import { defaultValues } from 'apps/PhoneSystem/containers/Faxes/Edit/default';
import Form from 'apps/PhoneSystem/containers/Faxes/Edit/Form';
import DataProvider from 'apps/PhoneSystem/containers/Seats/Edit/components/SeatEntityDataProvider';
import FormActionRow from 'apps/shared/components/FormActionRow';
import { FormContext } from 'apps/shared/components/FormContext';
import { ADD_KEY } from 'constant';
import cloneDeep from 'lodash/cloneDeep';
import { useFetchFaxboxByIdQuery } from 'models/Faxbox';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import { isModified } from 'shared/utility';
import { DialogContext } from 'store/contexts';
import { useCleanUpUnmodifiedEntities } from '../../hooks/useCleanupUnmodifiedEntities';

const FaxboxEditView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getValues, reset, setValue } = useFormContext();
  const dialogCtx = useContext(DialogContext);

  const { id } = useParams() as any;
  const { data } = useFetchFaxboxByIdQuery({ id }, { skip: id.startsWith(ADD_KEY) });

  const { setOnCleanUp } = useCleanUpUnmodifiedEntities();

  useEffect(() => {
    if (id === ADD_KEY) {
      const deleteUnmodifiedFaxbox = (idToDelete: string, initialValues: any) => {
        const currentValues = getValues();
        if (initialValues && !isModified(initialValues, currentValues.faxbox[idToDelete])) {
          delete currentValues.faxbox[idToDelete];
          reset({ ...currentValues }, { keepDirty: true });
        }
      };

      // Add the timestamp to allow for multiple items to be created at a single time.
      const newId = `${ADD_KEY}-${Date.now()}`;
      navigate(`../${newId}`, { replace: true });

      const values = getValues();
      values.faxbox[newId] = cloneDeep({ ...defaultValues, id: newId });

      setOnCleanUp(() => deleteUnmodifiedFaxbox(newId, values.faxbox[newId]));

      reset({ ...values }, { keepDirty: true });
    }
  }, []);

  const callDeleteFaxbox = async () => {
    if (id.startsWith(ADD_KEY)) {
      const values = getValues();
      delete values.faxbox[id];
      reset({ ...values }, { keepDirty: true });
    } else {
      setValue(`faxbox.${id}._toDelete`, true, { shouldDirty: true });
    }
    navigate('..');
  };

  const onDeleteFaxbox = () => {
    dialogCtx.onOpen({
      open: true,
      title: t('phone_system:containers.seats.faxbox.edit.actions.delete_faxbox.label'),
      confirmMessage: `${t('phone_system:containers.shared.edit_form.delete.text', {
        name: data?.name,
      })} ${t('common:delete_on_save')}`,
      onConfirm: callDeleteFaxbox,
    });
  };

  if (id === ADD_KEY) {
    return <Loading hasMargin />;
  }

  const renderPageWithData = (data?: any) => (
    <>
      <FormActionRow
        buttons={[
          {
            type: 'primary',
            label: t('phone_system:containers.seats.faxbox.edit.actions.delete_faxbox.label'),
            onClick: onDeleteFaxbox,
          },
        ]}
        title={
          data?.name
            ? t('phone_system:containers.seats.faxbox.edit.actions.editing_faxbox', {
                name: data.name,
              })
            : t('phone_system:containers.seats.faxbox.edit.actions.editing_new_faxbox')
        }
      />
      <Form hasFormActionRow />
    </>
  );

  return (
    <FormContext.Provider value={`faxbox.${id}.`}>
      {id.startsWith(ADD_KEY) ? (
        renderPageWithData()
      ) : (
        <DataProvider
          defaultValues={defaultValues}
          entity="faxbox"
          fetchFunction={useFetchFaxboxByIdQuery}
          id={id}
        >
          {(data: any) => renderPageWithData(data)}
        </DataProvider>
      )}
    </FormContext.Provider>
  );
};

export default FaxboxEditView;
