import E911AddressForm from 'apps/shared/components/E911AddressForm';
import merge from 'lodash/merge';
import Button from 'shared/components/Button';
import Dialog, { DialogActions, DialogType } from 'shared/components/Dialog';
import { SelectionPosition } from 'shared/components/Table';
import defaultProps from './default';
import { AddressUpdateConfirmDialogProps as Props } from './definition';
import translations from './translations';

const AddressDialog = (props: Props) => {
  const { isOpen, alertValue, onClick } = merge({}, defaultProps, props);
  const {
    button,
    components: { e911_address_form },
    title,
  } = translations();

  return (
    <Dialog
      open={isOpen}
      renderActions={
        <DialogActions
          rightButtons={
            <>
              <Button color="secondary" variant="outlined" onClick={onClick.close}>
                {button.cancel}
              </Button>
              <Button color="secondary" variant="contained" onClick={onClick.update}>
                {button.update}
              </Button>
            </>
          }
        />
      }
      title={title}
      type={DialogType.E911Address}
      onClose={onClick.close}
    >
      <E911AddressForm
        alertValue={{ count: alertValue.count }}
        dialogProps={{
          selection: {
            position: SelectionPosition.Left,
          },
          title: e911_address_form.dialog.title,
        }}
      />
    </Dialog>
  );
};

export default AddressDialog;
