import Box from 'shared/components/Box';
import styled, { css } from 'styled-components';

const StyledApplySiteLocationDialog = styled(Box)`
  ${({ theme }) => css`
    > div {
      margin: ${theme.spacing(0, 0, 3, 6)};
    }
  `};
`;

export default StyledApplySiteLocationDialog;
