import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { defaultValues as deviceDefaultValues } from 'apps/PhoneSystem/containers/Devices/Edit/default';
import { defaultValues as voicemailDefaultValues } from 'apps/PhoneSystem/containers/Voicemails/Edit/default';
import { HandleSaveSuccessParam } from 'apps/PhoneSystem/definition';
import { useNavigation } from 'apps/PhoneSystem/shared/useNavigation';
import useCurrentAccountId from 'apps/shared/hooks/useCurrentAccountId';
import useHorizontalTabs from 'apps/shared/hooks/useHorizontalTabs';
import { handleRequestError } from 'apps/shared/utility';
import { isPinPassLinkEnabled } from 'apps/shared/utility/featureFlag';
import { ADD_KEY, DESKTOP_APP_DEFAULT_ZONE, LOCAL_STORAGE, SEAT_TYPE } from 'constant';
import md5 from 'crypto-js/md5';
import { Provisioner } from 'definition';
import i18next from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import {
  useFetchAccountConfigsQuery,
  useFetchAccountDesktopAppZoneQuery,
  useFetchAccountQuery,
} from 'models/Account';
import { setCredentials, setLocalStorageAuth, useLoginMutation } from 'models/Auth';
import { useResetLoginAttemptsMutation } from 'models/BizUser';
import {
  useCreateCallflowMutation,
  useDeleteCallflowMutation,
  useFetchCallflowsByOwnerUsernameQuery,
  useLazyFetchCallflowsByOwnerUsernameQuery,
  usePatchCallflowMutation,
} from 'models/Callflow';
import {
  useCreateConferenceMutation,
  useDeleteConferenceMutation,
  useUpdateConferenceMutation,
} from 'models/Conference';
import { ConfigsId, useFetchConfigsByIdQuery } from 'models/Configs';
import {
  useCreateDeviceMutation,
  useDeleteDeviceMutation,
  useUpdateDeviceMutation,
} from 'models/Device';
import {
  useCreateFaxboxMutation,
  useDeleteFaxboxMutation,
  useUpdateFaxboxMutation,
} from 'models/Faxbox';
import { useCreateMattermostUserMutation } from 'models/Mattermost';
import {
  deleteSeat,
  useCreateSeatMutation,
  useDeleteSeatMutation,
  useFetchSeatByIdQuery,
  useUpdateSeatMutation,
} from 'models/Seat';
import { schema } from 'models/Seat/schema';
import {
  useCreateSMSMutation,
  useDeleteSMSMutation,
  useFetchSMSByUserIdQuery,
  useUpdateSMSMutation,
} from 'models/SMS';
import { useCreateDesktopAppZoneMutation, useFetchDesktopAppZoneByIdQuery } from 'models/User';
import {
  useCreateVoicemailMutation,
  useDeleteVoicemailMutation,
  useLazyFetchVoicemailsByOwnerIdQuery,
  useUpdateVoicemailMutation,
} from 'models/Voicemail';
import { ChangeEvent, FunctionComponent, useContext, useEffect, useMemo, useRef } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Button from 'shared/components/Button';
import DropdownButton from 'shared/components/DropdownButton';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import usePrevious from 'shared/hooks/usePrevious';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities, generateRandomTextString, generateUserName } from 'shared/utility';
import { AlertContext } from 'store/contexts';
import { transformConferenceForSaving } from '../../Conferences/Edit';
import { transformDeviceForSaving } from '../../Devices/Edit';
import { transformSMSForSaving } from '../../SMS/Edit/utility';
import { transformVoicemailForSaving } from '../../Voicemails/Edit/utility';
import { isCompoundFieldDirty } from '../../Voicemails/utility'; // TODO: Review implementation
import { Tab } from './components';
import { useHeroAppsList } from './components/TabSeat/components/DesktopModulesSection';
import { SEARCH_PARAMS } from './components/TabSMS/ListView/constants';
import { UpdateE911NumberDialog } from './components/UpdateE911NumberDialog';
import { defaultValues } from './default';
import {
  DirtySeatData,
  EditProps as Props,
  EntityDescriptor,
  FormInput,
  ResponseBody,
  SeatData,
} from './definition';
import {
  createEntityFunctions,
  handleDeleteResult,
  hasAuxButton,
  performEntityUpdates,
} from './helper';
import { createMattermostUser } from './helper/createMattermostUser';
import { hideAddSmsButton } from './helper/hideAddSmsButton';
import StyledEditPanels from './style';
import { createNewCallflowBody, getAddDeviceItems } from './utility';

/**
 * These additional (and temporary) account data object properties
 * are used to facilitate value gathering/validation, etc.
 */
export const TEMP_PROPERTY: Record<string, string> = {
  AVAILABLE_DIDS_FOR_SMS: '_temp_available_dids_for_sms',
  IS_IN_HERO_APPS: '_temp_is_in_hero_apps',
  IS_COMPLEX_PASSWORD: '_temp_is_complex_password',
  DESKTOP_APP_ZONE: '_temp_default_zone',
  LINKED_CALLFLOW_NUMBERS: '_temp_linked_callflow_numbers',
  PHONE_NUMBER: 'phone_number', // TODO: Prefix with `_temp_`
};

const DEFAULT_TAB = 'seat';

const Edit: FunctionComponent<Props> = ({ handleSaveSuccess, handleDeleteSuccess }: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id, activeTab } = useParams();
  const alertCtx = useContext(AlertContext);

  if (!id) {
    throw new Error('id is required');
  }

  const [updateSeat] = useUpdateSeatMutation();
  const [createSeat] = useCreateSeatMutation();
  const [deleteSeatMutation] = useDeleteSeatMutation();
  const [updateDevice] = useUpdateDeviceMutation();
  const [deleteDevice] = useDeleteDeviceMutation();
  const [createDevice] = useCreateDeviceMutation();
  const [updateVoicemail] = useUpdateVoicemailMutation();
  const [deleteVoicemail] = useDeleteVoicemailMutation();
  const [createVoicemail] = useCreateVoicemailMutation();
  const [updateFaxbox] = useUpdateFaxboxMutation();
  const [deleteFaxbox] = useDeleteFaxboxMutation();
  const [createFaxbox] = useCreateFaxboxMutation();
  const [updateConference] = useUpdateConferenceMutation();
  const [deleteConference] = useDeleteConferenceMutation();
  const [createConference] = useCreateConferenceMutation();
  const [updateSMS] = useUpdateSMSMutation();
  const [deleteSMS] = useDeleteSMSMutation();
  const [createSMS] = useCreateSMSMutation();
  const [createCallflow] = useCreateCallflowMutation();
  const [patchCallflow] = usePatchCallflowMutation();
  const [deleteCallflow] = useDeleteCallflowMutation();
  const [fetchCallflowsByOwnerUsername] = useLazyFetchCallflowsByOwnerUsernameQuery();
  const [fetchVoicemailsByOwnerId] = useLazyFetchVoicemailsByOwnerIdQuery();
  const [createDesktopAppZone] = useCreateDesktopAppZoneMutation();
  const [createMattermostUserMutation] = useCreateMattermostUserMutation();
  const [resetLoginAttempts] = useResetLoginAttemptsMutation();
  const [login] = useLoginMutation();

  const { data: accountData } = useFetchAccountQuery();
  const { data: configsDataAccounts } = useFetchAccountConfigsQuery();
  const { data: configsDataVoicemail } = useFetchConfigsByIdQuery(
    { id: ConfigsId.Voicemail },
    { skip: !isPinPassLinkEnabled() },
  );

  const resetFormResponseBody = useRef<ResponseBody>();

  const methods = useForm<FormInput>({
    defaultValues,
    resolver: joiResolver(schema()),
  });

  const {
    handleSubmit,
    watch,
    setError,
    formState: { errors, dirtyFields, isSubmitSuccessful },
    reset,
    getValues,
    setValue,
  } = methods;
  const isPageDirty = checkFormEntities(dirtyFields);
  const seat_type = searchParams.get('seat_type') || 'default';

  const watchUsername = watch('seat.username');
  const watchSeatType = watch('seat.seat_type');
  const watchSeatPassword = watch('seat.password');
  const watchPhoneNumber = watch('seat.phone_number');
  const availableDIDs = watch('seat._temp_available_dids_for_sms');
  const watchDesktopAppZone = watch('seat._temp_default_zone');

  const previousPhoneNumber = usePrevious(watchPhoneNumber);

  useEffect(() => {
    if (id === ADD_KEY && seat_type !== SEAT_TYPE.admin.id) {
      setValue('device.add-default.id' as any, `${ADD_KEY}-default`);
      setValue('voicemail.add-default.id' as any, `${ADD_KEY}-default`);

      setValue('device.add-default.name' as any, watchUsername);
      setValue('device.add-default.sip.username' as any, watchUsername);
      setValue('voicemail.add-default.name' as any, watchUsername);
      setValue('voicemail.add-default.mailbox' as any, watchUsername);

      // if seat/voicemail passwords are coupled
      if (
        !isPinPassLinkEnabled() ||
        (isPinPassLinkEnabled() && configsDataVoicemail?.pin_pass_sync)
      ) {
        setValue('voicemail.add-default.pin' as any, watchSeatPassword);
      }

      if (isPinPassLinkEnabled()) {
        setValue('voicemail.add-default.require_pin' as any, false);
      }
    }
  }, [
    configsDataVoicemail,
    configsDataVoicemail?.pin_pass_sync,
    id,
    seat_type,
    watchUsername,
    watchSeatPassword,
    setValue,
  ]);

  const { data: smsData } = useFetchSMSByUserIdQuery({ id }, { skip: id === ADD_KEY });
  const { data: accountDesktopAppZoneData } = useFetchAccountDesktopAppZoneQuery({
    id: useCurrentAccountId(),
  });

  /**
   * This hook is to handle the change of phone number and related SMS box event. There are only 2 scenarios that will trigger this effect:
   * 1. On page load, on data arrived, phone number changed from undefined to phone number assigned
   * 2. On creation or editing of Seats without phone number, user switch phone number
   */

  useEffect(() => {
    if (seat_type !== SEAT_TYPE.admin.id) {
      // Add new number to available dids
      if (watchPhoneNumber) {
        const nextAvailableDIDsSet = new Set(availableDIDs || []);
        nextAvailableDIDsSet.forEach((did) => {
          if (did !== previousPhoneNumber) {
            nextAvailableDIDsSet.add(did);
          }
        });

        // User switching phone number, remove the previous one
        if (previousPhoneNumber) {
          nextAvailableDIDsSet.delete(previousPhoneNumber);
        }

        // There is an edge case that on first load of Seat with assigned phone number triggers this effect.
        // Check if there is an existing sms box already for this phone number, don't add it to the set if it is.
        if (!smsData?.some((sms) => sms.numbers.includes(watchPhoneNumber))) {
          nextAvailableDIDsSet.add(watchPhoneNumber);
        }

        const nextAvailableDIDs = Array.from(nextAvailableDIDsSet);
        setValue('seat._temp_available_dids_for_sms', nextAvailableDIDs);
      }

      // Remove the previous locally created sms box
      if (previousPhoneNumber) {
        const seatSMS = getValues('sms') as any;
        delete seatSMS[`${ADD_KEY}${previousPhoneNumber}`];
        setValue('sms', seatSMS);
      }
    }
  }, [watchPhoneNumber]);

  useEffect(() => {
    setValue('seat.seat_type' as any, seat_type);
  }, []);

  const heroAppsList = useHeroAppsList(id);
  const { data: seatData } = useFetchSeatByIdQuery({ id }, { skip: id === ADD_KEY });
  const { data: callflowData } = useFetchCallflowsByOwnerUsernameQuery(
    { username: seatData?.username },
    { skip: id === ADD_KEY || !seatData },
  );

  const { data: desktopAppZoneSeatLevelData } = useFetchDesktopAppZoneByIdQuery(
    { id },
    { skip: id === ADD_KEY },
  );

  useEffect(() => {
    if (seatData) {
      // assign available hero apps to temporary property (for checkboxes)
      const heroApps = heroAppsList.reduce((acc: { [key: string]: boolean }, { id }: any) => {
        acc[id] = seatData.hero_apps?.includes(id);
        return acc;
      }, {});

      const phoneNumber =
        callflowData &&
        callflowData[0]?.numbers.filter((number: string) => number.startsWith('+'))?.[0];

      reset(
        merge({}, getValues(), {
          seat: merge({}, seatData, {
            acdc_min_call_duration: seatData?.acdc_min_call_duration?.toString(),
            call_restriction: {
              ...defaultValues.seat.call_restriction,
              ...seatData?.call_restriction,
            },
            [TEMP_PROPERTY.IS_IN_HERO_APPS]: heroApps,
            ...(phoneNumber
              ? { [TEMP_PROPERTY.LINKED_CALLFLOW_NUMBERS]: phoneNumber }
              : { [TEMP_PROPERTY.PHONE_NUMBER]: null }),
            should_create_caller_id: Boolean(seatData?.should_create_caller_id),
          }),
        }),
      );
    }
  }, [seatData, callflowData]);

  useEffect(() => {
    const desktopAppZoneAccountLevel =
      accountDesktopAppZoneData?.default_zone ?? DESKTOP_APP_DEFAULT_ZONE;
    setValue(
      'seat._temp_default_zone',
      desktopAppZoneSeatLevelData?.default_zone ?? desktopAppZoneAccountLevel,
    );
  }, [desktopAppZoneSeatLevelData, accountDesktopAppZoneData?.default_zone, setValue]);

  // On submit successful update the form with response data
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(resetFormResponseBody.current, { keepDirty: false });
    }
  }, [isSubmitSuccessful]);

  // If we are creating a new seat, we need to set
  // the default values based on the seat type
  useEffect(() => {
    if (id === ADD_KEY) {
      if (watchSeatType === SEAT_TYPE.admin.id) {
        setValue('device', {});
        setValue('voicemail', {});
      } else {
        const defaultDevice = cloneDeep({
          ...deviceDefaultValues,
          extra_info: {
            provisioner: Provisioner.Advanced,
          },
          sip: {
            ...deviceDefaultValues.sip,
            username: generateUserName(),
            password: generateRandomTextString(),
          },
        });
        setValue('device.add-default' as any, defaultDevice, { shouldDirty: true });
        setValue(
          'voicemail.add-default' as any,
          { ...cloneDeep(voicemailDefaultValues) },
          { shouldDirty: true },
        );
      }
    }
  }, [id, watchSeatType]);

  const params = useParams();

  const horizontalItems = createHorizontalItems(id, watchUsername, dirtyFields, errors);

  const entitiesToUpdate: Array<EntityDescriptor> = useMemo(
    () => [
      {
        accessor: 'device',
        functions: createEntityFunctions(updateDevice, createDevice, deleteDevice),
        transform: transformDeviceForSaving,
        label: t('phone_system:containers.seats.devices.label'),
        error: t('phone_system:containers.seats.devices.failure'),
        success: t('phone_system:containers.seats.devices.success'),
        update: true,
      },
      {
        accessor: 'voicemail',
        functions: createEntityFunctions(updateVoicemail, createVoicemail, deleteVoicemail),
        transform: transformVoicemailForSaving,
        label: t('phone_system:containers.seats.voicemail.label'),
        error: t('phone_system:containers.seats.voicemail.failure'),
        success: t('phone_system:containers.seats.voicemail.success'),
        update: true,
      },
      {
        accessor: 'faxbox',
        functions: createEntityFunctions(updateFaxbox, createFaxbox, deleteFaxbox),
        label: t('phone_system:containers.seats.faxbox.label'),
        error: t('phone_system:containers.seats.faxbox.failure'),
        success: t('phone_system:containers.seats.faxbox.success'),
        update: true,
      },
      {
        accessor: 'conference',
        functions: createEntityFunctions(updateConference, createConference, deleteConference),
        transform: transformConferenceForSaving,
        label: t('phone_system:containers.seats.conference.label'),
        error: t('phone_system:containers.seats.conference.failure'),
        success: t('phone_system:containers.seats.conference.success'),
        update: true,
      },
      {
        accessor: 'sms',
        functions: createEntityFunctions(updateSMS, createSMS, deleteSMS),
        transform: transformSMSForSaving,
        label: t('phone_system:containers.seats.sms.label'),
        error: t('phone_system:containers.seats.sms.failure'),
        success: t('phone_system:containers.seats.sms.success'),
        update: true,
        customOwnerIdKey: 'owner',
      },
    ],
    [],
  );

  const updateAssociatedCallflow = async (
    callflowId: string,
    existingNumbers: string[],
    phoneNumber: string,
  ) => {
    if (!(TEMP_PROPERTY.PHONE_NUMBER in (dirtyFields?.seat || {}))) {
      return true;
    }
    try {
      const numbers = existingNumbers.slice();
      numbers.push(phoneNumber);

      await patchCallflow({ id: callflowId, body: { numbers } });
      return true;
    } catch (exception) {
      showToast.error(t('phone_system:containers.seats.save_operations.update_callflow.failure'));
      return false;
    }
  };

  const createAssociatedCallflow = async (seatRequestData: Seat, entitiesData: SeatData) => {
    try {
      const newCallflow = createNewCallflowBody(configsDataAccounts, seatRequestData, entitiesData);

      const data = await createCallflow({
        body: newCallflow,
      }).unwrap();

      return { isSuccess: true, callflowId: data?.data?.id };
    } catch (exception) {
      alertCtx.openModal(exception);
      return { isSuccess: false };
    }
  };

  const updateBody = (seatBody: Record<string, any>) => {
    // delete extraneous properties
    delete seatBody[TEMP_PROPERTY.IS_COMPLEX_PASSWORD];
    delete seatBody[TEMP_PROPERTY.LINKED_CALLFLOW_NUMBERS];
    delete seatBody.confirm_password;

    // if empty `presence_id`... assign `username` value
    if (!seatBody.presence_id) {
      seatBody.presence_id = seatBody.username;
    }
  };

  const addSeat = async (seatBody: Record<string, any>, responseBody: Record<string, any>) => {
    try {
      updateBody(seatBody);

      // keep temp property for callflow creation/update
      const _seatBody = { ...seatBody };
      delete _seatBody[TEMP_PROPERTY.PHONE_NUMBER];
      delete _seatBody[TEMP_PROPERTY.DESKTOP_APP_ZONE];

      /**
       * Doesn't this apply to editing a seat as well? If it indeed does,
       * then we should move this conditional into `updateBody` above.
       */
      if (seatBody.password) {
        seatBody.password = seatBody.password.toString();
      }

      const seat = await createSeat({ body: _seatBody }).unwrap();

      responseBody.seat = { ...seat.data, phone_number: seatBody.phone_number }; // Include the phone number for later process
      return true;
    } catch (exception) {
      showToast.error(t('phone_system:containers.seats.save_operations.create_seat.failure'));
      handleRequestError(exception, setError);
      return false;
    }
  };

  const editSeat = async (seatBody: Record<string, any>, responseBody: Record<string, any>) => {
    try {
      // Get the phone number to include in response body before deletion
      const phoneNumber =
        seatBody[TEMP_PROPERTY.LINKED_CALLFLOW_NUMBERS] ?? seatBody[TEMP_PROPERTY.PHONE_NUMBER];
      updateBody(seatBody);

      // delete temporary properties
      delete seatBody[TEMP_PROPERTY.AVAILABLE_DIDS_FOR_SMS];
      delete seatBody[TEMP_PROPERTY.IS_IN_HERO_APPS];
      delete seatBody[TEMP_PROPERTY.DESKTOP_APP_ZONE];

      const seat = await updateSeat({ id, body: { ...seatBody } }).unwrap();

      if (seatBody.password) {
        if (isPinPassLinkEnabled()) {
          await resetLoginAttempts({
            body: {
              account_name: accountData.name,
              user_name: seatBody.username,
            },
          });
        }

        if (seat.id === localStorage.getItem(LOCAL_STORAGE.AUTH.USER_ID)) {
          const authUser = await login({
            body: {
              account_name: seat.account_name, // localStorage.getItem(LOCAL_STORAGE.AUTH.ACCOUNT_NAME),
              credentials: md5(`${seatBody.username}:${seatBody.password}`).toString(),
            },
          }).unwrap();
          setLocalStorageAuth({
            auth_token: authUser.auth_token,
            user_id: authUser.data.owner_id,
            account_id: authUser.data.account_id,
          });
          dispatch(setCredentials(authUser));
        }
      }

      responseBody.seat = { ...seat, phone_number: phoneNumber }; // Include the phone number for later process
      return true;
    } catch (exception) {
      console.error(exception);
      showToast.error(t('phone_system:containers.seats.save_operations.update_seat.failure'));
      return false;
    }
  };

  const createDesktopAppZoneSeatLevel = async (seatId: string, isDefaultZone: boolean) => {
    const desktopAppZoneBody = desktopAppZoneSeatLevelData
      ? cloneDeep(desktopAppZoneSeatLevelData)
      : {};

    if (desktopAppZoneSeatLevelData?.default_zone && isDefaultZone) {
      // we don't need to keep the key if the value is the "Default Zone"
      delete desktopAppZoneBody.default_zone;
    } else {
      // update value
      desktopAppZoneBody.default_zone = watchDesktopAppZone;
    }

    try {
      await createDesktopAppZone({
        id: seatId,
        body: desktopAppZoneBody,
      }).unwrap();

      return true;
    } catch (exception) {
      showToast.error(t('phone_system:containers.seats.save_operations.create_seat.failure'));
      handleRequestError(exception, setError);
      return false;
    }
  };

  const rollBackSeatCreation = async (seatId: string) => {
    await deleteSeatMutation({ id: seatId });
  };

  const rollBackRelatedEntitiesCreation = async ({
    deviceId,
    voicemailId,
    smsId,
    createdCallflowId,
  }: {
    deviceId: string;
    voicemailId: string;
    smsId: string;
    createdCallflowId: string;
  }) => {
    await deleteVoicemail({ id: voicemailId });
    await deleteDevice({ id: deviceId });
    if (smsId) {
      await deleteSMS({ id: smsId });
    }
    if (createdCallflowId) {
      await deleteCallflow({ id: createdCallflowId });
    }
  };

  const onSubmit: SubmitHandler<FormInput> = async (data) => {
    const responseBody: ResponseBody = cloneDeep({ ...data, id });
    const seatBody = cloneDeep(data.seat);
    let isSuccess = true;

    if (id === ADD_KEY) {
      isSuccess = await addSeat(seatBody, responseBody);
    } else if (dirtyFields.seat) {
      isSuccess = await editSeat(seatBody, responseBody);
    }

    if (isSuccess) {
      isSuccess = await performEntityUpdates(
        entitiesToUpdate,
        dirtyFields as DirtySeatData,
        data,
        responseBody,
        showToast,
        showErrorMessage,
        setError,
      );
    }

    let createdCallflowId = ''; // For rollback in case of failure
    // If we are creating/editing a non-admin seat we need to create/update the associated callflow. Only created after previous operation are successful.
    if (seatBody.seat_type !== SEAT_TYPE.admin.id) {
      // Try to fetch linked callflow
      let associatedCallflowData;
      try {
        associatedCallflowData = await fetchCallflowsByOwnerUsername({
          username: seatBody.username,
        }).unwrap();
      } catch (exception) {
        // Nothing to do
      }
      const associatedCallflow = associatedCallflowData?.[0];

      if (id === ADD_KEY || !associatedCallflow) {
        let voicemail: any = {};
        Object.keys(responseBody.voicemail).forEach((key: string) => {
          voicemail = (responseBody.voicemail as any)[key] || {};
        });
        if (Object.keys(voicemail).length < 1) {
          voicemail = (await fetchVoicemailsByOwnerId({ id: seatBody.id }).unwrap())?.[0];
        }

        if (isSuccess) {
          const {
            isSuccess: isCreateCallflowSuccess,
            callflowId,
          } = await createAssociatedCallflow(seatBody, { ...responseBody, voicemail });
          isSuccess = isCreateCallflowSuccess;
          createdCallflowId = callflowId;
        }
      } else if (isSuccess) {
        isSuccess = await updateAssociatedCallflow(
          associatedCallflow.id,
          associatedCallflow.numbers,
          seatBody.phone_number,
        );
      }
    }

    // Create Desktop App Zone
    if (TEMP_PROPERTY.DESKTOP_APP_ZONE in (dirtyFields?.seat || {})) {
      isSuccess = await createDesktopAppZoneSeatLevel(
        responseBody.seat.id,
        watchDesktopAppZone === DESKTOP_APP_DEFAULT_ZONE &&
          DESKTOP_APP_DEFAULT_ZONE === accountDesktopAppZoneData?.default_zone,
      );
    }

    // Create mattermost user when creating a seat. Only created after previous operation are successful to avoid rollback.
    if (id === ADD_KEY && isSuccess) {
      await createMattermostUser({
        accountData,
        createMattermostUserMutation,
        userId: responseBody?.seat?.id,
        onError: () => {
          showToast.warning(
            t('phone_system:containers.seats.save_operations.create_mattermost_user.failure'),
          );
        },
      });
    }

    if (isSuccess) {
      // update reset data for resetting forms to remove dirtydots
      resetFormResponseBody.current = cloneDeep(responseBody);

      // retain complex password flag
      if (data.seat._temp_is_complex_password) {
        resetFormResponseBody.current.seat._temp_is_complex_password =
          data.seat._temp_is_complex_password;
      }

      // retain hero apps field
      if (data.seat._temp_is_in_hero_apps) {
        resetFormResponseBody.current.seat._temp_is_in_hero_apps = data.seat._temp_is_in_hero_apps;
      }

      // retain phone number field
      if (data.seat._temp_linked_callflow_numbers) {
        resetFormResponseBody.current.seat._temp_linked_callflow_numbers =
          data.seat._temp_linked_callflow_numbers;
      }

      // retain desktop app zone field
      if (data.seat._temp_default_zone) {
        resetFormResponseBody.current.seat._temp_default_zone = data.seat._temp_default_zone;
      }

      // Handle redirect on save success
      const isSavedNotOnDefaultTab = activeTab !== DEFAULT_TAB;
      const toastMessage =
        seatBody.seat_type === SEAT_TYPE.admin.id
          ? t('phone_system:containers.seats.save_operations.create_seat.success.admin')
          : t('phone_system:containers.seats.save_operations.create_seat.success.non_admin');
      const handleSaveSuccessArgs: HandleSaveSuccessParam = {
        shouldRedirect: id === ADD_KEY || isSavedNotOnDefaultTab,
        toastMessage: id === ADD_KEY ? toastMessage : undefined,
      };

      // Need to update path if it is a new seat or need to back to list page if saving edit of entities when editing seats
      if (id === ADD_KEY || (id !== ADD_KEY && isSavedNotOnDefaultTab)) {
        handleSaveSuccessArgs.navigationOverride = {
          path: `../${id === ADD_KEY ? responseBody.seat.id : id}/${activeTab}`,
          options: { replace: true },
        };
      }

      handleSaveSuccess?.(handleSaveSuccessArgs);
    } else {
      // Roll back creation of Seat, Device and VMBox if the user is creating new Seat
      // TODO: change to rollback all elements instead of just one
      let deviceId = '';
      Object.keys(responseBody.device).forEach((key: string) => {
        deviceId = (responseBody.device as any)[key]?.id;
      });

      let voicemailId = '';
      Object.keys(responseBody.voicemail).forEach((key: string) => {
        voicemailId = (responseBody.voicemail as any)[key]?.id;
      });

      let smsId = '';
      Object.keys(responseBody.sms).forEach((key: string) => {
        smsId = (responseBody.sms as any)[key]?.id || {};
      });

      if (id === ADD_KEY) {
        // Rollback seat creation
        await rollBackSeatCreation(responseBody.seat?.id);

        // Roll back related entities
        if (!!deviceId || !!voicemailId || !!smsId || !!createdCallflowId) {
          await rollBackRelatedEntitiesCreation({
            deviceId,
            voicemailId,
            smsId,
            createdCallflowId,
          });
        }
      }
    }
  };

  const handleDelete = async () => {
    const result = await dispatch(deleteSeat(id));
    await handleDeleteResult({
      result,
      onError: () => {
        showToast.error();
      },
      onSuccess: () => {
        handleDeleteSuccess?.();
      },
    });
  };

  const configureHorizontalTabItem = (currentSeatType: string) => {
    const seatType = Object.values(SEAT_TYPE).find(
      ({ id, configuration }) => id === currentSeatType && configuration?.length,
    );
    return seatType
      ? horizontalItems.filter(({ hash }) => seatType.configuration?.includes(hash))
      : horizontalItems;
  };

  const { Tabs, TabPanels } = useHorizontalTabs({
    items: configureHorizontalTabItem(watch('seat.seat_type')),
    options: {
      hasMenuOffset: true,
    },
    onChange: (event: ChangeEvent<{}>, newValue: string) =>
      navigate(`../${id}/${newValue}`, { replace: true }),
  });

  const seatName = watch('seat.username');

  const AuxButton = useMemo(() => {
    if (hasAuxButton(params)) {
      switch (activeTab) {
        case 'device':
          return (
            <DropdownButton
              buttonProps={{ variant: 'outlined' }}
              items={getAddDeviceItems(navigate)}
              label={t('phone_system:containers.seats.aux_button.add_device.label')}
            />
          );
        case 'conference':
        case 'faxbox':
        case 'voicemail':
          return (
            <Button
              color="secondary"
              startIcon={<Icon name="plus-circle-outlined" size={16} />}
              variant="outlined"
              onClick={() => navigate(`./${ADD_KEY}`)}
            >
              {
                /**
                 * t('phone_system:containers.seats.aux_button.add_conference.label')
                 * t('phone_system:containers.seats.aux_button.add_faxbox.label')
                 * t('phone_system:containers.seats.aux_button.add_voicemail.label')
                 */
                t(`phone_system:containers.seats.aux_button.add_${activeTab}.label`)
              }
            </Button>
          );
        case 'sms': {
          if (hideAddSmsButton(configsDataVoicemail)) {
            return null;
          }
          const isDisabled = !availableDIDs?.length;
          return (
            <Tooltip
              title={
                isDisabled
                  ? t(
                      'phone_system:containers.seats.sms.empty_sms.create_sms_box.empty_phone_number',
                    )
                  : ''
              }
            >
              <div>
                <Button
                  color="secondary"
                  disabled={isDisabled}
                  startIcon={<Icon name="plus-circle-outlined" size={16} />}
                  style={isDisabled ? { pointerEvents: 'none' } : {}} // For tooltip to work on disabled button: https://mui.com/material-ui/react-tooltip/#disabled-elements
                  variant="outlined"
                  onClick={() => {
                    let nextSearch = searchParams.toString();
                    if (!searchParams.get(SEARCH_PARAMS.ADD_SMS_BOX)) {
                      nextSearch = `${nextSearch}&${SEARCH_PARAMS.ADD_SMS_BOX}=true`;
                    }
                    navigate({ pathname: `.`, search: `${nextSearch}` });
                  }}
                >
                  {t(`phone_system:containers.seats.aux_button.add_sms.label`)}
                </Button>
              </div>
            </Tooltip>
          );
        }
        default:
          break;
      }
    }
  }, [configsDataVoicemail, activeTab, params, availableDIDs]);

  const navBlockPathWhitelist: Array<RegExp | string> = useMemo(
    () => horizontalItems.map(({ hash }) => new RegExp(`.*seats.*${hash}.*(add)*.*$`, 'g')),
    [horizontalItems],
  );

  return (
    <>
      <EditForm
        AuxButton={AuxButton}
        deleteConfirm={t('phone_system:containers.seats.seat.actions.delete_seat.confirm', {
          name: seatName,
        })}
        deleteLabel={t('phone_system:containers.seats.seat.actions.delete_seat.label')}
        entityLabel={t('phone_system:containers.seats.label')}
        entityName={seatName}
        formMethods={methods}
        isPageDirty={isPageDirty}
        navBlockPathWhitelist={navBlockPathWhitelist}
        onCancel={() => navigate('../')}
        onDelete={id !== ADD_KEY ? handleDelete : undefined}
        onSave={handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }))}
      >
        <StyledEditPanels>
          {Tabs}
          {TabPanels}
        </StyledEditPanels>
      </EditForm>
      <UpdateE911NumberDialog
        formData={resetFormResponseBody.current}
        id={id}
        isSubmitSuccessful={isSubmitSuccessful}
      />
    </>
  );
};

const EditWithNav = () => {
  const EditWithNavComp = useNavigation(Edit);
  return <EditWithNavComp />;
};

export default EditWithNav;

export const createHorizontalItems = (
  id: string,
  username: string,
  dirtyFields: { [key: string]: any },
  errors: { [key: string]: any },
) => {
  return [
    {
      hash: 'seat',
      label: i18next.t('phone_system:containers.seats.seat.label'),
      component: <Tab.Seat id={id} />,
      isDirty: isCompoundFieldDirty(dirtyFields, 'seat'),
      isError: isCompoundFieldDirty(errors, 'seat'),
    },
    {
      hash: 'device',
      label: i18next.t('phone_system:containers.seats.devices.label'),
      component: <Tab.Devices id={id} />,
      isDirty: isCompoundFieldDirty(dirtyFields, 'device'),
      isError: isCompoundFieldDirty(errors, 'device'),
    },
    {
      hash: 'voicemail',
      label: i18next.t('phone_system:containers.seats.voicemail.label'),
      component: <Tab.Voicemail id={id} />,
      isDirty: isCompoundFieldDirty(dirtyFields, 'voicemail'),
      isError: isCompoundFieldDirty(errors, 'voicemail'),
    },
    {
      hash: 'faxbox',
      label: i18next.t('phone_system:containers.seats.faxbox.label'),
      component: <Tab.Faxbox id={id} />,
      isDirty: isCompoundFieldDirty(dirtyFields, 'faxbox'),
      isError: isCompoundFieldDirty(errors, 'faxbox'),
    },
    {
      hash: 'conference',
      label: i18next.t('phone_system:containers.seats.conference.label'),
      component: <Tab.Conference id={id} />,
      isDirty: isCompoundFieldDirty(dirtyFields, 'conference'),
      isError: isCompoundFieldDirty(errors, 'conference'),
    },
    {
      hash: 'sms',
      label: i18next.t('phone_system:containers.seats.sms.label'),
      component: <Tab.SMS id={id} />,
      isDirty: isCompoundFieldDirty(dirtyFields, 'sms'),
      isError: isCompoundFieldDirty(errors, 'sms'),
    },
    {
      hash: 'callflow',
      label: i18next.t('phone_system:containers.seats.callflow.label'),
      component: <Tab.Callflow id={id} username={username} />,
    },
  ];
};
