import E911AddressDialog, { IsDialogOpen } from 'apps/shared/components/E911AddressDialog';
import { RowArray } from 'apps/shared/definition';
import { FilterGroup, useFilter } from 'apps/shared/hooks/useFilter';
import { FilterInputType } from 'apps/shared/hooks/useFilter/components/FilterDialogContent/definition';
import { isE911AddressAutomationEnabled } from 'apps/shared/utility/featureFlag';
import { useFetchAccountQuery } from 'models/Account';
import { useFetchDevicesQuery } from 'models/Device';
import { useFetchPhoneNumbersQuery, usePatchPhoneNumberMutation } from 'models/PhoneNumber';
import { useFetchUsersQuery } from 'models/User';
import { useEffect, useState } from 'react';
import { FilterOption } from 'shared/components/FilterSelect';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import Table, { SelectionType } from 'shared/components/Table';
import { stripPlusSign } from 'shared/utility';
import DownloadIcon from './components/DownloadIcon';
import { UpdateAddressButton } from './components/UpdateAddressButton';
import { E911Row, FilterSourceType, FilterType, IsLoading } from './definition';
import { useE911InfoFilterOptions } from './hooks/useE911InfoFilterOptions';
import translations from './translations';
import { columns, processData, processDevices, processUsers } from './utility';

const List = () => {
  const { filter, table } = translations();

  const [tableData, setTableData] = useState<any>([]);
  const [data, setData] = useState<Array<E911Row>>([]);
  const [devices, setDevices] = useState<Array<E911Row>>([]);
  const [users, setUsers] = useState<Array<E911Row>>([]);
  const [selectedRows, setSelectedRows] = useState<RowArray>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<IsDialogOpen>({
    addressUpdateConfirm: false,
    address: false,
  });
  const [isLoading, setIsLoading] = useState<IsLoading>({
    data: true,
    devices: true,
    users: true,
  });

  const { data: accountData, isLoading: isLoadingAccount } = useFetchAccountQuery();
  const { data: devicesData, isLoading: isLoadingDevices } = useFetchDevicesQuery();
  const { data: numbersData, isLoading: isLoadingNumbers } = useFetchPhoneNumbersQuery();
  const { data: usersData, isLoading: isLoadingUsers } = useFetchUsersQuery();

  const [patchPhoneNumber] = usePatchPhoneNumberMutation();

  const {
    [FilterType.SiteLocation]: { locationOptions, getLocationLabelById },
    [FilterType.Source]: { sourceOptions, getSourceLabelByValue },
    [FilterType.Type]: { typeOptions, getTypeLabelByValue },
  } = useE911InfoFilterOptions();

  const onE911AddressUpdate = ({ isAccountAddressUpdateConfirmed, formData, callback }: any) => {
    const hasSourceAccount = selectedRows.some(
      (row: any) => row.source === FilterSourceType.Account,
    );

    if (hasSourceAccount && !isAccountAddressUpdateConfirmed) {
      setIsDialogOpen({ address: false, addressUpdateConfirm: true });
    }

    // remove duplicate numbers and plus sign prefix
    const e911Numbers = [
      ...new Set(selectedRows.map((row: any) => stripPlusSign(row.e911.number))),
    ];

    if (
      e911Numbers.length > 0 &&
      (!hasSourceAccount || (hasSourceAccount && isAccountAddressUpdateConfirmed))
    ) {
      // call mutation(s)
      e911Numbers.forEach((id: string) => {
        patchPhoneNumber({ id, body: formData });
      });

      callback();

      // reset selected rows
      setSelectedRows([]);
    }
  };

  const { filter: filterData } = useFilter({
    data,
    inputConfig: {
      filterDefaults: {
        [FilterType.Type]: [
          FilterOption.All,
          ...typeOptions.map(({ value }: SelectOption) => value),
        ],
        [FilterType.SiteLocation]: [
          FilterOption.All,
          ...locationOptions.map(({ value }: SelectOption) => value),
        ],
        [FilterType.Source]: [
          FilterOption.All,
          ...sourceOptions.map(({ value }: SelectOption) => value),
        ],
        [FilterType.IsShowMissingInfo]: [FilterOption.All, FilterType.IsShowMissingInfo],
      },
      filterGroup: FilterGroup.E911Info,
      items: {
        [FilterType.Type]: {
          compareFunction: (filterValues, { type }) =>
            filterValues.selected?.[FilterType.Type]?.includes(type),
          filterType: FilterType.Type,
          heading: filter.heading.type,
          inputType: FilterInputType.Select,
          getBreadcrumbItemLabel: getTypeLabelByValue,
          selectInputConfig: {
            isMulti: false,
            options: typeOptions,
          },
        },
        [FilterType.SiteLocation]: {
          compareFunction: (filterValues, { location }) =>
            filterValues.selected?.[FilterType.SiteLocation]?.includes(location ?? ''),
          filterType: FilterType.SiteLocation,
          heading: filter.heading.site_location,
          inputType: FilterInputType.Select,
          getBreadcrumbItemLabel: getLocationLabelById,
          selectInputConfig: {
            isMulti: false,
            options: locationOptions,
          },
        },
        [FilterType.Source]: {
          compareFunction: (filterValues, { source }) =>
            filterValues.selected?.[FilterType.Source]?.includes(source),
          filterType: FilterType.Source,
          heading: filter.heading.source,
          inputType: FilterInputType.Select,
          getBreadcrumbItemLabel: getSourceLabelByValue,
          selectInputConfig: {
            isMulti: false,
            options: sourceOptions,
          },
        },
        [FilterType.IsShowMissingInfo]: {
          compareFunction: (_, { e911 }) => !e911?.address,
          filterType: FilterType.IsShowMissingInfo,
          heading: filter.heading.show_missing_info_only,
          inputType: FilterInputType.Checkbox,
          getBreadcrumbItemLabel: () => filter.breadcrumb.missing_info_only,
        },
      },
    },
    setTableData,
  });

  useEffect(() => {
    processDevices({ devicesData, users, setDevices, setIsLoading });
  }, [devicesData, users, table.columns.seat.notAssigned]);

  useEffect(() => {
    processUsers({ accountData, usersData, setUsers, setIsLoading });
  }, [accountData, usersData]);

  useEffect(() => {
    processData({
      devices,
      users,
      locations: accountData?.locations,
      numbers: numbersData?.usage.assigned,
      setData,
      setIsLoading,
    });
  }, [devices, accountData, numbersData, users]);

  if (
    isLoadingAccount ||
    isLoadingDevices ||
    isLoadingNumbers ||
    isLoadingUsers ||
    isLoading.devices ||
    isLoading.users ||
    isLoading.data
  ) {
    return <Loading />;
  }

  return (
    <>
      <Table
        hasSearch
        actionRowButtons={{
          left: isE911AddressAutomationEnabled() ? (
            <UpdateAddressButton
              selectedRows={selectedRows}
              setIsAddressDialogOpen={() =>
                setIsDialogOpen((isDialogOpen: IsDialogOpen) => ({
                  ...isDialogOpen,
                  address: true,
                }))
              }
            />
          ) : (
            <></>
          ),
          right: <DownloadIcon data={tableData} />,
        }}
        columns={columns()}
        data={tableData}
        filter={filterData}
        hasEditIcon={false}
        hasPagination={{ rowsPerPage: true }}
        {...(isE911AddressAutomationEnabled()
          ? {
              selection: {
                type: SelectionType.Multiple,
                setRows: setSelectedRows,
              },
            }
          : {})}
        title={table.title}
      />
      {isE911AddressAutomationEnabled() && (
        <E911AddressDialog
          isOpen={isDialogOpen}
          selectedRows={selectedRows}
          setIsOpen={setIsDialogOpen}
          onUpdate={onE911AddressUpdate}
        />
      )}
    </>
  );
};

export default List;
