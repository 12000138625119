import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import { HTMLAttributes, JSXElementConstructor, SyntheticEvent, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Grid from 'shared/components/Grid';
import Icon from 'shared/components/Icon';
import { InputProps } from 'shared/components/Input';
import { LabeledInput } from 'shared/components/Labeled';
import { LabeledProps } from 'shared/components/Labeled/definition';
import Paper from 'shared/components/Paper';
import Popper, { PopperProps } from 'shared/components/Popper';
import Typography from 'shared/components/Typography';
import {
  MainTextMatchedSubstrings,
  PlaceType,
} from '../../../apps/PhoneSystem/containers/Account/SiteLocations/Edit/definition';
import { StyledAutocompletePopperFooter } from '../../../apps/PhoneSystem/containers/Account/style';
import useGoogleMapsPlacesApi from '../../hooks/useGoogleMapsPlacesApi';

const renderOptions = (props: any, option: PlaceType) => {
  const updatedProps = {
    ...props,
    key: props.key + props['data-option-index'],
  };

  const matches = option?.structured_formatting?.main_text_matched_substrings || [];

  const parts = parse(
    option?.structured_formatting?.main_text,
    matches.map((match: MainTextMatchedSubstrings) => [match.offset, match.offset + match.length]),
  );

  return (
    <li {...updatedProps}>
      <Grid container alignItems="flex-start">
        <Grid item>
          <Icon
            name="location-pin-filled"
            themeColor="core.color.grey_b5b5b5"
            size={18}
            style={{ margin: '3px 8px 0 -6px' }}
          />
        </Grid>
        <Grid item xs>
          {parts.map(({ highlight, text }: { highlight: boolean; text: string }, index: number) => (
            <span
              key={`${index + text.trim()}`}
              style={{
                fontWeight: highlight ? 700 : 400,
              }}
            >
              {text}
            </span>
          ))}
          <Typography variant="body2" color="text.secondary">
            {option.structured_formatting.secondary_text}
          </Typography>
        </Grid>
      </Grid>
    </li>
  );
};

const paperComponent: JSXElementConstructor<HTMLAttributes<HTMLElement>> = (props) => (
  <div {...props}>{props.children}</div>
);

const getPopperComponent = (isDirty: boolean): JSXElementConstructor<PopperProps> => (props) => (
  <Popper
    {...props}
    style={{ width: isDirty ? 292 : 258, paddingRight: isDirty ? 32 : 'auto' }}
    placement="bottom-end"
    modifiers={[
      {
        name: 'offset',
        options: {
          offset: [-33, 2],
        },
      },
    ]}
  >
    <Paper>
      {props.children}
      <StyledAutocompletePopperFooter>
        <p>powered by</p>
        <span>
          <img src="/google_logo.png" alt="google logo" />
        </span>
      </StyledAutocompletePopperFooter>
    </Paper>
  </Popper>
);

const AddressAutoComplete = ({
  name = '',
  label,
  onChange,
  inputProps = {},
  tooltip,
}: {
  name?: string;
  label: string;
  onChange: (placeData: unknown) => void;
  inputProps?: InputProps;
  tooltip?: string;
}) => {
  const { t } = useTranslation();
  const [currentPlace, setCurrentPlace] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const {
    isLoaded: isMapsApiLoaded,
    getPlacePredictions,
    getPlaceDetails,
  } = useGoogleMapsPlacesApi();

  const { control } = useFormContext();

  /* Set new options on input value change */
  useEffect(() => {
    let active = true;
    if (!isMapsApiLoaded) {
      return;
    }

    if (inputValue === '') {
      setOptions(currentPlace ? [currentPlace] : []);
      return;
    }

    (async () => {
      const results: readonly PlaceType[] = await getPlacePredictions({ input: inputValue });
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (currentPlace) {
          newOptions = [currentPlace];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    })();

    return () => {
      active = false;
    };
  }, [currentPlace, inputValue, getPlacePredictions, isMapsApiLoaded]);

  /* Fetch and update when option is selected from the list */
  useEffect(() => {
    (async () => {
      if (!isMapsApiLoaded) {
        return;
      }

      if (currentPlace) {
        const request = {
          placeId: currentPlace.place_id,
          fields: ['address_components'],
        };

        const place = await getPlaceDetails(request);

        if (place) {
          onChange(place);
        }
      }
    })();
  }, [currentPlace, isMapsApiLoaded, getPlaceDetails, onChange]);

  return (
    <Controller
      render={({ field: { onChange: fieldOnChange, value }, fieldState: { isDirty } }) => (
        <Autocomplete
          sx={{
            '.MuiInput-input': {
              padding: '0 12px !important',
            },
          }}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          onChange={(event: SyntheticEvent, newValue: PlaceType | null) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setCurrentPlace(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            fieldOnChange(newInputValue);
          }}
          renderInput={(params) => {
            return (
              <div ref={params.InputProps.ref}>
                <LabeledInput
                  isDirty={isDirty}
                  label={label}
                  tooltip={tooltip}
                  inputProps={{
                    placeholder: label,
                    ...(params.inputProps as LabeledProps),
                    ...inputProps,
                  }}
                />
              </div>
            );
          }}
          renderOption={renderOptions}
          PaperComponent={paperComponent}
          PopperComponent={getPopperComponent(isDirty)}
          noOptionsText={t('common:component.address_autocomplete.no_matching_location_text')}
          disablePortal
        />
      )}
      name={name}
      control={control}
    />
  );
};

export default AddressAutoComplete;
