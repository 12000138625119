import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import isUndefined from 'lodash/isUndefined';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from 'shared/components/Button';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import { LabeledCheckbox, LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import Typography from 'shared/components/Typography';
import { INTER_DIGIT_CONVERSION_FACTOR } from '../../constants';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, DisaDialogProps as Props, DisaNodeData } from './definition';
import schema from './schema';
import translations from './translations';

const DisaDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
      use_account_caller_id: !isUndefined(data?.use_account_caller_id)
        ? data.use_account_caller_id === false
        : defaultValues.use_account_caller_id,
      interdigit: data?.interdigit
        ? String(data.interdigit / INTER_DIGIT_CONVERSION_FACTOR)
        : defaultValues.interdigit,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const {
    buttons,
    dialog,
    interdigit,
    max_digits,
    options,
    pin,
    preconnect_audio,
    preconnect_audios,
    retries,
    title,
    use_account_caller_id,
  } = useMemo(() => translations(), []);

  const submitConfirmationHandler = (formData: Data) => {
    if (formData.pin.trim().length === 0) {
      setShowConfirmationDialog(true);
    } else {
      submitHandler(formData);
    }
  };

  const submitHandler = (formData: Data) => {
    const {
      pin,
      retries,
      interdigit,
      max_digits,
      preconnect_audio,
      use_account_caller_id,
    } = formData;
    const newData: Data = {
      nodeId: data?.nodeId,
      pin,
      preconnect_audio,
      use_account_caller_id: !use_account_caller_id,
    };

    if (retries !== 'default') {
      newData.retries = retries;
    }

    if (interdigit && interdigit !== 'default') {
      newData.interdigit = String(+interdigit * INTER_DIGIT_CONVERSION_FACTOR);
    }

    if (max_digits !== 'default') {
      newData.max_digits = max_digits;
    }

    const nodeData: DisaNodeData = {
      data: newData,
    };

    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitConfirmationHandler)}
    >
      <Dialog
        open={showConfirmationDialog}
        renderActions={
          <DialogActions
            rightButtons={
              <>
                <Button
                  color="secondary"
                  data-test-id="btn-disa-dialog-cancel"
                  variant="outlined"
                  onClick={() => setShowConfirmationDialog(false)}
                >
                  {buttons.cancel}
                </Button>
                <Button
                  color="success"
                  data-test-id="btn-disa-dialog-ok"
                  variant="contained"
                  onClick={() => submitHandler(getValues())}
                >
                  {buttons.ok}
                </Button>
              </>
            }
          />
        }
        title={dialog.title}
        onClose={() => setShowConfirmationDialog(false)}
      >
        <Typography paragraph>{dialog.content}</Typography>
      </Dialog>

      <HookFormInputWrapper control={control} name="pin">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-disa-pin',
            }}
            inputWidth="small"
            isDirty={isDirty}
            label={pin.label}
          />
        )}
      </HookFormInputWrapper>

      <HookFormSelectWrapper control={control} name="retries" options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={retries.label}
            selectProps={{
              ...formProps,
              id: 'select-disa-retries',
            }}
            tooltip={retries.tooltip}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormSelectWrapper control={control} name="interdigit" options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={interdigit.label}
            selectProps={{
              ...formProps,
              id: 'select-disa-interdigit',
            }}
            tooltip={interdigit.tooltip}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormSelectWrapper control={control} name="max_digits" options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={max_digits.label}
            selectProps={{
              ...formProps,
              id: 'select-disa-max-digits',
            }}
            tooltip={max_digits.tooltip}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormSelectWrapper control={control} name="preconnect_audio" options={preconnect_audios}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={preconnect_audio.label}
            selectProps={{
              ...formProps,
              id: 'select-preconnect-audio',
            }}
            tooltip={preconnect_audio.tooltip}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormInputWrapper isCheckbox control={control} name="use_account_caller_id">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            checkboxProps={{
              ...formProps,
              id: 'checkbox-disa-keep-original-caller-id',
            }}
            feedback={feedback}
            indentWidth="none"
            isDirty={isDirty}
            label={use_account_caller_id.label}
            tooltip={use_account_caller_id.tooltip}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default DisaDialog;
