import { phoneNumberMutations } from './Mutations';
import { phoneNumberQueries } from './Queries';

export { schema } from './schema';

export const {
  useFetchAvailableNumbersByPrefixQuery,
  useFetchClassifiersQuery,
  useFetchInServiceAndPortInPhoneNumbersQuery,
  useFetchPhoneNumberByIdQuery,
  useFetchPhoneNumbersQuery,
  useLazyCheckAvailableCollectionQuery,
  useLazyFetchPhoneNumberByIdQuery,
  useLazyFetchPhoneNumbersQuery,
} = phoneNumberQueries;

export const {
  useActivateCollectionMutation,
  useAddPhoneNumbersToCollectionMutation,
  useDeleteListMutation,
  useDeletePhoneNumberMutation,
  useMoveCollectionToMutation,
  usePatchPhoneNumberMutation,
  useUpdatePhoneNumberMutation,
} = phoneNumberMutations;
