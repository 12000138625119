import Button from '@mui/material/Button';
import ButtonGroup, { ButtonGroupProps } from '@mui/material/ButtonGroup';
import merge from 'lodash/merge';
import { useRef, useState } from 'react';
import Icon from '../Icon';
import Menu from './components/Menu';
import defaultProps from './default';
import { SplitButtonProps as Props } from './definition';

const SplitButton = (props: Props) => {
  const {
    buttonProps,
    buttonProps: { color, disabled },
    items,
    label,
    onClick,
  } = merge({}, defaultProps, props);
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <ButtonGroup
        ref={ref}
        className={`MuiButtonGroup-split MuiButtonGroup-${color}${disabled ? ' Mui-disabled' : ''}`}
        {...(buttonProps as ButtonGroupProps)}
      >
        <Button onClick={onClick}>{label}</Button>
        <Button
          onClick={() => setIsOpen((isOpen: boolean) => !isOpen)}
          {...(isOpen ? { className: 'is-open' } : {})}
        >
          <Icon name="dropdown-down" size={19} />
        </Button>
      </ButtonGroup>
      <Menu ref={ref} isOpen={isOpen} items={items} setIsOpen={setIsOpen} />
    </>
  );
};

export default SplitButton;
