import { EndpointItemType } from 'apps/PhoneSystem/definition';
import { getEndpointIcon } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import Icon from 'shared/components/Icon';
import translations from './translations';

export const columnsSelected = (endPointsFieldArray: any) => {
  const { columns } = translations();

  return [
    {
      Header: columns.name.header,
      Cell: ({ row: { original } }: EndpointItemType) => (
        <TableCellTruncatedValue
          icon={getEndpointIcon(original.endpoint_type)}
          text={original.name}
          truncateAfter={40}
        />
      ),
      accessor: 'name',
    },
    {
      Header: '',
      Cell: ({ row: { index } }: { row: { index: number } }) => (
        <Icon
          hasHover
          name="minus-circle-outlined"
          size={22}
          themeColor="core.states.error.main"
          onClick={endPointsFieldArray.remove.bind(null, index)}
        />
      ),
      accessor: 'id',
    },
  ];
};
