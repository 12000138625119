import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchCallflowsQuery } from 'models/Callflow';
import { buildCallflowName } from 'models/Callflow/utility';
import { useFetchUsersQuery } from 'models/User';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { CallflowCallflowDialogProps as Props, CallflowNodeData, Data } from './definition';
import schema from './schema';
import { buildCallflowOptions } from './utility';

const CallflowDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const [options, setOptions] = useState<Array<SelectOption>>([]);

  const { data: callFlowData, isLoading } = useFetchCallflowsQuery();
  const { data: usersData } = useFetchUsersQuery();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const submitHandler = (formData: Data) => {
    const selected = callFlowData?.find(({ id }) => id === formData.id);
    const nodeData: CallflowNodeData = {
      data: formData,
      metadata: {
        name: buildCallflowName(selected, usersData),
        pvt_type: 'callflow',
      },
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    if (callFlowData) {
      setOptions(buildCallflowOptions(callFlowData));
    }
  }, [callFlowData]);

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.callflow.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper control={control} name="id" options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            isWindowed
            feedback={feedback}
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.callflow.add.callflow',
            )}
            selectProps={{
              options: callFlowData?.map((item: any) => ({
                value: item.id,
                label: buildCallflowName(item),
              })),
              ...formProps,
              id: 'select-callflow-add-callflow',
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default CallflowDialog;
