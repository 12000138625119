export const defaultValues = {
  e911: {
    customer_name: '',
    extended_address: '',
    locality: '',
    notification_contact_emails: '',
    postal_code: '',
    region: '',
    street_address: '',
  },
};
