import { RowArray } from 'apps/shared/definition';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { isUpdateAddressButtonDisabled } from './utility';

export const UpdateAddressButton = ({
  selectedRows,
  setIsAddressDialogOpen,
}: {
  selectedRows: RowArray;
  setIsAddressDialogOpen: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        isUpdateAddressButtonDisabled(selectedRows)
          ? t('phone_system:containers.e911info.update_address_button.disabled_tooltip')
          : ''
      }
    >
      <Box component="span">
        <Button
          disabled={isUpdateAddressButtonDisabled(selectedRows)}
          startIcon={<Icon name="edit" />}
          variant="outlined"
          onClick={setIsAddressDialogOpen}
        >
          {t('phone_system:containers.e911info.update_address_button.label')}
        </Button>
      </Box>
    </Tooltip>
  );
};
