import { RowArray } from 'apps/shared/definition';
import { Dispatch, SetStateAction } from 'react';
import { ComponentProps } from 'shared/definition';

export enum DialogType {
  Address = 'address',
  AddressUpdateConfirm = 'addressUpdateConfirm',
}

export type IsDialogOpen = {
  [DialogType.Address]: boolean;
  [DialogType.AddressUpdateConfirm]: boolean;
};

export interface E911AddressDialogProps extends ComponentProps {
  /** Is the dialog open? */
  isOpen: Record<string, boolean>;
  /** Selected rows (numbers) */
  selectedRows: RowArray;
  /** onUpdate callback */
  onUpdate: ({ data }: any) => void;
  /** setIsOpen state handler */
  setIsOpen: Dispatch<SetStateAction<IsDialogOpen>>;
}

export interface FormInput {
  e911: {
    customer_name: string;
    extended_address: string;
    locality: string;
    postal_code: string;
    region: string;
    street_address: string;
  };
}
