import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { RequestMethod } from 'definition';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import Typography from 'shared/components/Typography';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, WebhookDialogProps as Props, WebhookNodeData } from './definition';
import schema from './schema';

const WebhookDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const isGetHttpVerb = watch('http_verb') === RequestMethod.Get.toLowerCase();
  const httpVerbs = [
    { value: RequestMethod.Get.toLowerCase(), label: RequestMethod.Get },
    { value: RequestMethod.Post.toLowerCase(), label: RequestMethod.Post },
  ];
  const retries = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
  ];
  const bodyFormats = [
    {
      value: 'form-data',
      label: t(
        'phone_system:containers.callflows.callflow_action_dialog.webhook.body_formats.form_data',
      ),
    },
    {
      value: 'json',
      label: t(
        'phone_system:containers.callflows.callflow_action_dialog.webhook.body_formats.json',
      ),
    },
  ];

  const submitHandler = (formData: Data) => {
    const { format, ...data }: Data & Partial<Data> = formData;
    const newData =
      data.http_verb !== RequestMethod.Get.toLowerCase()
        ? {
            ...data,
            format,
          }
        : data;
    const nodeData: WebhookNodeData = {
      data: newData,
      metadata: {
        pvt_type: 'webhook',
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.webhook.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <Typography paragraph>
        {t('phone_system:containers.callflows.callflow_action_dialog.webhook.explanation')}
      </Typography>
      <HookFormInputWrapper control={control} name="uri">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-webhook-uri',
            }}
            inputWidth="xlarge"
            isDirty={isDirty}
            label={t('phone_system:containers.callflows.callflow_action_dialog.webhook.uri.label')}
            tooltip={t(
              'phone_system:containers.callflows.callflow_action_dialog.webhook.uri.tooltip',
            )}
          />
        )}
      </HookFormInputWrapper>
      <HookFormSelectWrapper control={control} name="http_verb" options={httpVerbs}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.webhook.http_verb.label',
            )}
            selectProps={{
              ...formProps,
              id: 'select-webhook-http-verb',
            }}
            selectWidth="xlarge"
            tooltip={t(
              'phone_system:containers.callflows.callflow_action_dialog.webhook.http_verb.tooltip',
            )}
          />
        )}
      </HookFormSelectWrapper>
      {!isGetHttpVerb && (
        <HookFormSelectWrapper control={control} name="format" options={bodyFormats}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledSelect
              isLabelAbove
              feedback={feedback}
              isDirty={isDirty}
              label={t(
                'phone_system:containers.callflows.callflow_action_dialog.webhook.format.label',
              )}
              selectProps={{
                ...formProps,
                id: 'select-webhook-format',
              }}
              selectWidth="xlarge"
              tooltip={t(
                'phone_system:containers.callflows.callflow_action_dialog.webhook.format.tooltip',
              )}
            />
          )}
        </HookFormSelectWrapper>
      )}
      <HookFormSelectWrapper control={control} name="retries" options={retries}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.webhook.retries.label',
            )}
            selectProps={{
              ...formProps,
              id: 'select-webhook-retries',
            }}
            selectWidth="xlarge"
            tooltip={t(
              'phone_system:containers.callflows.callflow_action_dialog.webhook.retries.tooltip',
            )}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default WebhookDialog;
