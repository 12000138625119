import { DurationProps } from './definition';

const defaultProps: DurationProps = {
  date: {
    start: '0',
    end: '0',
  },
  isAllDayEvent: false,
  time: {
    start: '0',
    stop: '0',
  },
};

export default defaultProps;
