import { joiResolver } from '@hookform/resolvers/joi';
import SeatSelect from 'apps/PhoneSystem/shared/SeatSelect';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useFetchSeatsQuery } from 'models/Seat';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, SeatCallFlowDialogProps as Props, SeatNodeData } from './definition';
import schema from './schema';

const SeatDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const { data: seatData, isLoading } = useFetchSeatsQuery();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm<Data>({
    defaultValues,
    resolver: joiResolver(schema()),
  });

  const watchAdvanced = watch('advanced');

  const submitHandler = (formData: Data) => {
    const selected = seatData?.find(({ id }) => id === formData.id);
    const data = { ...formData };

    if (!data.advanced) {
      data.var = '';
    }

    const nodeData: SeatNodeData = {
      data,
      metadata: {
        name: `${selected.first_name} ${selected.last_name}`,
        pvt_type: 'user',
      },
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    const updatedData = enhancedFormUtility.transformDataToFormData(
      { ...data, advanced: Boolean(data?.var) },
      defaultValues,
    );

    reset(updatedData);
  }, [data, reset]);

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.seat.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <SeatSelect
        isLabelAbove
        control={control}
        label={t('phone_system:containers.callflows.callflow_action_dialog.seat.add.user')}
        name="id"
      />

      <HookFormInputWrapper control={control} name="timeout">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              type: 'number',
              ...formProps,
              id: 'input-seat-ring-duration',
            }}
            inputWidth="small"
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.seat.add.ring_duration',
            )}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper isCheckbox control={control} name="can_call_self">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            isCheckboxLeft
            checkboxProps={{
              ...formProps,
              id: 'checkbox-seat-allow-self-call',
            }}
            feedback={feedback}
            indentWidth="small"
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.seat.add.allow_self_call.label',
            )}
            tooltip={t(
              'phone_system:containers.callflows.callflow_action_dialog.seat.add.allow_self_call.tooltip',
            )}
          />
        )}
      </HookFormInputWrapper>
      <HookFormInputWrapper isCheckbox control={control} name="advanced">
        {({ ref, isDirty, ...formProps }) => (
          <LabeledCheckbox
            hasMargin
            checkboxProps={{
              ...formProps,
            }}
            indentWidth="small"
            isDirty={isDirty}
            label={t('phone_system:containers.callflows.callflow_action_dialog.seat.add.advanced')}
          />
        )}
      </HookFormInputWrapper>

      {watchAdvanced && (
        <HookFormInputWrapper control={control} name="var">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              feedback={feedback}
              inputProps={{
                ...formProps,
              }}
              inputWidth="xmedium"
              isDirty={isDirty}
              label={t(
                'phone_system:containers.callflows.callflow_action_dialog.seat.add.route_variable',
              )}
              labelWidth="xsmall"
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default SeatDialog;
