import { HookFormPasswordWrapper } from 'apps/shared/components/HookForm';
import { REGEX } from 'constant';
import { useFormContext } from 'react-hook-form';
import ComplexPasswordValidationFeedback from './components/ComplexPasswordValidationFeedback';
import { PasswordInputProps as Props } from './definition';

const PasswordInput = ({
  hasComplexPasswordValidationFeedback,
  isComplexPassword,
  isSsoEnabled,
  info,
  label,
  labelProps,
  name,
  sibling,
}: Props) => {
  const { trigger } = useFormContext();
  const options = { shouldFocus: true };

  let onBlur: () => void = () =>
    trigger(
      [name, sibling].map((field?: string) => `seat.${field}`),
      options,
    );

  if (isComplexPassword) {
    onBlur = !hasComplexPasswordValidationFeedback
      ? () => trigger(`seat.${name}`, options)
      : () => {};
  }

  return (
    <>
      <HookFormPasswordWrapper
        hasFeedback={isComplexPassword ? hasComplexPasswordValidationFeedback === undefined : true}
        isDisabled={isSsoEnabled}
        label={label}
        labelProps={labelProps}
        name={name}
        onBlur={onBlur}
        {...(isComplexPassword ? { filter: REGEX.STRING.SPECIAL_CHARACTERS } : {})}
        {...(info && isSsoEnabled ? { info } : {})}
      />
      {isComplexPassword && hasComplexPasswordValidationFeedback && (
        <ComplexPasswordValidationFeedback />
      )}
    </>
  );
};

export default PasswordInput;
