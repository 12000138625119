import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import Box from 'shared/components/Box';
import { LabeledInput } from 'shared/components/Labeled';
import { FormFields } from '../../definition';
import translations from '../../translations';

const CustomerNameCell = () => {
  const { field } = translations();

  return (
    <Box role="cell">
      <HookFormInputWrapper name={FormFields.E911CustomerName}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            feedback={feedback}
            inputProps={{
              placeholder: field[FormFields.E911CustomerName].label,
              ...formProps,
            }}
            isDirty={isDirty}
            label={field[FormFields.E911CustomerName].label}
            labelProps={{ required: true }}
          />
        )}
      </HookFormInputWrapper>
    </Box>
  );
};

export default CustomerNameCell;
