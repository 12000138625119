/**
 * @name getClassName
 * @description Get the MUI `className` value.
 *
 * @param suffix
 * @param element
 *
 * @returns Function which returns a className value.
 */
export const getClassName = (suffix: string) => (element: string) => `Mui${element}-${suffix}`;
