import { ConfigsId, useFetchConfigsByIdQuery } from 'models/Configs';
import { useDeleteVoicemailMessagesMutation, usePatchVoicemailMutation } from 'models/Voicemail';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'shared/hooks/useToast';
import { DialogContext } from 'store/contexts';
import { onConfirmResetVoicemailBox } from './utility';

export const useResetVoicemailBox = (id: string) => {
  const [isResetButtonDisabled, setResetButtonDisabled] = useState<boolean>(false);
  const dialogCtx = useContext(DialogContext);
  const { showToast } = useToast();
  const { t } = useTranslation();
  const { data: { pin_pass_sync } = {} } = useFetchConfigsByIdQuery({ id: ConfigsId.Voicemail });
  const [deleteVoicemailMessages] = useDeleteVoicemailMessagesMutation();
  const [patchVoicemail] = usePatchVoicemailMutation();

  const onResetVoicemail = useCallback(() => {
    dialogCtx.onOpen({
      open: true,
      title: t('phone_system:hooks.use_reset_voicemail_box.dialog.title'),
      confirmButtonLabel: t('phone_system:hooks.use_reset_voicemail_box.dialog.reset_button_label'),
      confirmMessage: pin_pass_sync
        ? t('phone_system:hooks.use_reset_voicemail_box.dialog.confirm_message.pin_pass_sync')
        : t('phone_system:hooks.use_reset_voicemail_box.dialog.confirm_message.not_pin_pass_sync'),
      onConfirm: async () => {
        await onConfirmResetVoicemailBox({
          deleteVoicemailMessages,
          id,
          patchVoicemail,
          pin_pass_sync,
          showToast,
          setResetButtonDisabled,
          successToastMessage: t(
            'phone_system:hooks.use_reset_voicemail_box.dialog.toast_message_success',
          ),
        });
      },
    });
  }, [deleteVoicemailMessages, dialogCtx, id, patchVoicemail, pin_pass_sync, showToast, t]);

  return {
    onResetVoicemail,
    isResetButtonDisabled,
  };
};
