import { AlertValueType } from 'apps/shared/components/E911AddressForm/definition';

const defaultProps = {
  isOpen: false,
  alertValue: {
    [AlertValueType.Count]: 0,
  },
  onClick: () => {},
};

export default defaultProps;
