import { ComponentProps } from 'shared/definition';
import { ApplySiteLocationDialogProps } from './components/ApplySiteLocationDialog';

export enum FormFields {
  E911AddressExtended = 'e911.extended_address',
  E911AddressStreet = 'e911.street_address',
  E911ContactEmails = 'e911.notification_contact_emails',
  E911CustomerName = 'e911.customer_name',
  E911Locality = 'e911.locality',
  E911PostalCode = 'e911.postal_code',
  E911Region = 'e911.region',
  E911SearchAddress = 'e911.search_address',
}

export enum AlertValueType {
  Count = 'count',
  Number = 'number',
}

export type AlertValue = {
  [AlertValueType.Count]?: number;
  [AlertValueType.Number]?: string;
};

export interface E911AddressFormProps extends ComponentProps {
  /** Is the Notification Contact Emails field visible? */
  hasContactEmails?: boolean;
  /** Alert message values */
  alertValue?: AlertValue;
  /** ApplySiteLocationDialog props */
  dialogProps?: ApplySiteLocationDialogProps;
}
