import { EndpointItemType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import { getEndpointIcon } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import Icon from 'shared/components/Icon';
import Input from 'shared/components/Input';
import { defaultValues } from '../default';
import translations from '../translations';

export const columnsSelected = (control: any, endpointsFieldArray: any) => {
  const { columns } = translations();

  return [
    {
      Header: columns.name.header,
      Cell: ({ row: { original } }: EndpointItemType) => (
        <TableCellTruncatedValue
          icon={getEndpointIcon(original.endpoint_type)}
          text={original.name}
        />
      ),
      accessor: 'name',
    },
    {
      Header: columns.delay.header,
      Cell: ({ row: { index } }: { row: { index: number } }) => (
        <HookFormInputWrapper control={control} name={`endpoints.${index}.delay`}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <Input
              feedback={feedback}
              inputProps={{ ...formProps, id: 'input-ring-group-selected-delays' }}
              type="number"
              width="xsmall"
            />
          )}
        </HookFormInputWrapper>
      ),
      accessor: 'delay',
    },
    {
      Header: columns.timeout.header,
      Cell: ({ row: { index } }: { row: { index: number } }) => (
        <HookFormInputWrapper control={control} name={`endpoints.${index}.timeout`}>
          {({ ref, isDirty, feedback, ...formProps }) => (
            <Input
              feedback={feedback}
              inputProps={{ ...formProps, id: 'input-ring-group-selected-timeouts' }}
              type="number"
              width="xsmall"
            />
          )}
        </HookFormInputWrapper>
      ),
      accessor: 'timeout',
    },
    {
      Header: '',
      Cell: ({ row: { index } }: { row: { index: number } }) => (
        <Icon
          hasHover
          name="minus-circle-outlined"
          size={22}
          themeColor="core.states.error.main"
          onClick={endpointsFieldArray.remove.bind(null, index)}
        />
      ),
      accessor: 'id',
    },
  ];
};

export const getLargestTimeout = (endpoints: Array<GroupEndpoint> | undefined = []) => {
  if (!endpoints.length) {
    return defaultValues.timeout;
  }

  const sortedTimeouts = [...endpoints].sort(
    (a, b) => parseInt(a.timeout, 10) - parseInt(b.timeout, 10),
  );

  return `${sortedTimeouts[sortedTimeouts.length - 1].timeout}`;
};
