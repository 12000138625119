import { useTranslation } from 'react-i18next';
import { LabeledText } from 'shared/components/Labeled';
import defaultProps from './default';
import { DurationProps as Props } from './definition';
import { buildDurationText } from './utility';

export type { Time as DurationTime } from './definition';

const Duration = (props: Props) => {
  const { isAllDayEvent, id, time, date } = { ...defaultProps, ...props };
  const { t } = useTranslation();

  return (
    <LabeledText
      id={id}
      label={t('phone_system:containers.time_of_day.component.duration.label.duration')}
      text={buildDurationText({ time, date, isAllDayEvent })}
    />
  );
};

export default Duration;
