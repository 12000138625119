import { ResponseBody } from '../../../../definition';
import { E911CallerIdDisplayInfo } from '../../definition';

export const getE911CallerIdDisplayInfo = ({
  formData,
  locations,
  missingAddressLabel = 'Missing Address',
  name,
  phoneNumber,
  phoneNumberInfoData,
}: {
  formData: ResponseBody | undefined;
  locations: any;
  missingAddressLabel?: string;
  name: string;
  phoneNumber: string;
  phoneNumberInfoData: any;
}) => {
  const { e911 } = phoneNumberInfoData ?? {};
  const {
    street_address = '',
    extended_address = '',
    locality = '',
    region = '',
    postal_code = '',
  } = e911 ?? {};
  const e911CallerIdDisplayInfo: E911CallerIdDisplayInfo = {
    number: phoneNumber,
    address: `${street_address}, ${
      extended_address ? `${extended_address}, ` : ''
    }${locality}, ${region}, ${postal_code}`,
    shouldUpdatePhoneNumberAddress: false, // A flag to indicate if the seat location should be also updated,
  };

  // There is no E911 address is linked with the phone number. Getting the address from seat location
  if (!e911) {
    const seatLocation = locations?.[formData?.seat?.location ?? ''];
    const {
      address_city,
      address_country,
      address_line_1,
      address_line_2,
      address_postal_code,
      address_state,
    } = seatLocation ?? {};
    if (
      seatLocation &&
      !Object.values([
        address_city,
        address_country,
        address_line_1,
        address_line_2,
        address_postal_code,
        address_state,
      ]).every((info) => info === 'Not Set')
    ) {
      e911CallerIdDisplayInfo.address = `${address_line_1}, ${
        address_line_2 ? `${address_line_2}, ` : ''
      }${address_city}, ${address_state}, ${address_country}, ${address_postal_code}`;
      e911CallerIdDisplayInfo.shouldUpdatePhoneNumberAddress = true;
      e911CallerIdDisplayInfo.addressToUpdate = {
        customer_name: name,
        street_address: address_line_1,
        extended_address: address_line_2,
        locality: address_city,
        region: address_state,
        postal_code: address_postal_code,
      };
    } else {
      e911CallerIdDisplayInfo.address = missingAddressLabel;
    }
  }
  return e911CallerIdDisplayInfo;
};
