import { joiResolver } from '@hookform/resolvers/joi';
import QueueSelect from 'apps/PhoneSystem/shared/QueueSelect';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchQueueQuery } from 'models/Queues';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Box from 'shared/components/Box';
import { LabeledCheckbox, LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import Typography from 'shared/components/Typography';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, WaitTimeDialogProps as Props, WaitTimeNodeData } from './definition';
import schema from './schema';
import translations from './translations';
import { convertWindowToSeconds, extractWindowValueAndUnit } from './utility';

const WaitTimeDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };

  const [
    window = defaultValues.window,
    window_unit = defaultValues.window_unit,
  ] = extractWindowValueAndUnit(data?.window);

  const { data: queueData, isLoading } = useFetchQueueQuery();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    watch,
  } = useForm<Data>({
    defaultValues,
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const {
    dialog,
    queue,
    route_to_another_queue,
    title,
    var_input,
    window_input,
    window_units,
  } = useMemo(() => translations(), []);

  const watchShowRouteToAnotherQueue = watch('route_to_another_queue');

  const submitHandler = (formData: Data) => {
    const { window, window_unit, ...rest } = formData;
    const selected = queueData?.find(({ id }) => id === formData.id);
    const windowSeconds = convertWindowToSeconds(Number(window), window_unit).toString();
    const nodeData: WaitTimeNodeData = {
      data: {
        ...rest,
        window: windowSeconds,
        var: formData.route_to_another_queue ? rest.var : '',
      },
      metadata: {
        name: selected!.name,
        pvt_type: 'queue',
      },
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    const updatedData = enhancedFormUtility.transformDataToFormData(
      { ...data, window, window_unit, route_to_another_queue: Boolean(data?.var) },
      defaultValues,
    );

    reset(updatedData);
  }, [data, reset, window, window_unit]);

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoading}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <Typography paragraph>{dialog.content}</Typography>

      <QueueSelect isLabelAbove control={control} label={queue.label} name="id" />

      <Box className="split" role="row">
        <Box role="cell">
          <HookFormInputWrapper control={control} name="window">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isLabelAbove
                feedback={feedback}
                inputProps={{
                  type: 'number',
                  ...formProps,
                  id: 'input-wait-time-window',
                }}
                inputWidth="small"
                isDirty={isDirty}
                label={window_input.label}
              />
            )}
          </HookFormInputWrapper>
        </Box>
        <Box role="cell">
          <HookFormSelectWrapper control={control} name="window_unit" options={window_units}>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSelect
                isLabelAbove
                isDirty={isDirty}
                label=""
                selectProps={{ ...formProps, id: 'select-window-unit' }}
                selectWidth="small"
              />
            )}
          </HookFormSelectWrapper>
        </Box>
      </Box>

      <HookFormInputWrapper isCheckbox control={control} name="route_to_another_queue">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            hasMargin
            checkboxProps={{
              ...formProps,
              id: 'checkbox-wait-time-add-route',
            }}
            feedback={feedback}
            indentWidth="small"
            isDirty={isDirty}
            label={route_to_another_queue.label}
          />
        )}
      </HookFormInputWrapper>

      {watchShowRouteToAnotherQueue && (
        <HookFormInputWrapper control={control} name="var">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isLabelAbove
              feedback={feedback}
              inputProps={{
                ...formProps,
                id: 'input-wait-time-add-var',
              }}
              inputWidth="small"
              isDirty={isDirty}
              label={var_input.label}
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default WaitTimeDialog;
