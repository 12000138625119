import { themeColor } from 'theme/local';
import theme from 'theme/mui/core';

const CONSTANTS = {
  SPLIT: {
    HEIGHT: {
      CONTAINED: 38,
      OUTLINED: 42,
    },
    WIDTH: {
      TOTAL: 150,
      RIGHT: 30,
    },
  },
};

export default {
  defaultProps: {
    disableFocusRipple: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '&.MuiButtonGroup-split': {
        width: CONSTANTS.SPLIT.WIDTH.TOTAL,
        boxShadow: 'none',
        '&.Mui-disabled': {
          borderColor: `${themeColor.core.button.grey} !important`,
        },
        '&.MuiButtonGroup-contained': {
          border: '1px solid transparent',
          '&.MuiButtonGroup-secondary': {
            borderColor: theme.palette.secondary.main,
          },
          '&.MuiButtonGroup-success': {
            borderColor: theme.palette.success.main,
          },
          '.MuiButton-root': {
            height: CONSTANTS.SPLIT.HEIGHT.CONTAINED,
          },
        },
        '&.MuiButtonGroup-outlined': {
          '.MuiButton-root': {
            height: CONSTANTS.SPLIT.HEIGHT.OUTLINED,
          },
        },
        '.MuiButton-root': {
          borderRadius: 5,
          '&:first-of-type': {
            width: '100%',
            borderRight: `1px solid ${theme.palette.common.white}`,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            '&.Mui-disabled': {
              borderRightColor: `${themeColor.core.button.border} !important`,
            },
          },
          '&:last-of-type': {
            width: CONSTANTS.SPLIT.WIDTH.RIGHT,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            '.MuiSvgIcon-root': {
              transition: theme.transitions.create(['transform'], {
                duration: theme.transitions.duration.shortest,
              }),
            },
            '&.is-open': {
              color: theme.palette.common.white,
              '&.MuiButton-containedSecondary, &.MuiButton-outlinedSecondary': {
                backgroundColor: theme.palette.secondary.dark,
              },
              '&.MuiButton-containedSuccess, &.MuiButton-outlinedSuccess': {
                backgroundColor: theme.palette.success.dark,
              },
              '.MuiSvgIcon-root': {
                transform: 'rotate(-180deg)',
              },
            },
          },
        },
      },
    },
  },
};
