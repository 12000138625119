import Edit from 'apps/PhoneSystem/containers/Voicemails/Edit';
import VoicemailSelect from 'apps/PhoneSystem/shared/VoicemailSelect';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Dialog, { DialogLink, DialogType } from 'shared/components/Dialog';
import { useToast } from 'shared/hooks/useToast';
import { onEmbeddedEditSaveSuccess } from '../../../utility';
import { VoicemailSelectWrapper as Props } from './definition';

const VoicemailSelectWrapper = ({ control, id }: Props) => {
  const { t } = useTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { showToast } = useToast();

  return (
    <>
      <Box className="split split-with-link" role="row">
        <Box role="cell">
          <VoicemailSelect
            hasDirtyIcon
            isLabelAbove
            control={control}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.voicemail.add.label',
            )}
            name="id"
          />
        </Box>
        <Box role="cell">
          {id && (
            <DialogLink
              text={t(
                'phone_system:containers.callflows.callflow_action_dialog.voicemail.edit.label',
              )}
              onClick={() => setIsEditDialogOpen(true)}
            />
          )}
        </Box>
      </Box>
      <Dialog
        open={isEditDialogOpen}
        title={t('phone_system:containers.callflows.callflow_action_dialog.voicemail.edit.title')}
        type={DialogType.CallflowActions}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <Edit
          handleCancel={() => setIsEditDialogOpen(false)}
          handleSaveSuccess={() => onEmbeddedEditSaveSuccess({ showToast, setIsEditDialogOpen })}
          id={id}
        />
      </Dialog>
    </>
  );
};

export default VoicemailSelectWrapper;
