import i18next from 'i18next';

const translations = () => ({
  button: {
    cancel: i18next.t('common:cancel'),
    update: i18next.t('common:update'),
  },
  content: i18next.t(
    'common:components.e911_address_dialog.components.address_update_confirm_dialog.content',
  ),
  title: i18next.t(
    'common:components.e911_address_dialog.components.address_update_confirm_dialog.title',
  ),
});

export default translations;
