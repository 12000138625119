import { joiResolver } from '@hookform/resolvers/joi';
import QueueSelect from 'apps/PhoneSystem/shared/QueueSelect';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useFetchQueueQuery } from 'models/Queues';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, QueueDialogProps as Props, QueueNodeData } from './definition';
import schema from './schema';

const QueueDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const { data: queueData, isLoading } = useFetchQueueQuery();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
      route_to_another_queue: Boolean(data?.var),
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const watchRouteToAnotherQueue = watch('route_to_another_queue');

  const submitHandler = (formData: Data) => {
    const selected = queueData?.find(({ id }) => id === formData.id);
    const data = { ...formData };

    if (!data.route_to_another_queue) {
      data.var = '';
    }

    const nodeData: QueueNodeData = {
      data,
      metadata: {
        name: selected?.name,
        pvt_type: 'acdc_member',
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.queue.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <QueueSelect
        isLabelAbove
        control={control}
        label={t('phone_system:containers.callflows.callflow_action_dialog.queue.add.queue')}
        name="id"
      />

      <HookFormInputWrapper isCheckbox control={control} name="enter_as_callback">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            isCheckboxLeft
            checkboxProps={{
              ...formProps,
              id: 'checkbox-queue-enter-as-callback',
            }}
            feedback={feedback}
            indentWidth="small"
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.queue.add.enter_as_callback',
            )}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper isCheckbox control={control} name="route_to_another_queue">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledCheckbox
            hasMargin
            checkboxProps={{
              ...formProps,
              id: 'checkbox-queue-route-to-queue',
            }}
            feedback={feedback}
            indentWidth="small"
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.queue.add.route_to_another_queue',
            )}
          />
        )}
      </HookFormInputWrapper>

      {watchRouteToAnotherQueue && (
        <HookFormInputWrapper control={control} name="var">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              isLabelAbove
              feedback={feedback}
              inputProps={{
                ...formProps,
                id: 'input-queue-add-var',
              }}
              inputWidth="small"
              isDirty={isDirty}
              label={t('phone_system:containers.callflows.callflow_action_dialog.queue.add.var')}
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default QueueDialog;
