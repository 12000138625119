import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import kebabCase from 'lodash/kebabCase';
import { forwardRef } from 'react';
import MenuItem, { SplitButtonMenuItem as Item } from './components/MenuItem';
import defaultProps from './default';
import { MenuProps as Props } from './definition';
import { getClassName } from './utility';

const Menu = forwardRef((props: Props, ref: any) => {
  const { isOpen, items, setIsOpen } = { ...defaultProps, ...props };
  const getClass = getClassName('split-button');

  const modifiers = [
    {
      name: 'offset',
      options: {
        offset: [-10, 6],
      },
    },
  ];

  return (
    <Popper
      transition
      anchorEl={ref?.current}
      className={getClass('Popper')}
      data-test-id="component-shared-split-button-menu"
      modifiers={modifiers}
      open={isOpen}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps}>
          <Paper className={getClass('Paper')}>
            <ClickAwayListener
              onClickAway={({ target }: MouseEvent | TouchEvent) =>
                !ref.current?.contains(target) && setIsOpen(false)
              }
            >
              <MenuList className={getClass('List')}>
                {items.map(({ icon, label, onClick }: Item) => (
                  <MenuItem
                    key={`component-shared-split-button-menu-list-item-${kebabCase(label)}`}
                    getClass={getClass}
                    icon={icon}
                    label={label}
                    onClick={() => {
                      onClick();
                      setIsOpen(false);
                    }}
                  />
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
});

export default Menu;
