import { themeColor } from 'theme';

export const CONSTANTS = {
  COLUMN: {
    ICON: {
      WIDTH: {
        LARGE: 160,
        SMALL: 120,
        XSMALL: 100,
      },
    },
  },
  GAP: '18px',
  HEIGHT: {
    BORDER: '1px',
    CELL: {
      TBODY: '40px',
      THEAD: '48px',
    },
    INPUT: '30px',
    TEXTAREA: '40px',
  },
  PADDING: {
    SELECTION: '20px',
    SIDE: '30px',
    SORT: '36px',
  },
  ROW_COUNT: 10,
  WIDTH: {
    // PKK-TODO: Review and consolidate with `COLUMN.ICON.WIDTH` above
    ICON: {
      ACTION: '100px',
      DRAG: '40px',
    },
    INPUT: '100px',
    SELECTION: '40px',
  },
  get BORDER() {
    return `${this.HEIGHT.BORDER} solid ${themeColor.core.border.color}`;
  },
};
