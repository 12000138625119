import i18next from 'i18next';

const translations = () => ({
  dialog: {
    content: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.wait_time.add.explanation',
    ),
  },
  queue: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.wait_time.add.queue',
    ),
  },
  route_to_another_queue: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.wait_time.add.route',
    ),
  },
  title: i18next.t('phone_system:containers.callflows.callflow_action_dialog.wait_time.title'),
  var_input: {
    label: i18next.t('phone_system:containers.callflows.callflow_action_dialog.wait_time.add.var'),
  },
  window_units: [
    { value: 'minutes', label: 'minutes' },
    { value: 'hours', label: 'hours' },
  ],
  window_input: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.wait_time.add.window',
    ),
  },
});

export default translations;
