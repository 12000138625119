import { ELEMENT_ID } from 'apps/PhoneSystem/constant';
import FormAddressAlertRow from 'apps/shared/components/FormAddressAlertRow';
import { FormContext } from 'apps/shared/components/FormContext';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import useFormNumbersInUsePicker from 'apps/shared/hooks/useFormNumbersInUsePicker';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoScrollByHash } from 'shared/components/AutoScrollByHash';
import LabeledCheckbox from 'shared/components/Labeled/components/LabeledCheckbox';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'caller_id.emergency.name',
  'caller_id.emergency.number',
  'caller_id.external.name',
  'caller_id.external.number',
  'caller_id.internal.name',
  'caller_id.internal.number',
  'enabled',
  'presence_id',
  'should_create_caller_id',
];

const CallerIdSection = () => {
  const { t } = useTranslation();
  const contextName = useContext(FormContext);

  const { formNumbersInUsePicker, setIsNumbersInUsePickerOpen } = useFormNumbersInUsePicker({
    field: `${contextName}caller_id.emergency.number`,
  });

  return (
    <>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="presence_id">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                inputProps={{
                  ...formProps,
                }}
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.caller_id.presence')}
              />
            )}
          </HookFormInputWrapper>
          <HookFormInputWrapper isCheckbox name="should_create_caller_id">
            {({ ref, value, isDirty, ...formProps }) => (
              <LabeledCheckbox
                checkboxProps={{
                  ...formProps,
                }}
                indentWidth="large"
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.caller_id.should_create_caller_id')}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.seats.section.caller_id.in_house_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.internal.name">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_name.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.caller_id.caller_id_name.label')}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          <HookFormInputWrapper name="caller_id.internal.number">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.caller_id.caller_id_number.label')}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <h2>{t('phone_system:containers.seats.section.caller_id.outbound_calls')}</h2>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.external.name">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_name.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.caller_id.caller_id_name.label')}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          <HookFormInputWrapper name="caller_id.external.number">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.caller_id.caller_id_number.label')}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <AutoScrollByHash hash={ELEMENT_ID.E911_CALLER_ID}>
        <h2>{t('phone_system:containers.seats.section.caller_id.emergency_calls')}</h2>
      </AutoScrollByHash>
      <div role="row">
        <div role="cell">
          <HookFormInputWrapper name="caller_id.emergency.name">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_name.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.caller_id.caller_id_name.label')}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>

        <div role="cell">
          <HookFormInputWrapper name="caller_id.emergency.number">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledInput
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.seats.section.caller_id.caller_id_number.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.caller_id.caller_id_number.label')}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
          {formNumbersInUsePicker}
        </div>
      </div>

      <FormAddressAlertRow
        field={`${contextName}caller_id.emergency.number`}
        setIsNumbersInUsePickerOpen={setIsNumbersInUsePickerOpen}
      />
    </>
  );
};

export default CallerIdSection;
