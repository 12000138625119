import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { LabeledSelect, LabeledTextarea } from 'shared/components/Labeled';
import { TTSFormProps as Props } from './definition';

/*
 * These are the values available from the schema on the backend.
 */
export const availableRecordings = [
  'female/en-US',
  'male/en-US',
  'female/en-CA',
  'female/en-AU',
  'female/en-GB',
  'male/en-GB',
  'female/es-US',
  'male/es-US',
  'female/us-US',
  'female/zh-CN',
  'male/zh-CN',
  'female/zh-HK',
  'female/zh-TW',
  'female/ja-JP',
  'male/ja-JP',
  'female/ko-KR',
  'male/ko-KR',
  'female/da-DK',
  'female/de-DE',
  'male/de-DE',
  'female/ca-ES',
  'female/es-ES',
  'male/es-ES',
  'female/fi-FI',
  'female/fr-CA',
  'male/fr-CA',
  'female/fr-FR',
  'male/fr-FR',
  'female/it-IT',
  'male/it-IT',
  'female/nb-NO',
  'female/nl-NL',
  'female/pl-PL',
  'female/pt-BR',
  'female/pt-PT',
  'male/pt-PT',
  'female/ru-RU',
  'male/ru-RU',
  'female/sv-SE',
  'female/hu-HU',
  'female/cs-CZ',
  'female/tr-TR',
  'male/tr-TR',
  'male/ru-RU/Vladimir',
  'female/ru-RU/Julia',
  'female/ru-RU/Anna',
  'female/ru-RU/Viktoria',
  'male/ru-RU/Alexander',
  'female/ru-RU/Maria',
  'female/ru-RU/Lidia',
  'es-ES-Standard-A',
  'it-IT-Standard-A',
  'ja-JP-Standard-A',
  'ko-KR-Standard-A',
  'pt-BR-Standard-A',
  'tr-TR-Standard-A',
  'sv-SE-Standard-A',
  'nl-NL-Standard-A',
  'en-US-Wavenet-D',
  'de-DE-Wavenet-A',
  'de-DE-Wavenet-B',
  'de-DE-Wavenet-C',
  'de-DE-Wavenet-D',
  'en-AU-Wavenet-A',
  'en-AU-Wavenet-B',
  'en-AU-Wavenet-C',
  'en-AU-Wavenet-D',
  'en-GB-Wavenet-A',
  'en-GB-Wavenet-B',
  'en-GB-Wavenet-C',
  'en-GB-Wavenet-D',
  'en-US-Wavenet-A',
  'en-US-Wavenet-B',
  'en-US-Wavenet-C',
  'en-US-Wavenet-E',
  'en-US-Wavenet-F',
  'fr-FR-Wavenet-A',
  'fr-FR-Wavenet-B',
  'fr-FR-Wavenet-C',
  'fr-FR-Wavenet-D',
  'it-IT-Wavenet-A',
  'ja-JP-Wavenet-A',
  'nl-NL-Wavenet-A',
  'en-GB-Standard-A',
  'en-GB-Standard-B',
  'en-GB-Standard-C',
  'en-GB-Standard-D',
  'en-US-Standard-B',
  'en-US-Standard-C',
  'en-US-Standard-D',
  'en-US-Standard-E',
  'de-DE-Standard-A',
  'de-DE-Standard-B',
  'en-AU-Standard-A',
  'en-AU-Standard-B',
  'en-AU-Standard-C',
  'en-AU-Standard-D',
  'fr-CA-Standard-A',
  'fr-CA-Standard-B',
  'fr-CA-Standard-C',
  'fr-CA-Standard-D',
  'fr-FR-Standard-A',
  'fr-FR-Standard-B',
  'fr-FR-Standard-C',
  'fr-FR-Standard-D',
];

const TTSFormSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();

  const ttsVoices = [
    { value: 'male/en-US', label: 'male/en-US' },
    { value: 'female/en-US', label: 'female/en-US' },
    { value: 'male/fr-FR', label: 'male/fr-FR' },
    { value: 'female/fr-FR', label: 'female/fr-FR' },
    { value: 'male/es-ES', label: 'male/es-ES' },
    { value: 'female/es-ES', label: 'female/es-ES' },
    { value: 'male/de-DE', label: 'male/de-DE' },
    { value: 'female/de-DE', label: 'female/de-DE' },
    { value: 'male/ja-JP', label: 'male/ja-JP' },
    { value: 'female/ja-JP', label: 'female/ja-JP' },
  ];

  /*  const ttsVoices = availableRecordings.map(
    (currentKey) => ({value: currentKey, label: currentKey})
  ); */

  return (
    <>
      {/* TTS Text */}
      <HookFormInputWrapper name="tts.text">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledTextarea
            isLabelAbove
            label={t('phone_system:containers.media.field.text.title')}
            labelProps={{ required: true }}
            inputProps={{
              ...formProps,
            }}
            feedback={feedback}
            isDirty={isDirty}
          />
        )}
      </HookFormInputWrapper>

      {/* TTS Voice */}
      <HookFormSelectWrapper name="tts.voice" options={ttsVoices}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            label={t('phone_system:containers.media.field.select.title')}
            selectWidth="medium"
            selectProps={{
              ...formProps,
            }}
            isDirty={isDirty}
            feedback={feedback}
          />
        )}
      </HookFormSelectWrapper>
    </>
  );
};

export default TTSFormSection;
