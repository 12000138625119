import { TooltipProps } from '@mui/material';
import { CHARACTER } from 'constant';
import { Env } from 'definition';
import _, { isEmpty, isEqual, isNil, omitBy } from 'lodash';
import { FieldErrors, FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { INPUT_LABEL_CONSTANTS } from 'shared/components/InputLabel';
import { MessageType } from 'shared/components/InputMessage';
import { Justify } from 'shared/components/Table/components/definition';
import Tooltip from 'shared/components/Tooltip';
import { props, value } from './default';
import { GetWidth, MarginType, Width } from './definition';

/**
 * @name alphafy
 * @description Transforms a CSS rgb() call to rgba(); this is useful when you
 *              would like to add alpha to an existing color.
 *
 * @param [rgb] Original rgb function call
 * @param [alpha] level of opacity from 0 to 1
 *
 * @example
 *
 * a {
 *   color: ${utility.alphafy('rgb(100, 100, 100)', 0.5)};
 * }
 *
 * @return CSS rgba function call
 */
export const alphafy = (rgb: string, alpha: number): string =>
  rgb.replace('rgb', 'rgba').replace(')', `, ${alpha})`);

/**
 * @name checkDomain
 * @description This method returns the 'host + domain' and remove the other character
 *              from the URL that makes it easy to compare between provided URL and the
 *              current host URL. Used in the isExternalURL function below.
 *
 * @param [url]
 *
 * @returns Host + domain value
 */
const checkDomain = (url: string): string => {
  if (url.indexOf('//') === 0) {
    url = global.location.protocol + url;
  }
  return url
    .toLowerCase()
    .replace(/([a-z])?:\/\//, '$1')
    .split('/')[0];
};

/**
 * @name checkFormEntities
 * @description Check either the form dirty fields list or error list
 *              and return true if the given list is populated.
 *
 * @param entities
 *
 * @returns Flag
 */
export const checkFormEntities = (
  entities: FieldErrors<FieldValues> | FieldNamesMarkedBoolean<FieldValues>,
) => Object.keys(entities ?? {}).length > 0;

/**
 * @name compareStrings
 * @description Compares two strings for sorting purposes. The comparison
 *              is based on numbers, alphabets, and symbols in the string.
 *
 * @param {string} [strA=''] - The first string to compare. Defaults to an empty string
 * @param {string} [strB=''] - The second string to compare. Defaults to an empty string
 *
 * @return A negative number if `strA` should be sorted before `strB`, a positive number
 *         if `strA` should be sorted after `strB`, and zero if they are equal
 */
export const compareStrings = (strA = '', strB = ''): number => {
  const numA = extractNumber(strA);
  const numB = extractNumber(strB);

  if (numA !== numB) {
    return numA - numB;
  }

  const alphA = strA.replace(/^\d+/, '').trim();
  const alphB = strB.replace(/^\d+/, '').trim();

  if (alphA !== alphB) {
    return alphA.localeCompare(alphB);
  }

  const symA = extractSymbol(strA);
  const symB = extractSymbol(strB);

  if (symA !== symB) {
    if (symA === '') {
      return 1;
    }
    if (symB === '') {
      return -1;
    }
    return symA.localeCompare(symB);
  }

  return strA.localeCompare(strB);
};

/**
 * @name convertToKebabCase
 * @description Convert text with whitespace to kebab case.
 *
 * @param [value] Text with whitespace
 *
 * @returns kebab-case value
 */
export const convertToKebabCase = (value: string) => value.replace(/[\W_]+/g, '-').toLowerCase();

/**
 * @name CSVToArray // TODO: Rename to csvToArray
 * @description Takes in a CSV string and returns an array of rows of columns.
 *
 * @param [strData] - the raw data
 * @param [strDelimiter] - defaults to comma
 *
 * @return Array of values
 */
export const CSVToArray = (strData: string, strDelimiter = ','): string[][] => {
  // Create a regular expression to parse the CSV values.
  const objPattern = new RegExp(
    // Delimiters.
    `(\\${strDelimiter}|\\r?\\n|\\r|^)` +
      // Quoted fields.
      `(?:"([^"]*(?:""[^"]*)*)"|` +
      // Standard fields.
      `([^"\\${strDelimiter}\\r\\n]*))`,
    'gi',
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  const arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  let arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while ((arrMatches = objPattern.exec(strData))) {
    // Get the delimiter that was found.
    const strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }

    let strMatchedValue: any;

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
    } else {
      // We found a non-quoted value.
      strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    if (strMatchedValue.trim()) {
      // @ts-ignore
      arrData[arrData.length - 1].push(strMatchedValue);
    }
  }

  // Return the parsed data.
  return arrData;
};

/**
 * @name extractNumber
 * @description Extracts the leading number from a string.
 *
 * @param {string} [str] - The string from which to extract the number
 *
 * @return The extracted number. If there is no leading number, returns 0.
 */
const extractNumber = (str: string): number => {
  const match = str.match(/^\d+/); // TODO: Add as global constant
  return match ? parseInt(match[0], 10) : 0;
};

/**
 * @name extractSymbol
 * @description Extracts the first non-alphanumeric, non-whitespace symbol from a string.
 *
 * @param {string} [str] - The string from which to extract the symbol
 *
 * @return The extracted symbol. If there are no such symbols, returns an empty string
 */
const extractSymbol = (str: string): string => {
  const match = str.match(/[^a-zA-Z0-9\s]/); // TODO: Add as global constant
  return match ? match[0] : '';
};

/**
 * @name generateId
 * @description Creates a unique id, given an element type.
 *
 * @param [element] Element value
 * @param [max] Maximum number value
 *
 * @return Unique Id
 */
export const generateId = (element: string, max = 9999999999): string =>
  `${element}-${Math.floor(Math.random() * max) + 1}`;

/**
 * @name generateKey
 * @description Creates a unique key, given the index and string values.
 *
 * @param [index] Array index
 * @param [value] String value
 *
 * @return Unique key
 */
export const generateKey = (index: number, value: string): string =>
  `${index}-${_.kebabCase(value)}`;

/**
 * @name generateRandomTextString
 * @description Generate random text string with a-z, A-Z and 0-9.
 *
 * @param [length] Length of text
 *
 * @returns Random text string
 */
export const generateRandomTextString = (length = 12): string => {
  const initialValue = 48;
  const numberOfCharacters = 62;
  const forbiddenRanges = [
    { min: 58, max: 64, offset: 7 },
    { min: 91, max: 96, offset: 6 },
  ];
  const returnString: Array<string> = [];
  for (let index = 0; index < length; index++) {
    let randomCharIndex = initialValue + Math.floor(Math.random() * numberOfCharacters);
    forbiddenRanges.forEach((currentRange) => {
      if (randomCharIndex >= currentRange.min && randomCharIndex <= currentRange.max) {
        randomCharIndex += currentRange.offset;
      }
    });
    returnString.push(String.fromCharCode(randomCharIndex));
  }
  return returnString.join('');
};

/**
 * @name generateUserName
 * @description Generate a random user name.
 *
 * @param [length] Length of name
 *
 * @returns Random username
 */
export const generateUserName = (length = 6) => `user_${generateRandomTextString(length)}`;

/**
 * @name getClassName
 * @description Get alignment class based in column id suffix
 *
 * @param id Column id
 *
 * @returns Attribute object.
 */
export const getClassName = (id: string): object => {
  const justify = id.split('.').pop();
  return Object.values(Justify).includes(justify as Justify) ? { className: justify } : {};
};

/**
 * @name getDeepKeys
 * @description Get keys from a nested object.
 *
 * @param [object] The nested object from which we want to extract the keys.
 *
 * @example
 *
 * getDeepKeys({a: {b: 1, c: 2}}) => ['a', 'a.b', 'a.c']
 *
 * @return Keys array
 */
export const getDeepKeys = (object: any): string[] => {
  let keys: Array<string> = [];
  for (var key in object) {
    keys.push(key);
    if (typeof object[key] === 'object') {
      const subkeys = getDeepKeys(object[key]);
      keys = keys.concat(subkeys.map((subkey: string) => `${key}.${subkey}`));
    }
  }
  return keys;
};

/**
 * @name getFeedbackColor
 * @description Get a feedback color given a feedback type.
 *
 * TODO: This may work better as a statically defined property/sub-object of the global color object
 *
 * @param [props] Props object
 * @param [type] Feedback/message type
 *
 * @return HTML color value
 */
export const getFeedbackColor = (props: any, type: MessageType): string => {
  const COLOR: { [key: string]: string } = {
    error: 'core.color.red',
    secondary: 'whitelabel.secondary.color',
    success: 'core.color.green.default',
    warning: 'core.color.yellow',
  };
  return _.get(props.theme, COLOR[type]);
};

/**
 * @name getWidth
 * @description Returns a width value, given size and type.
 *
 * @param [object] getWidth property object
 * @property [width]  Width property object, specifying label and input widths
 * @property [type] Specifies the element type
 * @property [offset] Specifies an additional offset value
 * @property [hasMargin] Specifies the explicit adding of margin
 * @property [hasDirtyIcon] Specifies if the dirty icon is being displayed
 * @property [hasInfoIcon] Specifies if the info icon is being displayed
 * @property [isReturnPixels] Specifies if the return value is in pixels
 * @property [leftMargin] Specifies the left margin
 *
 * @example
 *
 * div {
 *   width: ${utility.getWidth({ width: { input: 'medium', label: 'medium' }, type: 'input' })};
 * }
 *
 * @return Width value}
 */
export const getWidth = ({
  width = props.getWidth.width as Width,
  type = props.getWidth.type as string,
  offset = props.getWidth.offset as number,
  hasMargin = props.getWidth.hasMargin as boolean,
  hasDirtyIcon = props.getWidth.hasDirtyIcon as boolean,
  hasInfoIcon = props.getWidth.hasInfoIcon as boolean,
  isReturnPixels = props.getWidth.isReturnPixels as boolean,
  leftMargin = props.getWidth.leftMargin as MarginType,
}: GetWidth): number | string => {
  const WIDTH: Record<string, number> = { ICON: 32, MARGIN: 36 }; // TODO: Add global constant
  const _hasMargin: boolean =
    (width.label !== 'none' && width.label !== 'small' && type !== 'label') ||
    (width.label !== 'none' && width.label !== 'small' && type === 'label' && hasMargin);
  const _width: number | string =
    typeof value.getWidth[type][width.input] === 'number'
      ? value.getWidth.label[width.label] +
        value.getWidth[type][width.input] +
        (_hasMargin ? WIDTH.MARGIN : 0) +
        (hasDirtyIcon ? WIDTH.ICON : 0) +
        (hasInfoIcon ? WIDTH.ICON : 0) +
        (leftMargin ? INPUT_LABEL_CONSTANTS.MARGIN.DEFAULT[leftMargin] * 6 : 0) +
        (offset ?? 0)
      : value.getWidth[type][width.input];

  return isReturnPixels && typeof _width === 'number' ? `${_width}px` : _width;
};

/**
 * @name isEnv
 * @description Get the environment settings.
 */
export const isEnv: Record<string, boolean> = {
  development: window.nemo?.env === Env.Development,
  staging: window.nemo?.env === Env.Staging,
  production: window.nemo?.env === Env.Production,
};

/**
 * @name isExternalURL // TODO: Rename isExternalUrl
 * @description
 *
 * @param [url]
 *
 * @returns Flag
 */
export const isExternalURL = (url: string): boolean =>
  (url.indexOf(':') > -1 || url.indexOf('//') > -1) &&
  checkDomain(global.location.href) !== checkDomain(url);

/**
 * @name isModified
 * @description Compare the truthy fields of two objects to determine
 *              if an object has been substantively modified.
 *
 * @param initialValues
 * @param currentValues
 *
 * @returns Flag
 */
export function isModified(initialValues: any, currentValues: any): boolean {
  const prunedInitial = omitBy(initialValues, (value) => isNil(value) || isEmpty(value));
  const prunedCurrent = omitBy(currentValues, (value) => isNil(value) || isEmpty(value));
  return !isEqual(prunedInitial, prunedCurrent);
}

/**
 * @name maybeTooltipComponent
 * @description Maybe wrap the given component in a tooltip... maybe not ;-)
 *
 *              The wrapper <div> is necessary as, without it, the tooltip will
 *              not appear on hover of a button until the button has been clicked.
 *
 * @param [object]
 * @property hasWrapper
 * @property component
 * @property tooltip
 * @property tooltipProps
 *
 * @returns Component
 */
export const maybeTooltipComponent = ({
  hasWrapper = true,
  component,
  tooltip,
  tooltipProps,
}: {
  hasWrapper?: boolean;
  component: JSX.Element;
  tooltip?: string;
  tooltipProps?: TooltipProps;
}): JSX.Element =>
  tooltip ? (
    <Tooltip title={tooltip} {...tooltipProps}>
      {hasWrapper ? <div>{component}</div> : component}
    </Tooltip>
  ) : (
    component
  );

/**
 * @name validateRegexForSchema
 * @description Check if an value is a valid Regex.
 *
 * @param [value] - the value being validated
 * @param [helpers] - object with error helper
 *
 * @return Value or throw validation error
 */
export const validateRegexForSchema = (
  value: string,
  helpers: { error: (code: string) => any },
) => {
  try {
    // eslint-disable-next-line no-new
    new RegExp(value);
    return value;
  } catch (e) {
    return helpers.error('string.pattern.base');
  }
};

/**
 * @name stripPlusSign
 * @description Remove the plus sign character from the target value.
 *
 * @param value
 *
 * @returns Plus-sign-less string value.
 */
export const stripPlusSign = (value: string) => (value ? value.replace(CHARACTER.PLUS, '') : '');
