import { joiResolver } from '@hookform/resolvers/joi';
import TimeOfDaySelect from 'apps/PhoneSystem/shared/TimeOfDaySelect';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, TimeOfDayDisableNodeData, TimeOfDayDisableProps as Props } from './definition';
import schema from './schema';

const TimeOfDayDisableDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const submitHandler = (formData: Data) => {
    const nodeData: TimeOfDayDisableNodeData = { data: formData };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      title={t(
        'phone_system:containers.callflows.callflow_action_dialog.time_of_day_disable.title',
      )}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <TimeOfDaySelect control={control} name="rules" />
    </CallflowActionsDialog>
  );
};

export default TimeOfDayDisableDialog;
