import i18next from 'i18next';
import { Trans } from 'react-i18next';

const translations = () => ({
  default_account_location: i18next.t(
    'common:components.e911_address_form.components.apply_site_location_dialog.default_account_location',
  ),
  table: {
    columns: {
      display_name: {
        header: i18next.t(
          'common:components.e911_address_form.components.apply_site_location_dialog.table.column.location_name',
        ),
      },
      address_line_1: {
        header: i18next.t(
          'common:components.e911_address_form.components.apply_site_location_dialog.table.column.street_address',
        ),
      },
      address_country: {
        header: i18next.t(
          'common:components.e911_address_form.components.apply_site_location_dialog.table.column.country',
        ),
      },
      address_state: {
        header: i18next.t(
          'common:components.e911_address_form.components.apply_site_location_dialog.table.column.state_province',
        ),
      },
      address_city: {
        header: i18next.t(
          'common:components.e911_address_form.components.apply_site_location_dialog.table.column.city',
        ),
      },
      address_postal_code: {
        header: i18next.t(
          'common:components.e911_address_form.components.apply_site_location_dialog.table.column.postal_code',
        ),
      },
      default: {
        header: i18next.t('common:default'),
        info: i18next.t(
          'common:components.e911_address_form.components.apply_site_location_dialog.table.column.default.info',
        ),
      },
    },
    no_items: (
      <Trans
        components={{ underline: <span className="underline" /> }}
        i18nKey="common:components.e911_address_form.components.apply_site_location_dialog.table.no_items"
      />
    ),
  },
  toast: {
    success: i18next.t(
      'common:components.e911_address_form.components.apply_site_location_dialog.toast.success',
    ),
  },
});

export default translations;
