import { addressProvider } from 'shared/components/AddressAutoComplete/utility';

export const FIELDS_COMPONENT_MAPPING = [
  {
    fieldName: 'e911.street_address',
    type: 'street_address',
    valueProvider: addressProvider,
  },
  {
    fieldName: 'e911.extended_address',
    type: 'extended_address',
    valueProvider: addressProvider,
  },
  { fieldName: 'e911.postal_code', valueProvider: 'postal_code' },
  {
    fieldName: 'e911.region',
    hasShortName: true,
    valueProvider: 'administrative_area_level_1',
  },
  { fieldName: 'e911.locality', valueProvider: 'locality' },
];
