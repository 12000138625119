import { joiResolver } from '@hookform/resolvers/joi';
import {
  defaultValues as e911AddressDefaultValues,
  schema,
} from 'apps/shared/components/E911AddressForm';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { useFetchPhoneNumberByIdQuery } from 'models/PhoneNumber';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { stripPlusSign } from 'shared/utility';
import { AddressDialog, AddressUpdateConfirmDialog } from './components';
import defaultProps from './default';
import { DialogType, E911AddressDialogProps as Props, FormInput, IsDialogOpen } from './definition';

export type { IsDialogOpen };

const E911AddressDialog = (props: Props) => {
  const { isOpen, selectedRows, onUpdate, setIsOpen } = merge({}, defaultProps, props);
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();

  const { data: phoneNumberData } = useFetchPhoneNumberByIdQuery(
    { id: stripPlusSign((selectedRows[0] as any)?.e911.number) },
    { skip: selectedRows.length !== 1 },
  );

  const defaultValues = useMemo(
    () =>
      produce(e911AddressDefaultValues, (draft: any) => {
        if (selectedRows.length === 1 && !isEmpty(phoneNumberData?.e911)) {
          Object.entries(phoneNumberData.e911).forEach(([key, value]: any) => {
            draft.e911[key] = value;
          });
        }
        delete draft.e911.notification_contact_emails;
      }),
    [phoneNumberData, selectedRows.length],
  );

  const formMethods = useForm<FormInput>({
    defaultValues,
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });
  const { handleSubmit, reset, setError } = formMethods;

  const onClick = ({
    isAddressUpdateConfirm = false,
  }: {
    isAddressUpdateConfirm?: boolean;
  } = {}) => ({
    close: () => {
      reset(defaultValues);
      setIsOpen((isDialogOpen: IsDialogOpen) => ({
        ...isDialogOpen,
        [isAddressUpdateConfirm ? DialogType.AddressUpdateConfirm : DialogType.Address]: false,
      }));
    },
    update: handleSubmit(
      (formData: any) => {
        onUpdate({
          isAccountAddressUpdateConfirmed: isAddressUpdateConfirm,
          formData,
          callback: () => {
            showToast.success(t('common:components.e911_address_dialog.toast.success'));
            onClick({ isAddressUpdateConfirm }).close();
          },
        });
      },
      (errors: any) => {
        showErrorMessage({ errors, setError });
      },
    ),
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <FormProvider {...formMethods}>
      <AddressDialog
        alertValue={{ count: selectedRows.length }}
        isOpen={isOpen.address}
        onClick={onClick()}
      />
      <AddressUpdateConfirmDialog
        isOpen={isOpen.addressUpdateConfirm}
        onClick={onClick({ isAddressUpdateConfirm: true })}
      />
    </FormProvider>
  );
};

export default E911AddressDialog;
