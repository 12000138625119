import { useFetchAccountQuery } from 'models/Account';
import { useFetchPhoneNumberByIdQuery } from 'models/PhoneNumber';
import { useTranslation } from 'react-i18next';
import { ResponseBody } from '../../definition';
import { getE911CallerIdDisplayInfo } from './utility/getE911CallerIdDisplayInfo';

export const useE911CallerIdDisplayInfo = (formData: ResponseBody | undefined) => {
  const phoneNumber = formData?.seat?.phone_number ?? '';
  const { t } = useTranslation();
  const { data: phoneNumberInfoData } = useFetchPhoneNumberByIdQuery(
    { id: phoneNumber },
    { skip: !phoneNumber },
  );
  const { data: { locations, name } = {} } = useFetchAccountQuery();

  // Get the linked e911 address of the phone number
  return getE911CallerIdDisplayInfo({
    formData,
    locations,
    missingAddressLabel: t(
      'phone_system:containers.seats.update_e911_number_dialog.missing_address',
    ),
    name,
    phoneNumber,
    phoneNumberInfoData,
  });
};
