import { joiResolver } from '@hookform/resolvers/joi';
import VoicemailSelect from 'apps/PhoneSystem/shared/VoicemailSelect';
import { useFetchVoicemailsQuery } from 'models/Voicemail';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { CheckVoicemailDialogProps as Props, CheckVoicemailNodeData, Data } from './definition';
import schema from './schema';

const CheckVoicemailDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const { data: voicemailData, isLoading } = useFetchVoicemailsQuery();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const submitHandler = (formData: Data) => {
    const selected = voicemailData?.find(({ id }) => id === formData.id);
    const nodeData: CheckVoicemailNodeData = {
      data: formData,
      metadata: {
        name: selected?.mailbox,
        pvt_type: 'voicemail',
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.check_voicemail.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <VoicemailSelect
        isLabelAbove
        control={control}
        label={t(
          'phone_system:containers.callflows.callflow_action_dialog.check_voicemail.add.vmbox',
        )}
        name="id"
      />
    </CallflowActionsDialog>
  );
};

export default CheckVoicemailDialog;
