import { ShowToast } from 'shared/hooks/useToast';

export const onConfirmResetVoicemailBox = async ({
  deleteVoicemailMessages,
  id,
  patchVoicemail,
  pin_pass_sync,
  showToast,
  setResetButtonDisabled,
  successToastMessage,
}: {
  deleteVoicemailMessages: any;
  id: string;
  patchVoicemail: any;
  pin_pass_sync: boolean;
  showToast: ShowToast;
  setResetButtonDisabled: (value: boolean) => void;
  successToastMessage: string;
}) => {
  try {
    await deleteVoicemailMessages({ id }).unwrap();
    await patchVoicemail({
      id,
      body: {
        media: {
          unavailable: null,
        },
        is_setup: false,
        ...(pin_pass_sync ? {} : { pin: null }),
      },
    }).unwrap();
    showToast.success(successToastMessage);
    setResetButtonDisabled(true);
  } catch (e) {
    showToast.error();
  }
};
