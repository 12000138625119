import SiteSelectionDialog from 'apps/Numbers/components/SiteSelectionDialog';
import { useFetchAccountBillingByIdQuery } from 'models/Account';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Loading from 'shared/components/Loading';
import { useToast } from 'shared/hooks/useToast';
import { FormFields } from '../../definition';
import ApplyButton from './components/ApplyButton';
import { ApplySiteLocationDialogProps as Props } from './definition';
import StyledApplySiteLocationDialog from './style';
import translations from './translations';
import { columns, setFieldValue } from './utility';

export type { ApplySiteLocationDialogProps } from './definition';

const ApplySiteLocationDialog = ({ selection, title }: Props) => {
  const { setValue } = useFormContext();
  const { showToast } = useToast();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [locationsData, setLocationsData] = useState<any>([]);
  const { data, isLoading } = useFetchAccountBillingByIdQuery();
  const { default_account_location, toast } = translations();

  const populateForm = (location: Record<string, string>) => {
    const set = setFieldValue(setValue);

    set(FormFields.E911AddressStreet, location.address_line_1?.trim());
    set(FormFields.E911AddressExtended, location.address_line_2?.trim());
    set(FormFields.E911Region, location.address_state);
    set(FormFields.E911Locality, location.address_city);
    set(FormFields.E911PostalCode, location.address_postal_code);

    showToast.success(toast.success);
  };

  useEffect(() => {
    if (data?.length > 0) {
      const { locations } = data[0];

      setLocationsData(
        Object.entries(locations).reduce((acc: Array<any>, [key]) => {
          if (key !== 'unknown' && locations[key]?.display_name !== default_account_location) {
            acc.push({ key, ...locations[key] });
          }
          return acc;
        }, []),
      );
    }
  }, [data, default_account_location]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledApplySiteLocationDialog>
      <ApplyButton
        isDisabled={Object.keys(locationsData).length === 0}
        onClick={() => setIsDialogOpen(true)}
      />
      <SiteSelectionDialog
        columns={columns()}
        data={locationsData}
        isOpen={isDialogOpen}
        selection={selection}
        title={title}
        onApply={(selectedLocation: any) => populateForm(selectedLocation)}
        onCancel={() => setIsDialogOpen(false)}
        onClose={() => setIsDialogOpen(false)}
      />
    </StyledApplySiteLocationDialog>
  );
};

export default ApplySiteLocationDialog;
