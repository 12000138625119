import { joiResolver } from '@hookform/resolvers/joi';
import Edit from 'apps/PhoneSystem/containers/Menu/Edit';
import MenuSelect from 'apps/PhoneSystem/shared/MenuSelect';
import { ADD_KEY } from 'constant';
import { menuQueries } from 'models/Menu/Queries';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Box from 'shared/components/Box';
import Dialog, { DialogLink, DialogType } from 'shared/components/Dialog';
import { useToast } from 'shared/hooks/useToast';
import { onEmbeddedEditSaveSuccess } from '../../utility';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, MenuFlowDialogProps as Props, MenuNodeData } from './definition';
import schema from './schema';
import { onSelectedIdChanged } from './utility/onSelectedIdChanged';

const MenuDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { showToast } = useToast();

  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const [showEditButton, setShowEditButton] = useState<boolean>(false);
  const [editId, setEditId] = useState<string>(ADD_KEY);

  const {
    control,
    getValues,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const [itemName, setItemName] = useState<string>();

  const selectedId = watch('id');
  useEffect(() => {
    onSelectedIdChanged({ selectedId, setShowEditButton, setEditId });
  }, [selectedId]);

  useEffect(() => {
    reset(data);
  }, [data]);

  const submitHandler = async (formData: Data) => {
    const fetchByIdResponse: any = await dispatch(
      menuQueries.endpoints.fetchMenuById.initiate({ id: formData.id }),
    );
    const { name } = fetchByIdResponse.data;
    const nodeData: MenuNodeData = {
      data: formData,
      metadata: {
        name,
        pvt_type: 'menu',
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      isOpen
      data={data}
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.menu.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <Box className="split split-with-link" role="row">
        <Box role="cell">
          <MenuSelect
            isLabelAbove
            control={control}
            label={t('phone_system:containers.callflows.callflow_action_dialog.menu.add.menu')}
            name="id"
            {...(itemName ? { itemName } : {})}
          />
        </Box>
        <Box role="cell">
          {showEditButton && (
            <DialogLink
              onClick={() => {
                setIsEditDialogOpen(true);
                setEditId(getValues('id'));
              }}
            />
          )}
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          <DialogLink
            isBelow
            text={t(
              'phone_system:containers.callflows.callflow_action_dialog.menu.add.add_new_menu',
            )}
            type="add"
            onClick={() => {
              setIsEditDialogOpen(true);
              setEditId(ADD_KEY);
            }}
          />
        </Box>
      </Box>
      <Dialog
        open={isEditDialogOpen}
        title={
          editId === ADD_KEY
            ? t('phone_system:containers.callflows.callflow_action_dialog.menu.add.create_menu')
            : t('phone_system:containers.callflows.callflow_action_dialog.menu.edit.edit_menu')
        }
        type={DialogType.CallflowActions}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <Edit
          handleCancel={() => setIsEditDialogOpen(false)}
          handleSaveSuccess={() => onEmbeddedEditSaveSuccess({ showToast, setIsEditDialogOpen })}
          id={editId}
          setItemName={setItemName}
        />
      </Dialog>
    </CallflowActionsDialog>
  );
};

export default MenuDialog;
