import { EndpointItemType, EndpointType } from 'apps/PhoneSystem/definition';
import { getEndpointIcon } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { TableCellTruncatedValue } from 'apps/shared/components/TableCell';
import Icon from 'shared/components/Icon';
import { Recipient } from './definition';
import translations from './translations';

export const columnsSelected = (usersFieldArray: any) => {
  const { columns } = translations();

  return [
    {
      Header: columns.selected.header,
      Cell: ({ row: { original } }: EndpointItemType) => (
        <TableCellTruncatedValue
          icon={getEndpointIcon(original.endpoint_type)}
          text={original.name}
          truncateAfter={40}
        />
      ),
      accessor: 'name',
    },
    {
      Header: '',
      Cell: ({ row: { index } }: { row: { index: number } }) => (
        <Icon
          hasHover
          name="minus-circle-outlined"
          size={22}
          themeColor="core.states.error.main"
          onClick={usersFieldArray.remove.bind(null, index)}
        />
      ),
      accessor: 'id',
    },
  ];
};

export const mapUsersToForm = (recipients: Recipient[]) =>
  recipients
    ? recipients
        .filter((recipient: Recipient) => recipient.type === EndpointType.User)
        .map((recipient: Recipient) => ({
          key: recipient.id,
          endpoint_type: recipient.type,
        }))
    : [];

export const mapEmailsToForm = (recipients: Recipient[]) =>
  recipients
    ? recipients
        .filter((recipient: Recipient) => recipient.type === EndpointType.Email)
        .map((recipient: Recipient) => recipient.id)
        .join(',')
    : '';

export const mapUsersToNode = (users: any) =>
  users.map((user: Recipient) => ({
    type: EndpointType.User,
    id: user.key,
  }));

export const mapEmailsToNode = (emails: string) =>
  emails && emails.trim() !== ''
    ? emails
        .split(',')
        .filter((email) => email)
        .map((email) => ({
          type: EndpointType.Email,
          id: email.trim(),
        }))
    : [];
