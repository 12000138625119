import { joiResolver } from '@hookform/resolvers/joi';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'shared/components/Button';
import Dialog, { DialogActions, DialogActionsCloseReasons } from 'shared/components/Dialog';
import Typography from 'shared/components/Typography';
import AddApplicationVariableDialog from './AddApplicationVariableDialog';
import ApplicationVariableForm from './ApplicationVariableForm';
import defaultProps, { defaultValues } from './default';
import { ApplicationVariablesDialogProps as Props, KeyValue, Variable } from './definition';
import schema from './schema';
import {
  extractDefaultVariables,
  extractKeys,
  extractMaxKey,
  formatFormData,
  normalizeData,
} from './utility';

const ApplicationVariablesDialog = (props: Props) => {
  const { data = {}, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { nodeId, ...rest } = data;
  const { t } = useTranslation();

  const dataWithOutNodeId = (Object.entries(rest) as unknown) as Variable[];
  const dataNormalized = normalizeData(dataWithOutNodeId);
  const defaultVariables = extractDefaultVariables(dataNormalized);

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    register,
    unregister,
  } = useForm<KeyValue>({
    defaultValues: {
      ...defaultValues,
      ...defaultVariables,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const [showAddVariableDialog, setShowAddVariableDialog] = useState<boolean>(false);

  const submitHandler = () => {
    const values = getValues();
    const keys = extractKeys(values);
    const formData: Variable = formatFormData(keys, values);
    const nodeData: any = {
      data: { ...formData, nodeId: data.nodeId },
    };
    onSave(nodeData, isDirty);
  };

  const onAddHandler = (data: { variable_type: string }) => {
    const keys = extractKeys(getValues());
    const index = extractMaxKey(keys);
    register(`key-${index}`, { value: '' });
    register(`type-${index}`, { value: data.variable_type });
    register(`value-${index}`, { value: '' });
    setShowAddVariableDialog(false);
  };

  const onCloseHandler = (
    handleSubmit: any,
    submitHandler: any,
    closeResponse: { reason: DialogActionsCloseReasons },
  ) => {
    if (closeResponse.reason === 'saveClicked') {
      handleSubmit(submitHandler)();
    } else {
      setShowAddVariableDialog(false);
    }
  };

  const onRemoveHandler = (key: number) => {
    unregister([`key-${key}`, `type-${key}`, `value-${key}`]);
  };

  return (
    <Dialog
      open
      renderActions={
        <DialogActions
          rightButtons={
            <>
              <Button
                color="secondary"
                id="btn-application-cancel"
                variant="outlined"
                onClick={() => onClose(null, null, { reason: 'cancelClicked' })}
              >
                {t('common:cancel')}
              </Button>
              <Button
                color="secondary"
                id="btn-application-add"
                variant="outlined"
                onClick={() => setShowAddVariableDialog(true)}
              >
                {t(
                  'phone_system:containers.callflows.callflow_action_dialog.application_variables.add.add',
                )}
              </Button>
              <Button
                color="secondary"
                id="btn-application-save"
                variant="contained"
                onClick={submitHandler}
              >
                {t('common:update')}
              </Button>
            </>
          }
        />
      }
      title={t(
        'phone_system:containers.callflows.callflow_action_dialog.application_variables.title',
      )}
      onClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      {showAddVariableDialog && (
        <AddApplicationVariableDialog onAdd={onAddHandler} onClose={onCloseHandler} />
      )}
      {extractKeys(getValues()).length ? (
        <ApplicationVariableForm
          control={control}
          values={getValues()}
          onRemoveHandler={onRemoveHandler}
        />
      ) : (
        <Typography paragraph>
          {t(
            'phone_system:containers.callflows.callflow_action_dialog.application_variables.add.none',
          )}
        </Typography>
      )}
    </Dialog>
  );
};

export default ApplicationVariablesDialog;
