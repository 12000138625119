import Box from 'shared/components/Box';
import MuiButton from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { ApplyButtonProps as Props } from './definition';
import translations from './translations';

const ApplyButton = ({ isDisabled, onClick }: Props) => {
  const { button } = translations();

  const element = (
    <MuiButton
      color="secondary"
      disabled={isDisabled}
      role="button"
      startIcon={<Icon name="plus-circle-outlined" size={18} />}
      variant="text"
      onClick={onClick}
    >
      {button.label}
    </MuiButton>
  );

  return (
    <Box>
      {isDisabled ? (
        <Tooltip title={button.tooltip}>
          <Box component="span">{element}</Box>
        </Tooltip>
      ) : (
        element
      )}
    </Box>
  );
};

export default ApplyButton;
