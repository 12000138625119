import { defaultValues } from './default';
import { Data } from './definition';

export const extractId = (data: Data) => {
  let { id } = defaultValues;

  if (data.hasOwnProperty('group_id')) {
    id = data.group_id ?? '';
  }
  if (data.hasOwnProperty('device_id')) {
    id = data.device_id ?? '';
  }
  if (data.hasOwnProperty('user_id')) {
    id = data.user_id ?? '';
  }

  return id;
};
