import SeatTypeSelect from 'apps/PhoneSystem/shared/SeatTypeSelect';
import { BuyNumbers } from 'apps/shared/components/BuyNumbers';
import {
  HookFormInputWrapper,
  HookFormSelectWrapper,
  HookFormTimeZoneSelectWrapper,
} from 'apps/shared/components/HookForm';
import LabeledDesktopAppZoneSelect from 'apps/shared/components/LabeledDesktopAppZoneSelect';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { isPinPassLinkEnabled } from 'apps/shared/utility/featureFlag';
import { SEAT_TYPE } from 'constant';
import get from 'lodash/get';
import { useFetchAccountQuery } from 'models/Account';
import { ConfigsId, useFetchConfigsByIdQuery } from 'models/Configs';
import { useFetchPhoneNumbersQuery, useLazyFetchPhoneNumbersQuery } from 'models/PhoneNumber';
import { ChangeEvent, FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import { KazooUserType } from 'shared/definition';
import { SSO_ENABLED } from 'shared/hooks/useSamlSso';
import { TEMP_PROPERTY } from '../../../..';
import { FormInput } from '../../../../definition';
import PasswordInput from './components/PasswordInput';
import UsernameInput from './components/UsernameInput';
import { buildCallerIdName, initLocationOptions } from './utility';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'caller_id.internal.name',
  'confirm_password',
  'email',
  'first_name',
  'last_name',
  'location',
  'password',
  'phone_number',
  'seat_type',
  'timezone',
  'username',
];

const BasicsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const { getValues, setValue, watch } = useFormContext();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [locationOptions, setLocationOptions] = useState<Array<any>>([]);

  const { data: accountData } = useFetchAccountQuery();
  const { data: configsData } = useFetchConfigsByIdQuery({ id: ConfigsId.Voicemail });
  const { data: phoneNumbersData, refetch: refetchPhoneNumbers } = useFetchPhoneNumbersQuery();
  const [fetchPhoneNumbers] = useLazyFetchPhoneNumbersQuery();

  const watchFields = {
    linkedCallflowNumbers: watch(`seat.${TEMP_PROPERTY.LINKED_CALLFLOW_NUMBERS}`),
    seat: {
      hasComplexPassword: watch(`seat.${TEMP_PROPERTY.IS_COMPLEX_PASSWORD}`),
      id: watch('seat.id'),
      type: watch('seat.seat_type'),
    },
  };

  const isSsoEnabled = get(accountData, SSO_ENABLED, false) && !!watchFields.seat.id;
  const showPhoneNumber = useMemo(() => watchFields.seat.type !== SEAT_TYPE.admin.id, [
    watchFields.seat.type,
  ]);

  useEffect(() => {
    if (accountData) {
      setLocationOptions(initLocationOptions(accountData));
    }
  }, [accountData]);

  useEffect(() => {
    setIsAdmin(watchFields.seat.type === SEAT_TYPE.admin.id);
  }, [watchFields.seat.type]);

  useEffect(() => {
    setValue('seat.priv_level', isAdmin ? KazooUserType.ADMIN : KazooUserType.USER);
  }, [isAdmin, setValue]);

  useEffect(() => {
    setValue(
      `seat.${TEMP_PROPERTY.IS_COMPLEX_PASSWORD}`,
      isPinPassLinkEnabled() && configsData
        ? !isAdmin && configsData?.pin_pass_sync === false
        : false,
    );
  }, [isAdmin, configsData, configsData?.pin_pass_sync, setValue]);

  const spareNumbersOptions: SelectOption[] = useMemo<SelectOption[]>(
    () =>
      phoneNumbersData?.usage?.spare
        ? Object.keys(phoneNumbersData.usage.spare).map((number: string) => ({
            label: number,
            value: number,
          }))
        : [],
    [phoneNumbersData],
  );

  return (
    <>
      <div role="row">
        <div role="cell">
          {/* Seat Type */}
          <SeatTypeSelect
            isAdmin={isAdmin}
            label={t('phone_system:containers.seats.section.basics.seat_type.label')}
            name="seat_type"
          />

          {/* Username */}
          <UsernameInput isAdmin={isAdmin} name="username" />
        </div>

        <div role="cell">
          {/* User Privilege level */}
          <HookFormInputWrapper isCheckbox name="priv_level">
            {({ ref, isDirty, ...formProps }) => (
              <LabeledCheckbox
                checkboxProps={{
                  ...formProps,
                  checked: isAdmin,
                  disabled: true,
                }}
                indentWidth="large"
                label={t(
                  'phone_system:containers.seats.section.basics.administrator_privileges.label',
                )}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      {/* Phone Number */}
      {showPhoneNumber && (
        <div className="one-column" role="row">
          <div role="cell">
            {watchFields.linkedCallflowNumbers ? (
              <HookFormInputWrapper name={TEMP_PROPERTY.LINKED_CALLFLOW_NUMBERS}>
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledInput
                    inputProps={{
                      disabled: true,
                      placeholder: t(
                        'phone_system:containers.seats.section.basics.phone_number.placeholder',
                      ),
                      ...formProps,
                    }}
                    label={t('phone_system:containers.seats.section.basics.phone_number.label')}
                    tooltip={t(
                      'phone_system:containers.seats.section.basics.phone_number.disabled.tooltip',
                    )}
                  />
                )}
              </HookFormInputWrapper>
            ) : (
              <HookFormSelectWrapper
                name={TEMP_PROPERTY.PHONE_NUMBER}
                options={spareNumbersOptions}
              >
                {({ ref, isDirty, feedback, value, ...formProps }) => (
                  <>
                    <LabeledSelect
                      feedback={feedback}
                      isDirty={isDirty}
                      label={t('phone_system:containers.seats.section.basics.phone_number.label')}
                      selectProps={{
                        ...formProps,
                        placeholder: t(
                          'phone_system:containers.seats.section.basics.phone_number.placeholder',
                        ),
                        value,
                        onMenuOpen: () => refetchPhoneNumbers(),
                      }}
                    />
                    <BuyNumbers
                      buttonVariant="outlined"
                      onFlowComplete={({ success }) => {
                        fetchPhoneNumbers().then(() =>
                          setValue('seat.phone_number', success?.[0], { shouldDirty: true }),
                        );
                      }}
                    />
                  </>
                )}
              </HookFormSelectWrapper>
            )}
          </div>
        </div>
      )}

      <div role="row">
        <div role="cell">
          {/* First Name */}
          <HookFormInputWrapper name="first_name">
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                id="input-basics-first-name"
                inputProps={{
                  ...formProps,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setValue('seat.first_name', e.target.value, { shouldDirty: true });
                    setValue(
                      'seat.caller_id.internal.name',
                      buildCallerIdName(e.target.value, getValues('seat.last_name')),
                      { shouldDirty: true },
                    );
                  },
                  placeholder: t(
                    'phone_system:containers.seats.section.basics.first_name.placeholder',
                  ),
                }}
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.basics.first_name.label')}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
        <div role="cell">
          {/* Last Name */}
          <HookFormInputWrapper name="last_name">
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                id="input-basics-last-name"
                inputProps={{
                  ...formProps,
                  onChange: (e: ChangeEvent<HTMLInputElement>) => {
                    setValue('seat.last_name', e.target.value, {
                      shouldDirty: true,
                    });
                    setValue(
                      'seat.caller_id.internal.name',
                      buildCallerIdName(getValues('seat.first_name'), e.target.value),
                      { shouldDirty: true },
                    );
                  },
                  placeholder: t(
                    'phone_system:containers.seats.section.basics.last_name.placeholder',
                  ),
                }}
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.basics.last_name.label')}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Email */}
          <HookFormInputWrapper name="email">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                id="input-basics-email"
                inputProps={{
                  placeholder: t('phone_system:containers.seats.section.basics.email.placeholder'),
                  ...formProps,
                  disabled: isSsoEnabled,
                }}
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.basics.email.label')}
                labelProps={{ required: true }}
                tooltip={
                  isSsoEnabled
                    ? t('phone_system:containers.seats.section.basics.sso_enabled.no_email_change')
                    : ''
                }
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* New Password */}
          <PasswordInput
            hasComplexPasswordValidationFeedback={watchFields.seat.hasComplexPassword}
            info={t('phone_system:containers.seats.section.basics.sso_enabled.no_password_change')}
            isComplexPassword={watchFields.seat.hasComplexPassword}
            isSsoEnabled={isSsoEnabled}
            label={t('phone_system:containers.seats.section.basics.new_password.label')}
            labelProps={{ required: !watchFields.seat.id }} // if id doesn't exist, user must have a password
            name="password"
            sibling="confirm_password"
          />
        </div>

        <div role="cell">
          {/* Confirm Password */}
          <PasswordInput
            isComplexPassword={watchFields.seat.hasComplexPassword}
            isSsoEnabled={isSsoEnabled}
            label={t('phone_system:containers.seats.section.basics.confirm_password.label')}
            labelProps={{ required: !watchFields.seat.id }} // if id doesn't exist, user must have a password
            name="confirm_password"
            sibling="password"
          />
        </div>
      </div>

      <div role="row">
        <div role="cell">
          {/* Time Zone */}
          <HookFormTimeZoneSelectWrapper
            hasDefaultOptionUndefined
            label={t('phone_system:containers.seats.section.basics.timezone.label')}
            name="timezone"
          />
        </div>

        <div role="cell">
          {/* Seat Location */}
          <HookFormSelectWrapper name="location" options={locationOptions}>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSelect
                isDirty={isDirty}
                label={t('phone_system:containers.seats.section.basics.seat_location.label')}
                selectProps={{
                  ...formProps,
                }}
              />
            )}
          </HookFormSelectWrapper>
        </div>
      </div>
      <LabeledDesktopAppZoneSelect name={TEMP_PROPERTY.DESKTOP_APP_ZONE} />
    </>
  );
};

export default BasicsSection;
