import { ELEMENT_ID } from 'apps/PhoneSystem/constant';
import { RouterLink } from 'apps/shared/components/RouterLink';
import { PropsWithChildren } from 'react';
import { getPath } from './utility';

export const E911NumberLink = ({
  children,
  entityId,
  number,
  type,
}: PropsWithChildren<{ entityId: string; number: string; type: string }>) => {
  if (!number) {
    return <>{children}</>;
  }
  return (
    <RouterLink
      target="_blank"
      to={getPath(entityId, type)}
      urlSuffix={`#${ELEMENT_ID.E911_CALLER_ID}`}
    >
      {children}
    </RouterLink>
  );
};
