import { joiResolver } from '@hookform/resolvers/joi';
import MediaSelect from 'apps/PhoneSystem/shared/MediaSelect';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, ResponseDialogProps as Props, ResponseVariablesNodeData } from './definition';
import schema from './schema';

const ResponseDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
      code: data?.code ? String(data.code) : defaultValues.code,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const submitHandler = (formData: Data) => {
    const nodeData: ResponseVariablesNodeData = {
      data: formData,
      metadata: {
        name: t('phone_system:containers.callflows.callflow_action_dialog.response.add.sip_code', {
          code: formData.code,
        }),
        pvt_type: 'response',
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.response.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper control={control} name="code">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-response-code',
            }}
            inputWidth="small"
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.response.add.response_code',
            )}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper control={control} name="message">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-response-message',
            }}
            inputWidth="small"
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.response.add.response_message',
            )}
          />
        )}
      </HookFormInputWrapper>

      <MediaSelect
        isLabelAbove
        control={control}
        label={t('phone_system:containers.callflows.callflow_action_dialog.response.add.media')}
        name="media"
      />
    </CallflowActionsDialog>
  );
};

export default ResponseDialog;
