import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { AgentResumeDialogProps as Props, AgentResumeNodeData, Data } from './definition';
import schema from './schema';

const AgentResumeDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const submitHandler = (formData: Data) => {
    const nodeData: AgentResumeNodeData = {
      data: formData,
      metadata: {
        name: `${t(
          'phone_system:containers.callflows.callflow_action_dialog.agent_resume.add.presence_id',
        )}: ${formData.presence_id}`,
        pvt_type: 'acdc_agent_resume',
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      title={t('phone_system:containers.callflows.callflow_action_dialog.agent_resume.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper control={control} name="presence_id">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-agent-resume-presence-id',
            }}
            inputWidth="small"
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.agent_resume.add.presence_id',
            )}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default AgentResumeDialog;
