import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchDevicesQuery } from 'models/Device';
import { useFetchGroupsQuery } from 'models/Group';
import { useFetchUsersQuery } from 'models/User';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import {
  Data,
  GroupedSelect,
  GroupPickupDialogProps as Props,
  GroupPickupNodeData,
} from './definition';
import schema from './schema';
import { extractId } from './utility';

const GroupPickupDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const { data: usersData, isLoading: usersLoading } = useFetchUsersQuery();
  const { data: groupsData, isLoading: groupsLoading } = useFetchGroupsQuery();
  const { data: devicesData, isLoading: devicesLoading } = useFetchDevicesQuery();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      name: data?.name,
      nodeId: data?.nodeId,
      id: data ? extractId(data) : defaultValues.id,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const label = {
    devices: t(
      'phone_system:containers.callflows.callflow_action_dialog.group_pickup.select.devices',
    ),
    groups: t(
      'phone_system:containers.callflows.callflow_action_dialog.group_pickup.select.groups',
    ),
    users: t('phone_system:containers.callflows.callflow_action_dialog.group_pickup.select.users'),
  };

  const [options, setOptions] = useState<GroupedSelect>([
    { label: label.groups, options: [] },
    { label: label.users, options: [] },
    { label: label.devices, options: [] },
  ]);

  // when adding new input field, react form does not re-render
  const [, forceUpdateState] = useState<unknown>();
  const forceUpdate = useCallback(() => forceUpdateState({}), []); // dubious...

  const formatId = useCallback((id: string | undefined, groups: GroupedSelect) => {
    const map = {
      [label.devices]: 'device_id',
      [label.groups]: 'group_id',
      [label.users]: 'user_id',
    };
    const group = groups.find((group) => group.options.find((option) => option.value === id));

    if (group?.label) {
      return { [map[group.label]]: id };
    }
  }, []);

  const formatName = useCallback((id: string | undefined, groups: GroupedSelect) => {
    const group = groups.find((group) => group.options.find((option) => option.value === id));
    const option = group?.options.find((option) => option.value === id);
    return option?.label ?? '';
  }, []);

  const updateState = useCallback(
    (label: string, options: SelectOption[], prevState: GroupedSelect) => {
      const index = prevState.findIndex((option) => option.label === label);

      if (index > -1) {
        prevState[index].options = options;
      } else {
        prevState.push({
          label,
          options,
        });
      }
      // Not re-rendering when state is being updated.
      forceUpdate();
      return prevState;
    },
    [],
  );

  useEffect(() => {
    if (groupsData) {
      setOptions((prevState: GroupedSelect) => {
        const newOptions: SelectOption[] = groupsData.map((user) => ({
          value: user.id,
          label: user.name,
        }));
        return updateState(label.groups, newOptions, prevState);
      });
    }
  }, [groupsData]);

  useEffect(() => {
    if (usersData) {
      setOptions((prevState: GroupedSelect) => {
        const newOptions: SelectOption[] = usersData.map((user) => ({
          value: user.id,
          label: user.username,
        }));
        return updateState(label.users, newOptions, prevState);
      });
    }
  }, [usersData]);

  useEffect(() => {
    if (devicesData) {
      setOptions((prevState: GroupedSelect) => {
        const newOptions: SelectOption[] = devicesData.map((user) => ({
          value: user.id,
          label: user.name,
        }));
        return updateState(label.devices, newOptions, prevState);
      });
    }
  }, [devicesData]);

  const submitHandler = (formData: Data) => {
    const { id, ...rest } = formData;
    const formattedId = formatId(id, options);
    const formattedName = formatName(id, options);
    const nodeData: GroupPickupNodeData = {
      data: {
        ...rest,
        ...formattedId,
        name: formattedName,
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={usersLoading || groupsLoading || devicesLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.group_pickup.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper control={control} name="id" options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.group_pickup.add.endpoint',
            )}
            selectProps={{
              ...formProps,
              id: 'select-group-pickup-endpoint',
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default GroupPickupDialog;
