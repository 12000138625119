import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchConferencesQuery } from 'models/Conference';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { ConferenceDialogProps as Props, ConferenceNodeData, Data } from './definition';
import schema from './schema';

const ConferenceDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const [conferences, setConferences] = useState<Array<SelectOption>>([]);

  const { data: conferenceData, isLoading } = useFetchConferencesQuery();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  useEffect(() => {
    if (conferenceData) {
      setConferences(
        conferenceData.map((conference) => ({ value: conference.id, label: conference.name })),
      );
    }
  }, [conferenceData]);

  const submitHandler = (formData: Data) => {
    const selected = conferenceData?.find((conference) => conference.id === formData.id);
    const nodeData: ConferenceNodeData = {
      data: formData,
      metadata: {
        name: selected?.name,
        pvt_type: 'conference',
      },
    };
    onSave(nodeData, isDirty);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.conference.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper control={control} name="id" options={conferences}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            isWindowed
            feedback={feedback}
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.conference.add.label',
            )}
            selectProps={{
              ...formProps,
              id: 'select-conference-add',
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default ConferenceDialog;
