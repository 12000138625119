// Voicemail Mutations
import { Voicemail } from 'api/voicemail';
import { RequestMethod } from 'definition';
import { ByIdParam } from 'models/definition';
import { kazooApi, responseHandler, TAGS } from 'services/kazoo';

export const voicemailMutations = kazooApi.injectEndpoints({
  endpoints: (builder) => ({
    createVoicemail: builder.mutation<Voicemail, Partial<Voicemail>>({
      query: ({ body }) => ({
        url: Voicemail.Create(),
        method: RequestMethod.Put,
        body: { data: { ...body } },
      }),
      invalidatesTags: [
        { type: TAGS.VOICEMAIL, id: 'LIST' },
        { type: TAGS.VOICEMAIL, id: 'SHARED' },
      ],
    }),
    updateVoicemail: builder.mutation<Voicemail, UpdateVoicemailParams>({
      query: ({ id, body }) => ({
        url: Voicemail.Update(id),
        method: RequestMethod.Post,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.VOICEMAIL, id },
        { type: TAGS.VOICEMAIL, id: 'SHARED' },
      ],
    }),
    patchVoicemail: builder.mutation<Voicemail, UpdateVoicemailParams>({
      query: ({ id, body }) => ({
        url: Voicemail.Update(id),
        method: RequestMethod.Patch,
        body: { data: { ...body } },
        responseHandler,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: TAGS.VOICEMAIL, id },
        { type: TAGS.VOICEMAIL, id: 'SHARED' },
      ],
    }),
    deleteVoicemail: builder.mutation<Voicemail, ByIdParam>({
      query: ({ id }) => ({
        url: Voicemail.Delete(id),
        method: RequestMethod.Delete,
      }),
      invalidatesTags: [
        { type: TAGS.VOICEMAIL, id: 'LIST' },
        { type: TAGS.VOICEMAIL, id: 'SHARED' },
      ],
    }),
    deleteVoicemailMessages: builder.mutation<Voicemail, ByIdParam>({
      query: ({ id }) => ({
        url: Voicemail.DeleteMessages(id),
        method: RequestMethod.Delete,
      }),
    }),
  }),
});
