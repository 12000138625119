import { TableCellCheckmarkIcon } from 'apps/shared/components/TableCell';
import { Justify, TABLE_CONSTANTS } from 'shared/components/Table';
import translations from './translations';

export const columns = () => {
  const {
    table: { columns },
  } = translations();

  return [
    {
      Header: columns.display_name.header,
      accessor: 'display_name',
      id: 'display_name',
      width: 180,
      maxWidth: 180,
    },
    {
      Header: columns.address_line_1.header,
      Cell: ({
        row: {
          original: { address_line_1, address_line_2 },
        },
      }: any) => `${address_line_1.trim()}${address_line_2 ? `, ${address_line_2.trim()}` : ''}`,
      accessor: 'address_line_1',
      id: 'address_line_1',
      width: 180,
      maxWidth: 180,
    },
    {
      Header: columns.address_city.header,
      accessor: 'address_city',
      id: 'address_city',
      width: 140,
      maxWidth: 140,
    },
    {
      Header: columns.address_state.header,
      accessor: 'address_state',
      id: 'address_state',
      width: 140,
      maxWidth: 140,
    },
    {
      Header: columns.address_country.header,
      accessor: 'address_country',
      id: 'address_country',
      width: 120,
      maxWidth: 120,
    },
    {
      Header: columns.default.header,
      Cell: ({
        row: {
          original: { key },
        },
      }: any) => key === 'default' && <TableCellCheckmarkIcon info={columns.default.info} />,
      accessor: (row: any) => row.id,
      id: `column.default.header.${Justify.Center}`,
      width: TABLE_CONSTANTS.COLUMN.ICON.WIDTH.SMALL,
    },
  ];
};

/**
 * @name setFieldValue
 * @description Set the form field value (with option values)
 *
 * @param setValue
 * • @param name
 * • @param value
 */
export const setFieldValue = (setValue: any) => (name: string, value: string) => {
  setValue(name, value, {
    shouldValidate: true,
    shouldDirty: true,
    shouldTouch: true,
  });
};
