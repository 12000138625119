export const CONSTANTS = {};

export default {
  styleOverrides: {
    root: {
      '&.MuiPopper-split-button': {
        zIndex: 100,
      },
    },
  },
};
