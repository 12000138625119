import { joiResolver } from '@hookform/resolvers/joi';
import { EndpointItemType, EndpointType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import TableSelector from 'apps/PhoneSystem/shared/TableSelector';
import { nameFormatter, remapEndpoints } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import Box from 'shared/components/Box';
import { DialogType } from 'shared/components/Dialog';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues, endpointDefaultValues } from './default';
import { Data, PageGroupDialogProps as Props, PageGroupNodeData } from './definition';
import schema from './schema';
import translations from './translations';
import { columnsSelected } from './utility';

const PageGroupDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };

  const updatedEndpoints = data?.endpoints?.map((endpoint: GroupEndpoint) => ({
    ...endpoint,
    key: endpoint.id,
  }));

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
      endpoints: updatedEndpoints,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const endPointsFieldArray = useFieldArray({ control, name: 'endpoints' });
  const { audio_way_options, audio, confirmations, name, title } = useMemo(
    () => translations(),
    [],
  );

  const submitHandler = (formData: Data) => {
    const mappedEndpoints: GroupEndpoint[] = remapEndpoints(formData);
    const nodeData: PageGroupNodeData = {
      data: {
        ...formData,
        endpoints: mappedEndpoints,
      },
      metadata: {
        name: formData.name,
        pvt_type: 'ring_group',
      },
    };
    onSave(nodeData, isDirty);
  };

  const addItemToForm = (item: EndpointItemType, endpointType: EndpointType) =>
    endPointsFieldArray.append({
      ...endpointDefaultValues,
      endpoint_type: endpointType,
      key: item.id,
      name: nameFormatter(endpointType, item),
    });

  return (
    <CallflowActionsDialog
      data={data}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
      isLoading={false}
      title={title}
      type={DialogType.XLarge}
    >
      <Box className="split split-input-fields" role="row">
        <Box role="cell">
          <HookFormInputWrapper control={control} name="name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isLabelAbove
                feedback={feedback}
                inputProps={{
                  ...formProps,
                  id: 'input-page-group-name',
                }}
                isDirty={isDirty}
                label={name.label}
              />
            )}
          </HookFormInputWrapper>
        </Box>
        <Box role="cell">
          <HookFormSelectWrapper control={control} name="audio" options={audio_way_options}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isLabelAbove
                feedback={feedback}
                isDirty={isDirty}
                label={audio.label}
                selectProps={{
                  ...formProps,
                  id: 'select-page-group-audio',
                }}
              />
            )}
          </HookFormSelectWrapper>
        </Box>
      </Box>

      <TableSelector
        hasActionRow
        addItemHandler={addItemToForm}
        columnsSelected={columnsSelected(endPointsFieldArray)}
        confirmations={confirmations}
        id="page-group"
        selectionFieldArray={endPointsFieldArray}
      />
    </CallflowActionsDialog>
  );
};

export default PageGroupDialog;
