import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import {
  CallRecordingStartDialogProps as Props,
  CallRecordingStartNodeData,
  Data,
} from './definition';
import schema from './schema';
import translations from './translations';

const StartCallRecordingDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });
  const { format, formats, time_limit, title, url } = useMemo(() => translations(), []);

  const submitHandler = (formData: Data) => {
    const nodeData: CallRecordingStartNodeData = { data: formData };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper control={control} name="format" options={formats}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={format.label}
            selectProps={{
              ...formProps,
              id: 'select-start-call-recording-format',
            }}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormInputWrapper control={control} name="url">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-start-call-recording-url',
            }}
            inputWidth="large"
            isDirty={isDirty}
            label={url.label}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper control={control} name="time_limit">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-start-call-recording-time-limit',
            }}
            inputWidth="large"
            isDirty={isDirty}
            label={time_limit.label}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default StartCallRecordingDialog;
