import { joiResolver } from '@hookform/resolvers/joi';
import EditForm from 'apps/PhoneSystem/components/EditForm';
import { EditFormProps as Props } from 'apps/PhoneSystem/definition';
import { StyledSinglePanel } from 'apps/PhoneSystem/style';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { EditPanel } from 'apps/shared/components/StyledEditForm';
import { ADD_KEY } from 'constant';
import {
  useCreateMediaMutation,
  useDeleteMediaMutation,
  useFetchMediaByIdQuery,
  useUpdateMediaMutation,
  useUploadMediaMutation,
} from 'models/Media';
import { schema } from 'models/Media/schema';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledCheckbox, LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { checkFormEntities } from 'shared/utility';
import TTSFormSection from './components/TTSFormSection';
import UploadFormSection from './components/UploadFormSection';
import { DataSource, FormInput } from './definition';
import { transformData } from './utility';

const Edit: FunctionComponent<Props> = ({
  id,
  handleSaveSuccess,
  handleDeleteSuccess,
  handleCancel,
  setItemName,
}: Props) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();

  const { data, isLoading } = useFetchMediaByIdQuery({ id }, { skip: id === ADD_KEY });
  const [createMedia] = useCreateMediaMutation();
  const [updateMedia] = useUpdateMediaMutation();
  const [deleteMedia] = useDeleteMediaMutation();
  const [uploadMedia] = useUploadMediaMutation();

  const mediaTypes = [
    { value: 'tts', label: t('phone_system:containers.media.field.media_source.options.tts') },
    {
      value: 'upload',
      label: t('phone_system:containers.media.field.media_source.options.file'),
    },
  ];

  const formMethods = useForm<FormInput>({
    defaultValues: {
      name: '',
      media_source: mediaTypes[0].value,
      streamable: true,
      tts: {
        voice: 'male/en-US',
        text: '',
      },
    },
    reValidateMode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const {
    handleSubmit,
    setError,
    formState: { dirtyFields, isSubmitSuccessful },
    reset,
    resetField,
    watch,
  } = formMethods;

  const isPageDirty = checkFormEntities(dirtyFields);
  const watchFields = {
    mediaSource: watch('media_source'),
    name: watch('name'),
  };

  useEffect(() => {
    if (watchFields.mediaSource === DataSource.Tts) {
      resetField('file');
    }
  }, [watchFields.mediaSource, resetField]);

  useEffect(() => {
    reset(data); // Populates form components once data is loaded.
  }, [data, reset]);

  // This hook is to resolve the isDirty State does not refresh due to API data doesn't include a new field to reflect change of same file
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({}, { keepValues: true });
    }
  }, [isSubmitSuccessful, reset]);

  const handleDelete = async () => {
    try {
      await deleteMedia({ id }).unwrap();
      handleDeleteSuccess?.();
    } catch (exception) {
      showToast.delete.error();
    }
  };

  const handleFileUpload = (file: File): Promise<ArrayBuffer | string | null | undefined> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function (evt) {
        const data = evt.target?.result;
        resolve(data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (data: any) => {
      try {
        let entityId = id;
        let responseData = null;
        transformData(data);
        if (id === ADD_KEY) {
          responseData = await createMedia({ body: data }).unwrap();
        } else {
          await updateMedia({ id, body: data }).unwrap();
        }

        entityId = responseData?.id ?? entityId;
        if (data.file) {
          const fileData = await handleFileUpload(data.file);
          await uploadMedia({ id: entityId, data: fileData }).unwrap();
        }

        handleSaveSuccess?.({
          shouldRedirect: id === ADD_KEY,
          id: entityId,
        });
      } catch (exception) {
        showErrorMessage({ isFromException: true, errors: exception, setError });
      }
    },
    [id, createMedia, handleSaveSuccess, setError, showErrorMessage, updateMedia, uploadMedia],
  );

  useEffect(() => {
    setItemName?.(watchFields.name);
  }, [watchFields.name, setItemName]);

  if (isLoading) {
    return <Loading />;
  }

  // TODO: if (isUploading) showDialog(progress?)
  return (
    <EditForm
      entityLabel={t('phone_system:containers.media.label')}
      entityName={data?.name}
      onSave={handleSubmit(onSubmit, (errors) => showErrorMessage({ errors, setError }))}
      onDelete={id !== ADD_KEY ? handleDelete : undefined}
      onCancel={handleCancel}
      isPageDirty={isPageDirty}
      formMethods={formMethods}
    >
      <EditPanel isSinglePanel>
        <StyledSinglePanel>
          <div>
            {/* Name */}
            <HookFormInputWrapper name="name">
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  isLabelAbove
                  label={t('phone_system:containers.media.field.name.title')}
                  inputProps={{
                    ...formProps,
                  }}
                  labelProps={{ required: true }}
                  feedback={feedback}
                  isDirty={isDirty}
                />
              )}
            </HookFormInputWrapper>

            <div role="row" className="row-split-checkbox">
              <HookFormSelectWrapper name="media_source" options={mediaTypes}>
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledSelect
                    id="select-media-source"
                    isLabelAbove
                    label={t('phone_system:containers.media.field.media_source.title')}
                    selectWidth="small"
                    selectProps={{
                      ...formProps,
                    }}
                    labelProps={{ required: true }}
                    isDirty={isDirty}
                  />
                )}
              </HookFormSelectWrapper>

              <HookFormInputWrapper name="streamable" isCheckbox>
                {({ ref, value, isDirty, ...formProps }) => (
                  <LabeledCheckbox
                    indentWidth="small"
                    label={t('phone_system:containers.media.field.streamable.title')}
                    checkboxProps={{
                      checked: value,
                      ...formProps,
                    }}
                    isDirty={isDirty}
                  />
                )}
              </HookFormInputWrapper>
            </div>
            {watchFields.mediaSource === DataSource.Upload && <UploadFormSection data={data} />}
            {watchFields.mediaSource === DataSource.Tts && <TTSFormSection />}
          </div>
        </StyledSinglePanel>
      </EditPanel>
    </EditForm>
  );
};

export default Edit;
