import {
  HookFormInputWrapper,
  HookFormPasswordWrapper,
  HookFormTimeZoneSelectWrapper,
} from 'apps/shared/components/HookForm';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['name', 'mailbox', 'pin', 'timezone'];

const BasicsSection = () => {
  const { t } = useTranslation();

  return (
    <Box role="row">
      <Box role="cell">
        {/* Name */}
        <HookFormInputWrapper name="name">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              feedback={feedback}
              id="input-basic-name"
              inputProps={formProps}
              isDirty={isDirty}
              label={t('phone_system:containers.voicemails.section.basics.name.label')}
              labelProps={{ required: true }}
            />
          )}
        </HookFormInputWrapper>

        {/* Voicemail Number */}
        <HookFormInputWrapper name="mailbox">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              feedback={feedback}
              id="input-basic-voicemail-number"
              inputProps={formProps}
              isDirty={isDirty}
              label={t('phone_system:containers.voicemails.section.basics.voicemail_number.label')}
              labelProps={{ required: true }}
            />
          )}
        </HookFormInputWrapper>

        {/* PIN Number */}
        <HookFormPasswordWrapper
          hasFeedback
          label={t('phone_system:containers.voicemails.section.basics.pin_number.label')}
          labelProps={{ required: true }}
          name="pin"
        />

        {/* Timezone */}
        <HookFormTimeZoneSelectWrapper
          id="input-basic-timezone"
          label={t('phone_system:containers.voicemails.section.basics.timezone.label')}
          name="timezone"
          tooltip={t('phone_system:containers.voicemails.section.basics.timezone.tooltip')}
        />
      </Box>
    </Box>
  );
};

export default BasicsSection;
