import { ACCOUNT_ID_URL_PLACEHOLDER } from 'constant';

export const Voicemail = {
  // GET
  Fetch: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/vmboxes?paginate=${isPaginated}`,
  FetchById: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/vmboxes/${id}`,
  FetchByOwnerId: (id: string) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/vmboxes?by_owner_id=${id}`,
  FetchShared: (isPaginated = false) =>
    `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/vmboxes/shared?paginate=${isPaginated}`,
  // PUT
  Create: () => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/vmboxes`,

  // POST
  Update: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/vmboxes/${id}`,

  // DELETE
  Delete: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/vmboxes/${id}`,
  DeleteMessages: (id: string) => `accounts/${ACCOUNT_ID_URL_PLACEHOLDER}/vmboxes/${id}/messages`,
};
