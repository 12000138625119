import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FieldComponent } from 'shared/components/AddressAutoComplete/definition';
import { generateFieldData } from 'shared/components/AddressAutoComplete/utility';

export const useAutofillFieldsFromAddressField = ({
  fieldsComponentMapping,
}: {
  fieldsComponentMapping: Array<FieldComponent>;
}) => {
  const { setValue } = useFormContext();

  return useCallback(
    (placeResult) => {
      generateFieldData(placeResult, fieldsComponentMapping).forEach(({ fieldName, value }) => {
        setValue(fieldName as string, value, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      });
    },
    [fieldsComponentMapping, setValue],
  );
};
