import { validateNumber } from 'apps/shared/utility';
import { CHARACTER, MILLISECONDS_IN_SECOND } from 'constant';
import { Duration, intervalToDuration, isValid } from 'date-fns';
import i18next from 'i18next';
import { DurationProps } from './definition';

/**
 * @name formatDuration
 * @description Formats provided duration to a human readable string.
 *
 * @param duration
 *
 * @returns Human readable string with comma separated duration properties.
 */

export const formatDuration = (duration: Duration) => {
  const format = ['years', 'months', 'days', 'hours', 'minutes'];

  return format
    .reduce((acc, unit) => {
      const count = duration[unit as keyof Duration];
      if (!count || count === 0) {
        return acc;
      }

      return acc.concat(
        i18next.t(`phone_system:containers.time_of_day.component.duration.units.${unit}`, {
          count,
        }),
      );
    }, [] as string[])
    .join(', ');
};

/**
 * @name buildDurationText
 * @description Parses and joins the provided date and time into a human readable string:
 *              - If no end date is present, returns only hours and minutes
 *              - If `isAllDayEvent` is `true`, returns "All Day"
 *              - Returns "-" for invalid values
 *
 * @param [options] Time and date ranges for building duration text
 * @param date Date range
 * @param date.start
 * @param date.end
 * @param time Time range
 * @param time.start
 * @param time.stop
 * @param isAllDayEvent
 *
 * @returns Duration as human readable string.
 *
 */
export const buildDurationText = ({ date, time, isAllDayEvent }: DurationProps) => {
  if (isAllDayEvent) {
    return i18next.t('phone_system:containers.time_of_day.component.duration.label.all_day');
  }

  const { start, stop }: { start: number; stop: number } = {
    start: Number(time.start),
    stop: Number(time.stop),
  };

  if (!validateNumber(start) || !validateNumber(stop)) {
    return CHARACTER.EMDASH;
  }

  let dateDuration: Duration = {};
  if (isValid(date.start) && isValid(date.end)) {
    dateDuration = intervalToDuration({
      start: date.start as Date,
      end: date.end as Date,
    });
  }

  const { hours = 0, minutes = 0 } = intervalToDuration({
    start: start * MILLISECONDS_IN_SECOND,
    end: stop * MILLISECONDS_IN_SECOND,
  });

  if (hours <= 0 && minutes <= 0) {
    return CHARACTER.EMDASH;
  }

  return formatDuration({ ...dateDuration, hours, minutes });
};
