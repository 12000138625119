import i18next from 'i18next';

const translations = () => ({
  button: {
    cancel: i18next.t('common:cancel'),
    update: i18next.t('common:update'),
  },
  components: {
    e911_address_form: {
      dialog: {
        title: i18next.t(
          'common:components.e911_address_dialog.components.e911_address_form.dialog.title',
        ),
      },
    },
  },
  title: i18next.t('common:components.e911_address_dialog.components.address_dialog.title'),
});

export default translations;
