import {
  HookFormInputWrapper,
  HookFormTimeZoneSelectWrapper,
} from 'apps/shared/components/HookForm';
import { useAutofillFieldsFromAddressField } from 'apps/shared/hooks/useAutofillFieldsFromAddressField';
import { ADD_KEY } from 'constant';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AddressAutoComplete from 'shared/components/AddressAutoComplete';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import { LabeledText } from 'shared/components/Labeled';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import Tooltip from 'shared/components/Tooltip';
import Typography from 'shared/components/Typography';
import { useShowErrorMessage } from 'shared/hooks/useShowErrorMessage';
import { useToast } from 'shared/hooks/useToast';
import { DEFAULT_LOCATION_ID } from '../../constant';
import { FormFields } from '../../definition';
import { FIELDS_COMPONENT_MAPPING } from './constant';
import { StyledLocationField } from './style';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  FormFields.DisplayName,
  FormFields.AddressLine1,
  FormFields.AddressLine2,
  FormFields.AddressCountry,
  FormFields.AddressState,
  FormFields.AddressCity,
  FormFields.AddressPostalCode,
  FormFields.TimeZone,
];

export { schema } from './schema';

const OptionsSection = ({ id: locationId }: any) => {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const { showErrorMessage } = useShowErrorMessage();
  const { setError } = useFormContext();

  const isDefaultLocation = locationId === DEFAULT_LOCATION_ID;

  const onAddressChange = useAutofillFieldsFromAddressField({
    fieldsComponentMapping: FIELDS_COMPONENT_MAPPING,
  });

  const onLocationCopyClick = async () => {
    try {
      await global.navigator.clipboard.writeText(locationId);
      showToast.success(t('common:copy_success'));
    } catch (exception) {
      showErrorMessage({ isFromException: true, errors: exception, setError });
    }
  };

  return (
    <>
      {isDefaultLocation && (
        <Typography>
          {t(
            'phone_system:containers.account.submodule.site_locations.edit.section.options.heading.default_location_message',
          )}
        </Typography>
      )}

      <Box className={`one-column underline${isDefaultLocation ? ' overline' : ''}`} role="row">
        <Box role="cell">
          {/* Address Search */}
          <Box id="map" />
          <AddressAutoComplete
            inputProps={{
              adornment: { position: 'start', type: 'icon', value: 'search' },
            }}
            label={t(
              'phone_system:containers.account.submodule.site_locations.edit.section.options.field.search_address.label',
            )}
            tooltip={t(
              'phone_system:containers.account.submodule.site_locations.edit.section.options.field.search_address.info',
            )}
            onChange={onAddressChange}
          />
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          {/* Display Name */}
          <HookFormInputWrapper name={FormFields.DisplayName}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.options.field.location_name.label',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                isLabelAbove={false}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.options.field.location_name.label',
                )}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </Box>

        <Box role="cell">{/* Type */}</Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          {/* Address Line 1 */}
          <HookFormInputWrapper name={FormFields.AddressLine1}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.options.field.address_line_1.label',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                isLabelAbove={false}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.options.field.address_line_1.label',
                )}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </Box>

        <Box role="cell">
          {/* Address Line 2 */}
          <HookFormInputWrapper name={FormFields.AddressLine2}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.options.field.address_line_2.placeholder',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                isLabelAbove={false}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.options.field.address_line_2.label',
                )}
                labelProps={{ required: false }}
              />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          {/* Country */}
          <HookFormInputWrapper name={FormFields.AddressCountry}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.options.field.country.label',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                isLabelAbove={false}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.options.field.country.label',
                )}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </Box>

        <Box role="cell">
          {/* Province/ State */}
          <HookFormInputWrapper name={FormFields.AddressState}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.options.field.province_state.label',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                isLabelAbove={false}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.options.field.province_state.label',
                )}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          {/* City */}
          <HookFormInputWrapper name={FormFields.AddressCity}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.options.field.city.label',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                isLabelAbove={false}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.options.field.city.label',
                )}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </Box>

        <Box role="cell">
          {/* Postal Code */}
          <HookFormInputWrapper name={FormFields.AddressPostalCode}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: t(
                    'phone_system:containers.account.submodule.site_locations.edit.section.options.field.postal_code.label',
                  ),
                  ...formProps,
                }}
                isDirty={isDirty}
                isLabelAbove={false}
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.options.field.postal_code.label',
                )}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          {/* Timezone */}
          <HookFormTimeZoneSelectWrapper
            label={t(
              'phone_system:containers.account.submodule.site_locations.edit.section.options.field.time_zone.label',
            )}
            name={FormFields.TimeZone}
          />

          {/* Location Id */}
          {!(locationId === ADD_KEY) && (
            <StyledLocationField>
              <LabeledText
                label={t(
                  'phone_system:containers.account.submodule.site_locations.edit.section.options.field.location_id.label',
                )}
                text={locationId}
              />
              <Tooltip title={t('common:copy')}>
                <Icon hasHover name="file-copy-outlined" size={20} onClick={onLocationCopyClick} />
              </Tooltip>
            </StyledLocationField>
          )}
        </Box>
      </Box>
    </>
  );
};

export default OptionsSection;
