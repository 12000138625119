import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchFaxboxesQuery } from 'models/Faxbox';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledSelect } from 'shared/components/Labeled';
import Loading from 'shared/components/Loading';
import { SelectOption } from 'shared/components/Select';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, FaxBoxDialogProps as Props, FaxBoxNodeData } from './definition';
import schema from './schema';

const FaxBoxDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();
  const [faxBoxes, setFaxBoxes] = useState<Array<SelectOption>>([]);

  const { data: faxBoxData, isLoading } = useFetchFaxboxesQuery();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  useEffect(() => {
    if (faxBoxData) {
      setFaxBoxes(faxBoxData.map((faxbox) => ({ value: faxbox.id, label: faxbox.name })));
    }
  }, [faxBoxData]);

  const submitHandler = (formData: Data) => {
    const selected = faxBoxData?.find((fax) => fax.id === formData.id);
    const nodeData: FaxBoxNodeData = {
      data: formData,
      metadata: {
        name: selected?.name,
        pvt_type: 'faxbox',
      },
    };
    onSave(nodeData, isDirty);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoading}
      title={t('phone_system:containers.callflows.callflow_action_dialog.faxbox.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper control={control} name="id" options={faxBoxes}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            isWindowed
            feedback={feedback}
            isDirty={isDirty}
            label={t('phone_system:containers.callflows.callflow_action_dialog.faxbox.add.label')}
            selectProps={{
              ...formProps,
              id: 'select-faxbox-add',
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default FaxBoxDialog;
