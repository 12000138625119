import merge from 'lodash/merge';
import Button from 'shared/components/Button';
import Dialog, { DialogActions } from 'shared/components/Dialog';
import Typography from 'shared/components/Typography';
import defaultProps from './default';
import { AddressUpdateConfirmDialogProps as Props } from './definition';
import translations from './translations';

const AddressUpdateConfirmDialog = (props: Props) => {
  const { isOpen, onClick } = merge({}, defaultProps, props);
  const { button, content, title } = translations();

  return (
    <Dialog
      open={isOpen}
      renderActions={
        <DialogActions
          rightButtons={
            <>
              <Button color="secondary" variant="outlined" onClick={onClick.close}>
                {button.cancel}
              </Button>
              <Button color="secondary" variant="contained" onClick={onClick.update}>
                {button.update}
              </Button>
            </>
          }
        />
      }
      title={title}
      onClose={onClick.close}
    >
      <Typography paragraph>{content}</Typography>
    </Dialog>
  );
};

export default AddressUpdateConfirmDialog;
