import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { CallerIdPrependDialogProps as Props, CallerIdPrependNodeData, Data } from './definition';
import schema from './schema';
import translations from './translations';

const CallerIdPrependDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const { apply_to, apply_tos, caller_id_name_prefix, caller_id_number_prefix, title } = useMemo(
    () => translations(),
    [],
  );

  const submitHandler = (formData: Data) => {
    const nodeData: CallerIdPrependNodeData = {
      data: formData,
      metadata: {
        name: `${formData.caller_id_name_prefix} ${formData.caller_id_number_prefix}`,
        pvt_type: 'prepend_cid',
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper control={control} name="caller_id_name_prefix">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: `input-caller-id-prepend-name-prefix`,
            }}
            inputWidth="small"
            isDirty={isDirty}
            label={caller_id_name_prefix.label}
          />
        )}
      </HookFormInputWrapper>

      <HookFormInputWrapper control={control} name="caller_id_number_prefix">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-caller-id-prepend-number-prefix',
            }}
            inputWidth="small"
            isDirty={isDirty}
            label={caller_id_number_prefix.label}
          />
        )}
      </HookFormInputWrapper>

      <HookFormSelectWrapper control={control} name="apply_to" options={apply_tos}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={apply_to.label}
            selectProps={{
              ...formProps,
              id: 'select-caller-id-prepend-apply-to',
            }}
            tooltip={apply_to.tooltip}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default CallerIdPrependDialog;
