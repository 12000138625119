import { themeColor } from 'theme/local';
import theme from '../core';
import { CONSTANTS as DIALOG_ACTIONS_CONSTANTS } from './muiDialogActions';
import { CONSTANTS as DIALOG_TITLE_CONSTANTS } from './muiDialogTitle';

export const CONSTANTS = {
  CALLFLOW_ACTIONS: {
    HEIGHT: {
      ACTION_ROW: '76px', // circular dependency warnings, if imported
      HEADER: `${DIALOG_TITLE_CONSTANTS.HEIGHT.DEFAULT}px`,
    },
    MARGIN: `${theme.spacing(1)} * 2`, // surrounding dialog
    PADDING: `${theme.spacing(5)} * 2`, // inside content area
  },
  SPLIT: {
    WIDTH: 360,
  },
};

export default {
  styleOverrides: {
    root: {
      fontSize: 16,
      padding: theme.spacing(0, 4.66),
      '&.MuiDialogContent-callflow-preview, &.MuiDialogContent-download-file, &.MuiDialogContent-help, &.MuiDialogContent-medium': {
        backgroundColor: theme.palette.common.white,
      },
      '&.MuiDialogContent-callflow-preview, &.MuiDialogContent-download-file': {
        padding: 0,
      },
      '&.MuiDialogContent-callflow-actions': {
        '> div > div:last-of-type': {
          height: `calc(100vh - (${CONSTANTS.CALLFLOW_ACTIONS.MARGIN} + ${CONSTANTS.CALLFLOW_ACTIONS.HEIGHT.HEADER} + ${CONSTANTS.CALLFLOW_ACTIONS.HEIGHT.ACTION_ROW} + ${CONSTANTS.CALLFLOW_ACTIONS.PADDING}))`,
        },
      },
      '&.MuiDialogContent-callflow-preview': {
        overflow: 'auto',
      },
      '&.MuiDialogContent-download-file': {
        overflow: 'hidden',
        '> div > div:last-of-type': {
          height: `calc(80vh - (${theme.spacing(5.33)} * 2 + ${
            DIALOG_TITLE_CONSTANTS.HEIGHT.DEFAULT
          }px + ${DIALOG_ACTIONS_CONSTANTS.HEIGHT}px))`,
        },
      },
      '&.MuiDialogContent-device-picker': {
        overflow: 'hidden',
        '> .MuiBox-root': {
          height: `calc(80vh - (${theme.spacing(5.33)} * 2 + ${
            DIALOG_TITLE_CONSTANTS.HEIGHT.DEFAULT
          }px + ${CONSTANTS.CALLFLOW_ACTIONS.HEIGHT.ACTION_ROW} + ${
            DIALOG_ACTIONS_CONSTANTS.HEIGHT
          }px))`,
          overflowY: 'auto',
        },
      },
      '&.MuiDialogContent-groups-endpoints': {
        minHeight: 160,
        margin: theme.spacing(0, 5),
        padding: theme.spacing(2),
        backgroundColor: themeColor.core.background.grey.light.list,
        borderRadius: theme.shape.borderRadius,
        fontSize: 14,
        lineHeight: 1.3,
      },
      '&.MuiDialogContent-filter': {
        padding: theme.spacing(2, 0),
      },
      '&.MuiDialogContent-help': {
        padding: theme.spacing(0.66, 2.66, 0),
      },
      '&.MuiDialogContent-no-select': {
        userSelect: 'none',
      },
      '> div > div': {
        height: 'auto',
      },
      '> p': {
        width: '95%',
        fontSize: 15,
        lineHeight: 1.4,
        '&:not(:last-of-type)': {
          margin: theme.spacing(0, 0, 5),
        },
        '&.is-below': {
          marginTop: -4,
        },
        strong: {
          fontSize: 'inherit',
        },
      },
      '> pre': {
        '&, span': {
          fontFamily: "'Courier New', courier, monospace",
          fontSize: 12,
          fontWeight: 500,
        },
        padding: 16,
        backgroundColor: themeColor.core.background.grey.light.text,
        borderRadius: theme.shape.borderRadius,
        lineHeight: 1.3,
        overflowX: 'auto',
        span: {
          color: themeColor.whitelabel.primary.color,
        },
      },
      '> span': {
        display: 'inline-block',
        width: '95%',
        margin: theme.spacing(0, 0, 4),
        '&, *': {
          fontSize: 16,
          lineHeight: 1.6,
        },
        '&.error': {
          display: 'inline-flex',
          alignItems: 'center',
          color: theme.palette.error.main,
          svg: {
            margin: theme.spacing(0, 1.33, 0, 0),
          },
        },
        '&.standard, &.error': {
          marginBottom: 32,
          lineHeight: 1,
        },
        '& + div': {
          margin: theme.spacing(1, 0, 4.66),
        },
        '&.subheading': {
          color: themeColor.core.color.grey,
          fontSize: 14,
          fontStyle: 'italic',
        },
        p: {
          marginTop: 0,
          fontSize: 16,
          lineHeight: 1.6,
          '&:last-of-type': {
            margin: 0,
          },
        },
      },
      'div[role=row].split': {
        display: 'flex',
        '> div[role=cell]:last-of-type': {
          padding: theme.spacing(0, 0, 0, 3),
        },
        '&.split-input-fields > div[role=cell]': {
          width: CONSTANTS.SPLIT.WIDTH,
        },
        '&.split-with-button': {
          alignItems: 'flex-start',
          '> div[role=cell]:last-of-type': {
            margin: theme.spacing(4.33, 0, 0),
            padding: 0,
          },
        },
        '&.split-with-link': {
          alignItems: 'center',
        },
      },
    },
  },
};
