import { ChangeEvent, useState } from 'react';
import { LabeledInput } from 'shared/components/Labeled';
import { HookFormInputWrapper } from '..';
import defaultProps from './default';
import { PasswordWrapperProps as Props } from './definition';

const PasswordWrapper = (props: Props) => {
  const {
    hasFeedback,
    hasMargin,
    isDisabled,
    isLabelAbove,
    showValue,
    filter,
    info,
    label,
    labelProps,
    name,
    placeholder,
    onBlur,
  } = {
    ...defaultProps,
    ...props,
  };
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const id = `input-${name.replace('_', '-')}`;

  return (
    <HookFormInputWrapper name={name}>
      {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
        <LabeledInput
          dataTestId={id}
          hasMargin={hasFeedback ?? hasMargin}
          id={id}
          inputProps={{
            ...formProps,
            adornment: {
              position: 'end',
              type: 'icon',
              value: `visibility-${isVisible ? 'off' : 'on'}-filled`,
              onClick: !isDisabled ? () => setIsVisible(!isVisible) : () => {},
            },
            autoComplete: 'new-password',
            disabled: isDisabled,
            placeholder,
            type: isVisible ? 'text' : 'password',
            value: showValue ? value : undefined,
            onBlur: () => onBlur?.(),
            onChange: ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
              onChange(filter ? value.replace(filter, '') : value),
          }}
          isDirty={isDirty}
          isLabelAbove={isLabelAbove}
          label={label}
          labelProps={labelProps}
          tooltip={info}
          {...(hasFeedback ? { feedback } : {})}
        />
      )}
    </HookFormInputWrapper>
  );
};

export default PasswordWrapper;
