import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useAutofillFieldsFromAddressField } from 'apps/shared/hooks/useAutofillFieldsFromAddressField';
import { StyledTabPanel } from 'apps/shared/hooks/useTabs/components';
import AddressAutoComplete from 'shared/components/AddressAutoComplete';
import Alert from 'shared/components/Alert';
import Box from 'shared/components/Box';
import { LabeledInput } from 'shared/components/Labeled';
import Typography from 'shared/components/Typography';
import ApplySiteLocationDialog from './components/ApplySiteLocationDialog';
import CustomerNameCell from './components/CustomerNameCell';
import { FIELDS_COMPONENT_MAPPING } from './constant';
import { E911AddressFormProps as Props, FormFields } from './definition';
import translations from './translations';

export { defaultValues } from './default';
export type { AlertValue } from './definition';
export { schema } from './schema';

export const fields = [
  FormFields.E911AddressStreet,
  FormFields.E911AddressExtended,
  FormFields.E911Region,
  FormFields.E911Locality,
  FormFields.E911PostalCode,
  FormFields.E911CustomerName,
  FormFields.E911ContactEmails,
];

const E911AddressForm = ({ hasContactEmails, alertValue, dialogProps }: Props) => {
  const { description, dialog, field, toast } = translations();

  const autofillFieldsFromAddressField = useAutofillFieldsFromAddressField({
    fieldsComponentMapping: FIELDS_COMPONENT_MAPPING,
  });

  const alert: Record<string, any> = {
    message: {
      error: alertValue?.number ? toast.error(alertValue.number) : '',
      info: alertValue?.count ? toast.info(alertValue.count) : '',
    },
    severity: alertValue?.count ? 'info' : 'error',
  };

  return (
    <StyledTabPanel $hasDescription>
      {(alertValue?.count || alertValue?.number) && (
        <Alert hasMargin message={alert.message[alert.severity]} severity={alert.severity} />
      )}
      <Typography variant="h3">{description}</Typography>
      <Box role="row">
        <Box role="cell">
          <ApplySiteLocationDialog {...dialogProps} />
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          {/* Search Address */}
          <Box id="map" />
          <AddressAutoComplete
            inputProps={{
              adornment: {
                position: 'start',
                type: 'icon',
                value: 'search',
              },
            }}
            label={field[FormFields.E911SearchAddress].label}
            tooltip={field[FormFields.E911SearchAddress].info}
            onChange={autofillFieldsFromAddressField}
          />
        </Box>
      </Box>

      <Box className="overline" role="row">
        <Box role="cell">
          {/* Street Address */}
          <HookFormInputWrapper name={FormFields.E911AddressStreet}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: field[FormFields.E911AddressStreet].label,
                  ...formProps,
                }}
                isDirty={isDirty}
                label={field[FormFields.E911AddressStreet].label}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </Box>
        <Box role="cell">
          {/* Additional Address Info */}
          <HookFormInputWrapper name={FormFields.E911AddressExtended}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: field[FormFields.E911AddressExtended].placeholder,
                  ...formProps,
                }}
                isDirty={isDirty}
                label={field[FormFields.E911AddressExtended].label}
              />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          {/* Locality (City) */}
          <HookFormInputWrapper name={FormFields.E911Locality}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: field[FormFields.E911Locality].label,
                  ...formProps,
                }}
                isDirty={isDirty}
                label={field[FormFields.E911Locality].label}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </Box>
        <Box role="cell">
          {/* Region (State/Province) */}
          <HookFormInputWrapper name={FormFields.E911Region}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: field[FormFields.E911Region].label,
                  ...formProps,
                }}
                isDirty={isDirty}
                label={field[FormFields.E911Region].label}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          {/* Postal Code */}
          <HookFormInputWrapper name={FormFields.E911PostalCode}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                feedback={feedback}
                inputProps={{
                  placeholder: field[FormFields.E911PostalCode].label,
                  ...formProps,
                }}
                isDirty={isDirty}
                label={field[FormFields.E911PostalCode].label}
                labelProps={{ required: true }}
              />
            )}
          </HookFormInputWrapper>
        </Box>
        {!hasContactEmails && <CustomerNameCell />}
      </Box>

      <Box role="row">
        {hasContactEmails && <CustomerNameCell />}

        {hasContactEmails && (
          <Box role="cell">
            {/* Contact Emails */}
            <HookFormInputWrapper name={FormFields.E911ContactEmails}>
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  feedback={feedback}
                  inputProps={{
                    placeholder: field[FormFields.E911ContactEmails].placeholder,
                    ...formProps,
                  }}
                  isDirty={isDirty}
                  label={field[FormFields.E911ContactEmails].label}
                />
              )}
            </HookFormInputWrapper>
          </Box>
        )}
      </Box>
    </StyledTabPanel>
  );
};

export default E911AddressForm;
