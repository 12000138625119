import { meta as metaBuyNumberFlow } from 'apps/shared/components/BuyNumbers/components/BuyNumberFlow';
import HorizontalNav from 'apps/shared/components/HorizontalNav';
import Page from 'apps/shared/components/Page';
import { Suspense, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Error from 'shared/components/Error';
import Loading from 'shared/components/Loading';
import UpperActionRow from './components/UpperActionRow';
import NumbersInUse, { meta as metaNumbersInUse } from './containers/NumbersInUse';
import SpareNumbers, { meta as metaSpareNumbers } from './containers/SpareNumbers';
import { NumbersProps as Props } from './definition';
import { E911AssignedEndpointsProvider } from './store/context/E911AssignedEndpointsContext/provider';
import StyledNumbers from './style';

const Numbers = ({ label }: Props) => {
  // TODO: Find a better way for checking the origin
  const [origin, setOrigin] = useState<string>('');

  const routes = useRoutes([
    { path: '/', element: <Navigate replace to={`./${metaNumbersInUse.slug}`} /> },
    { path: `/${metaNumbersInUse.slug}/*`, element: <NumbersInUse /> },
    { path: `/${metaSpareNumbers.slug}/*`, element: <SpareNumbers /> },
    {
      path: `/${metaBuyNumberFlow.local.slug}/*`,
      element: origin.includes(metaNumbersInUse.slug) ? <NumbersInUse /> : <SpareNumbers />,
    },
    {
      path: `/${metaBuyNumberFlow.tollFree.slug}/*`,
      element: origin.includes(metaNumbersInUse.slug) ? <NumbersInUse /> : <SpareNumbers />,
    },
    {
      path: '*',
      element: <Error />,
    },
  ]);

  return (
    <Page label={label}>
      <StyledNumbers>
        <UpperActionRow origin={origin} setOrigin={setOrigin} />
        <HorizontalNav hasIndent items={[metaNumbersInUse, metaSpareNumbers]} />
        <Suspense fallback={<Loading />}>
          <E911AssignedEndpointsProvider>{routes}</E911AssignedEndpointsProvider>
        </Suspense>
      </StyledNumbers>
    </Page>
  );
};

export default Numbers;
