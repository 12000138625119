const defaultProps = {
  buttonProps: {
    color: 'success',
    disabled: false,
    variant: 'contained',
  },
  items: [],
};

export default defaultProps;
