import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { FormFields } from './definition';

const translations = () => ({
  dialog: {
    site_location: {
      title: i18next.t('common:components.e911_address_form.dialog.site_location.title'),
    },
  },
  field: {
    [FormFields.E911AddressExtended]: {
      label: i18next.t('common:components.e911_address_form.field.extended_address.label'),
      placeholder: i18next.t(
        'common:components.e911_address_form.field.extended_address.placeholder',
      ),
    },
    [FormFields.E911AddressStreet]: {
      label: i18next.t('common:components.e911_address_form.field.street_address'),
    },
    [FormFields.E911ContactEmails]: {
      label: i18next.t('common:components.e911_address_form.field.contact_emails.label'),
      placeholder: i18next.t(
        'common:components.e911_address_form.field.contact_emails.placeholder',
      ),
    },
    [FormFields.E911CustomerName]: {
      label: i18next.t('common:components.e911_address_form.field.customer_name'),
    },
    [FormFields.E911Locality]: {
      label: i18next.t('common:components.e911_address_form.field.locality'),
    },
    [FormFields.E911Region]: {
      label: i18next.t('common:components.e911_address_form.field.region'),
    },
    [FormFields.E911PostalCode]: {
      label: i18next.t('common:components.e911_address_form.field.postal_code'),
    },
    [FormFields.E911SearchAddress]: {
      info: i18next.t('common:components.e911_address_form.field.search_address.info'),
      label: i18next.t('common:components.e911_address_form.field.search_address.label'),
    },
  },
  description: i18next.t('common:components.e911_address_form.description'),
  toast: {
    error: (number: string) => (
      <Trans i18nKey="common:components.e911_address_form.toast.error" values={{ number }} />
    ),
    info: (count: number) => (
      <Trans i18nKey="common:components.e911_address_form.toast.info.0" values={{ count }} />
    ),
  },
});

export default translations;
