import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormTimeZoneSelectWrapper } from 'apps/shared/components/HookForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, TimeOfDayDialogProps as Props, TimeOfDayNodeData } from './definition';
import schema from './schema';

const TimeOfDayDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const submitHandler = (formData: Data) => {
    const nodeData: TimeOfDayNodeData = { data: formData };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.time_of_day.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormTimeZoneSelectWrapper
        control={control}
        label={t(
          'phone_system:containers.callflows.callflow_action_dialog.time_of_day.add.timezone',
        )}
        name="timezone"
      />
    </CallflowActionsDialog>
  );
};

export default TimeOfDayDialog;
