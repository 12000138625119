export enum FilterType {
  IsShowMissingInfo = 'isShowMissingInfo',
  SiteLocation = 'siteLocation',
  Source = 'source',
  Type = 'type',
}

export enum FilterSourceType {
  Account = 'account',
  Device = 'device',
  Seat = 'seat',
}

export type E911Row = {
  isUnassigned?: boolean;
  deviceName?: string;
  e911: {
    isSystemNumber: boolean;
    address?: string;
    name?: string;
    number?: string;
  };
  id: string;
  location?: string;
  locationName?: string;
  seatName: string;
  source: string;
  sourceName: string;
  type: string;
};

export type IsLoading = {
  data: boolean;
  devices: boolean;
  users: boolean;
};
