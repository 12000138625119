import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import defaultProps, { defaultValues } from './default';
import {
  CallerIdResetPrependDialogProps as Props,
  CallerIdResetPrependNodeData,
  Data,
} from './definition';
import schema from './schema';

const CallerIdResetPrependDialog = (props: Props) => {
  const { data, onSave }: Props = { ...defaultProps, ...props };

  const {
    formState: { isDirty },
    getValues,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    resolver: joiResolver(schema()),
  });

  useEffect(() => {
    const nodeData: CallerIdResetPrependNodeData = { data: getValues() };
    onSave(nodeData, isDirty);
  }, []);

  return <></>;
};

export default CallerIdResetPrependDialog;
