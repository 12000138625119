import { JOI } from 'constant';
import Joi from 'joi';
import { messages } from 'shared/utility/validation';

export const schema = () =>
  Joi.object({
    e911: Joi.object({
      customer_name: Joi.string().required(),
      locality: Joi.string().required(),
      postal_code: Joi.string().required(),
      region: Joi.string().max(2).required(),
      street_address: Joi.string().required(),
      notification_contact_emails: Joi.string()
        .email({ ...JOI.EMAIL, multiple: true })
        .optional()
        .allow(''),
    }).unknown(true),
  })
    .unknown(true)
    .messages(messages());
