import Page from 'apps/shared/components/Page';
import { FunctionComponent, Suspense } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import Error from 'shared/components/Error';
import Loading from 'shared/components/Loading';
import Accounts, { meta as metaAccounts } from './containers/Accounts';
import { AccountsManagerProps as Props } from './definition';

const AccountsManager: FunctionComponent<Props> = ({ label }: Props): JSX.Element => {
  const routes = useRoutes([
    { path: '/', element: <Navigate replace to={`./${metaAccounts.slug}`} /> },
    { path: `/${metaAccounts.slug}/*`, element: <Accounts /> },
    { path: '*', element: <Error /> },
  ]);

  return (
    <Page label={label}>
      <Suspense fallback={<Loading />}>{routes}</Suspense>
    </Page>
  );
};

export default AccountsManager;
