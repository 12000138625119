import { ShowToast } from 'shared/hooks/useToast';
import { E911CallerIdDisplayInfo } from '../../definition';

export const updateSeatE911 = async ({
  e911CallerIdDisplayInfo,
  id,
  onClose,
  patchSeat,
  patchPhoneNumber,
  showToast,
  toastMessage,
}: {
  e911CallerIdDisplayInfo: E911CallerIdDisplayInfo;
  id: string;
  onClose: () => void;
  patchSeat: any;
  patchPhoneNumber: any;
  showToast: ShowToast;
  toastMessage: string;
}) => {
  await patchSeat({
    id,
    body: { caller_id: { emergency: { number: e911CallerIdDisplayInfo.number } } },
  });
  if (e911CallerIdDisplayInfo.shouldUpdatePhoneNumberAddress) {
    await patchPhoneNumber({
      id: e911CallerIdDisplayInfo.number,
      body: {
        e911: {
          ...e911CallerIdDisplayInfo.addressToUpdate,
        },
      },
    });
  }
  onClose();
  showToast.success(toastMessage);
};
