import Edit from 'apps/PhoneSystem/containers/Media/Edit';
import { ADD_KEY } from 'constant';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Dialog, { DialogLink, DialogType } from 'shared/components/Dialog';
import { useToast } from 'shared/hooks/useToast';
import { onEmbeddedEditSaveSuccess } from '../../../utility';
import defaultProps from './default';
import { AddMediaDialogProps as Props } from './definition';
import StyledAddMediaDialog from './style';

const AddMediaDialog = (props: Props) => {
  const {
    hasEditButton,
    hasMargin,
    editId,
    selectComponent,
    addClickHandler,
    editClickHandler,
    setItemName,
  }: Props = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { showToast } = useToast();

  const title =
    editId === ADD_KEY
      ? t('phone_system:containers.callflows.callflow_action_dialog.media.add.create_media')
      : t('phone_system:containers.callflows.callflow_action_dialog.media.edit.edit_media');

  return (
    <StyledAddMediaDialog hasMargin={hasMargin}>
      <Box className="split split-with-link" role="row">
        <Box role="cell">{selectComponent}</Box>
        <Box role="cell">
          {hasEditButton && (
            <DialogLink
              type="edit"
              onClick={() => {
                editClickHandler?.();
                setIsEditDialogOpen(true);
              }}
            />
          )}
        </Box>
      </Box>

      <Box role="row">
        <Box role="cell">
          <DialogLink
            isBelow
            text={t(
              'phone_system:containers.callflows.callflow_action_dialog.media.add.add_new_media',
            )}
            type="add"
            onClick={() => {
              addClickHandler?.();
              setIsEditDialogOpen(true);
            }}
          />
        </Box>
      </Box>

      <Dialog
        open={isEditDialogOpen}
        title={title}
        type={DialogType.CallflowActions}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <Edit
          handleCancel={() => setIsEditDialogOpen(false)}
          handleDeleteSuccess={() => setIsEditDialogOpen(false)}
          handleSaveSuccess={() => onEmbeddedEditSaveSuccess({ showToast, setIsEditDialogOpen })}
          id={editId}
          setItemName={setItemName}
        />
      </Dialog>
    </StyledAddMediaDialog>
  );
};

export default AddMediaDialog;
