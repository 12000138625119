import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, ManualPresenceDialogProps as Props, ManualPresenceNodeData } from './definition';
import schema from './schema';
import translations from './translations';

const ManualPresenceDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });
  const { presence_id, status, statuses, title } = useMemo(() => translations(), []);

  const submitHandler = (formData: Data) => {
    const nodeData: ManualPresenceNodeData = {
      data: formData,
      metadata: {
        pvt_type: 'manual_presence',
        name: formData.presence_id,
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper control={control} name="presence_id">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-manual-presence-add-id',
            }}
            inputWidth="small"
            isDirty={isDirty}
            label={presence_id.label}
          />
        )}
      </HookFormInputWrapper>

      <HookFormSelectWrapper control={control} name="status" options={statuses}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={status.label}
            selectProps={{
              ...formProps,
              id: 'input-manual-presence-add-status',
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default ManualPresenceDialog;
