import { themeColor } from 'theme';
import theme from '../core';

export default {
  styleOverrides: {
    root: {
      '&.MuiMenu-paper': {
        backgroundColor: themeColor.core.background.grey.light.list,
        border: `1px solid ${themeColor.core.border.color}`,
        boxShadow: 'none',
        transform: 'translateY(4px)',
        '.MuiMenu-list': {
          padding: '4px !important',
        },
      },
      '&.MuiPaper-autocomplete-filter': {
        '.MuiAutocomplete-listbox': {
          padding: theme.spacing(0.66),
          '.MuiAutocomplete-option': {
            height: 36,
            padding: theme.spacing(1),
            borderRadius: theme.spacing(0.66),
            '&:not(:first-of-type)': {
              margin: theme.spacing(0.5, 0, 0),
            },
            '&.Mui-focused': {
              backgroundColor: 'rgba(0 0 0 / 8%)',
            },
            '&[aria-selected="true"]': {
              backgroundColor: 'rgba(0 0 0 / 4%)',
            },
          },
        },
      },
      '&.MuiPaper-select-filter, &.MuiPaper-autocomplete-filter': {
        border: 'transparent',
        boxShadow: `0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)`,
        transform: 'translateY(4px)',
      },
      '&.MuiPaper-split-button': {
        width: 170,
      },
    },
  },
};
