import i18next from 'i18next';

const translations = () => ({
  columns: {
    selected: {
      header: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.call_notification.columns.selected.header',
      ),
    },
    unselected: {
      header: i18next.t(
        'phone_system:containers.callflows.callflow_action_dialog.call_notification.columns.unselected.header',
      ),
    },
  },
  content: i18next.t(
    'phone_system:containers.callflows.callflow_action_dialog.call_notification.content',
  ),
  emails: {
    label: i18next.t(
      'phone_system:containers.callflows.callflow_action_dialog.call_notification.emails.label',
    ),
  },
  title: i18next.t(
    'phone_system:containers.callflows.callflow_action_dialog.call_notification.title',
  ),
});

export default translations;
