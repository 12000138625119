import i18next from 'i18next';

const translations = () => ({
  button: {
    label: i18next.t(
      'common:components.e911_address_form.components.apply_site_location_dialog.components.apply_button.label',
    ),
    tooltip: i18next.t(
      'common:components.e911_address_form.components.apply_site_location_dialog.components.apply_button.tooltip',
    ),
  },
});

export default translations;
