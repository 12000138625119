import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormRadioGroupWrapper } from 'apps/shared/components/HookForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput, LabeledRadioGroup } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, MatchCallerIdDialogProps as Props, MatchCallerIdNodeData } from './definition';
import schema from './schema';

const MatchCallerIdDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
      use_absolute_mode: data?.use_absolute_mode == true ? 'true' : 'false',
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const watchUseAbsoluteMode = watch('use_absolute_mode');
  const options = [
    {
      label: t(
        'phone_system:containers.callflows.callflow_action_dialog.match_caller_id.add.regex_mode',
      ),
      value: 'false',
    },
    {
      label: t(
        'phone_system:containers.callflows.callflow_action_dialog.match_caller_id.add.absolute_mode',
      ),
      value: 'true',
    },
  ];

  const submitHandler = (formData: Data) => {
    // todo if mode is changed then what to do with children? Double check this behaviour
    const useAbsoluteMode = formData.use_absolute_mode == true;
    const nodeData: MatchCallerIdNodeData = {
      data: {
        ...data,
        regex: useAbsoluteMode ? defaultValues.regex : formData.regex,
        use_absolute_mode: useAbsoluteMode,
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.match_caller_id.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormRadioGroupWrapper control={control} name="use_absolute_mode" options={options}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledRadioGroup
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={t('common:options')}
            radioGroupProps={{ ...formProps, id: 'radio-absolute-mode' }}
            radioProps={options}
          />
        )}
      </HookFormRadioGroupWrapper>

      {watchUseAbsoluteMode === 'false' && (
        <HookFormInputWrapper control={control} name="regex">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              feedback={feedback}
              inputProps={{ ...formProps, id: 'input-regex' }}
              inputWidth="medium"
              isDirty={isDirty}
              labelWidth="none"
            />
          )}
        </HookFormInputWrapper>
      )}
    </CallflowActionsDialog>
  );
};

export default MatchCallerIdDialog;
