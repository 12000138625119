import { PropsWithChildren, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '../Box';

/**
 * @description
 * This is a wrapper component to wrap any element to make the browser auto scroll to the location of the element when there is a hash in the url.
 * Please use this wrapper instead of pure html id attribute otherwise the auto scroll will not work when the element has not been loaded (e.g., opening the link in a new tab)
 */
export const AutoScrollByHash = ({ hash, children }: PropsWithChildren<{ hash: string }>) => {
  const ref = useRef<HTMLDivElement>(null);
  const { hash: urlHash } = useLocation();

  useEffect(() => {
    if (ref?.current && urlHash === `#${hash}`) {
      ref.current.scrollIntoView();
    }
  }, [hash, ref, urlHash]);

  return (
    <>
      <Box ref={ref} id={hash} />
      {children}
    </>
  );
};
