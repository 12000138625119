import { ELEMENT_ID } from 'apps/PhoneSystem/constant';
import { RouterLink } from 'apps/shared/components/RouterLink';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Icon from 'shared/components/Icon';
import Link from 'shared/components/Link';
import { StyledDisabledSMSCreation, StyledText, StyledTitle } from './style';

const DisabledSMSCreation: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <StyledDisabledSMSCreation>
      <Icon name="warning-filled" size={40} themeColor="brio.text.secondary" />
      <Box>
        <StyledTitle>
          {t('phone_system:containers.seats.sms.disabled_sms_creation.title')}
        </StyledTitle>
        <StyledText>
          <Trans
            components={[
              <Link
                component={RouterLink}
                to="/apps/phone-system/account/settings"
                urlSuffix={`#${ELEMENT_ID.PASSWORD_VOICEMAIL_PIN}`}
              />,
            ]}
            i18nKey="phone_system:containers.seats.sms.disabled_sms_creation.message"
            t={t}
          />
        </StyledText>
      </Box>
    </StyledDisabledSMSCreation>
  );
};

export default DisabledSMSCreation;
