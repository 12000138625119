import MuiMenuItem from '@mui/material/MenuItem';
import Icon from 'shared/components/Icon';
import { Item, MenuItemProps as Props } from './definition';

export type { Item as SplitButtonMenuItem };

const MenuItem = ({ icon, label, getClass, onClick }: Props) => (
  <MuiMenuItem className={getClass('MenuItem')} onClick={onClick}>
    {icon && <Icon name={icon} size={18} />}
    {label}
  </MuiMenuItem>
);

export default MenuItem;
