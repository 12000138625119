import CallflowActionsDialog from 'apps/PhoneSystem/containers/Callflows/Edit/components/CallflowActionsDialog/components/CallflowActionDialog';
import { HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledSelect } from 'shared/components/Labeled';
import defaultProps, { defaultValues } from './default';
import { AddApplicationVariableDialogProps as Props } from './definition';
import translations from './translations';

const AddApplicationVariableDialog = (props: Props) => {
  const { onClose, onAdd }: Props = { ...defaultProps, ...props };

  const { control, handleSubmit } = useForm<any>({ defaultValues });
  const { variable_type, variable_types, title } = useMemo(() => translations(), []);

  const submitHandler = (formData: any) => {
    onAdd(formData);
  };

  return (
    <CallflowActionsDialog
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormSelectWrapper name="variable_type" options={variable_types} control={control}>
        {({ ref, isDirty, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            label={variable_type.label}
            selectProps={{
              ...formProps,
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default AddApplicationVariableDialog;
