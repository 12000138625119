import { DialogType } from './definition';

const defaultProps = {
  isOpen: {
    [DialogType.Address]: false,
    [DialogType.AddressUpdateConfirm]: false,
  },
  selectedRows: [],
  onUpdate: () => {},
  setIsOpen: () => {},
};

export default defaultProps;
