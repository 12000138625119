import { joiResolver } from '@hookform/resolvers/joi';
import { EndpointItemType, EndpointType } from 'apps/PhoneSystem/definition';
import TableSelector from 'apps/PhoneSystem/shared/TableSelector';
import { nameFormatter } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { DialogType } from 'shared/components/Dialog';
import { LabeledTextarea } from 'shared/components/Labeled';
import Typography from 'shared/components/Typography';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { CallNotificationDialogProps as Props, CallNotificationNodeData, Data } from './definition';
import schema from './schema';
import translations from './translations';
import {
  columnsSelected,
  mapEmailsToForm,
  mapEmailsToNode,
  mapUsersToForm,
  mapUsersToNode,
} from './utility';

const CallNotificationDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      users: mapUsersToForm(data?.recipients),
      emails: mapEmailsToForm(data?.recipients),
    },
    mode: 'onSubmit',
    resolver: joiResolver(schema()),
  });

  const usersFieldArray = useFieldArray({ control, name: 'users' });
  const { columns, content, emails, title } = useMemo(() => translations(), []);

  const submitHandler = (formData: Data) => {
    const { users = [], emails = '' } = formData;
    const usersMapped = mapUsersToNode(users);
    const emailsMapped = mapEmailsToNode(emails);
    const nodeData: CallNotificationNodeData = {
      data: {
        nodeId: data?.nodeId,
        recipients: [...usersMapped, ...emailsMapped],
      },
    };
    onSave(nodeData, isDirty);
  };

  const addItemToForm = (item: EndpointItemType, endpointType: EndpointType) =>
    usersFieldArray.append({
      endpoint_type: endpointType,
      key: item.id,
      name: nameFormatter(endpointType, item),
    });

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={title}
      type={DialogType.XLarge}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <Typography paragraph>{content}</Typography>
      <HookFormInputWrapper control={control} name="emails">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledTextarea
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
            }}
            isDirty={isDirty}
            label={emails.label}
          />
        )}
      </HookFormInputWrapper>
      <TableSelector
        hasActionRow
        addItemHandler={addItemToForm}
        columnsSelected={columnsSelected(usersFieldArray)}
        id="call-notification"
        included={[EndpointType.User]}
        labels={{ user: columns.unselected.header }}
        selectionFieldArray={usersFieldArray}
      />
    </CallflowActionsDialog>
  );
};

export default CallNotificationDialog;
