import { DeviceType } from 'apps/PhoneSystem/definition';
import CallForward from 'apps/PhoneSystem/shared/CallForward';
import { FormContext } from 'apps/shared/components/FormContext';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import useDevicePicker from 'apps/shared/hooks/useDevicePicker';
import { macAddressMask } from 'apps/shared/utility';
import { Provisioner } from 'definition';
import { ChangeEvent, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import { LabeledCheckbox, LabeledInput, LabeledSwitch } from 'shared/components/Labeled';
import { FormFields } from '../../definition';
import { DeviceFormFieldsGenerator } from '../../utility';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  FormFields.CallForwardDirectCallsOnly,
  FormFields.CallForwardEnabled,
  FormFields.CallForwardFailover,
  FormFields.CallForwardNumber,
  FormFields.CallForwardSubstitute,
  FormFields.DeviceType,
  FormFields.Enabled,
  FormFields.FollowMe,
  FormFields.MacAddress,
  FormFields.Name,
  FormFields.ProvisionEndpointModel,
  FormFields.SipRoute,
  FormFields.SipUsername,
  FormFields.SuppressUnregisterNotifications,
  FormFields.TempDeviceBrand,
  FormFields.TempDeviceModel,
];

const BasicsSection = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const contextName = useContext(FormContext);
  const devicePicker = useDevicePicker();

  const watchFields = {
    deviceType: watch(`${contextName}device_type`),
    enabled: watch(`${contextName}enabled`),
    provisioner: watch(`${contextName}extra_info.provisioner`),
  };

  return (
    <>
      <Box role="row">
        <Box role="cell">
          {/* Device Nickname */}
          <HookFormInputWrapper name={FormFields.Name}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                id="input-basics-device-nickname"
                label={t('phone_system:containers.devices.section.basics.device_nickname')}
                labelProps={{ required: true }}
                inputProps={{
                  ...formProps,
                }}
                feedback={feedback}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>
      <Box role="row">
        <Box role="cell">
          {/* Device Type */}
          <HookFormInputWrapper name={FormFields.DeviceType}>
            {({ ref, value, isDirty, ...formProps }) => (
              <LabeledInput
                id="input-basics-device-type"
                label={t('phone_system:containers.devices.section.basics.device_type')}
                inputProps={{
                  disabled: true,
                  value: t(`phone_system:containers.devices.type.${value}`),
                  ...formProps,
                }}
                isDirty={isDirty}
              />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>
      <Box role="row">
        <Box role="cell">
          {watchFields.deviceType === DeviceType.Landline ? (
            <>
              {/* Device Phone Number (and associated flags) */}
              <CallForward
                numberLabel={t(
                  'phone_system:containers.devices.section.basics.device_phone_number',
                )}
              />
            </>
          ) : (
            <>
              {/* Device MAC Address */}
              {DeviceFormFieldsGenerator.shouldGenerate(
                FormFields.MacAddress,
                watchFields.deviceType,
              ) && (
                <HookFormInputWrapper name={FormFields.MacAddress}>
                  {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
                    <LabeledInput
                      isDirty={isDirty}
                      feedback={feedback}
                      id="input-basics-device-mac-address"
                      inputProps={{
                        ...formProps,
                        placeholder: '01:23:45:67:89:AB',
                        value: macAddressMask(value),
                        onChange: (e: ChangeEvent<HTMLInputElement>) => {
                          e.target.value = macAddressMask(e.target.value);
                          return onChange(e);
                        },
                      }}
                      label={t('phone_system:containers.devices.section.basics.device_mac_address')}
                    />
                  )}
                </HookFormInputWrapper>
              )}
              {/* Call Forward - Number */}
              {DeviceFormFieldsGenerator.shouldGenerate(
                FormFields.CallForwardNumber,
                watchFields.deviceType,
              ) && (
                <HookFormInputWrapper name={FormFields.CallForwardNumber}>
                  {({ ref, isDirty, feedback, ...formProps }) => (
                    <LabeledInput
                      isDirty={isDirty}
                      feedback={feedback}
                      inputProps={{
                        ...formProps,
                        placeholder: t(
                          'phone_system:containers.devices.section.basics.call_forward.number.placeholder',
                        ),
                      }}
                      label={t(
                        'phone_system:containers.devices.section.basics.call_forward.number.label',
                      )}
                      tooltip={t(
                        'phone_system:containers.devices.section.basics.call_forward.number.tooltip',
                      )}
                    />
                  )}
                </HookFormInputWrapper>
              )}
            </>
          )}
          {/* Sip Route */}
          {DeviceFormFieldsGenerator.shouldGenerate(
            FormFields.SipRoute,
            watchFields.deviceType,
          ) && (
            <HookFormInputWrapper name={FormFields.SipRoute}>
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  feedback={feedback}
                  id="input-basics-device-sip-route"
                  inputProps={{
                    ...formProps,
                    placeholder: t(
                      'phone_system:containers.devices.section.basics.sip_route.placeholder',
                    ),
                  }}
                  isDirty={isDirty}
                  label={t('phone_system:containers.devices.section.basics.sip_route.label')}
                  tooltip={t('phone_system:containers.devices.section.basics.sip_route.tooltip')}
                />
              )}
            </HookFormInputWrapper>
          )}
          {/* Sip Username */}
          {DeviceFormFieldsGenerator.shouldGenerate(
            FormFields.SipUsername,
            watchFields.deviceType,
          ) && (
            <HookFormInputWrapper name={FormFields.SipUsername}>
              {({ ref, isDirty, feedback, ...formProps }) => (
                <LabeledInput
                  feedback={feedback}
                  id="input-basics-device-sip-username"
                  inputProps={{
                    ...formProps,
                  }}
                  isDirty={isDirty}
                  label={t('phone_system:containers.devices.section.basics.sip_username.label')}
                />
              )}
            </HookFormInputWrapper>
          )}
        </Box>
      </Box>

      {[DeviceType.SipDevice].includes(watchFields.deviceType) && (
        <Box role="row">
          <Box role="cell">
            {[Provisioner.Advanced, Provisioner.New].includes(watchFields.provisioner) ? (
              <>
                {devicePicker.advanced.field}
                {devicePicker.advanced.dialog}
              </>
            ) : (
              devicePicker.legacy
            )}
          </Box>
        </Box>
      )}

      <Box role="row">
        <Box role="cell">
          {/* Device Enabled */}
          <HookFormInputWrapper isCheckbox name={FormFields.Enabled}>
            {({ ref, isDirty, ...formProps }) => (
              <LabeledSwitch
                hasMargin
                isDirty={isDirty}
                id="switch-basic-device-enabled"
                label={t('phone_system:containers.devices.section.basics.device_enabled.label')}
                switchProps={formProps}
                tooltip={t('phone_system:containers.devices.section.basics.device_enabled.tooltip')}
              />
            )}
          </HookFormInputWrapper>
        </Box>
      </Box>
      {watchFields.enabled && (
        <Box role="row" className="one-column is-stack">
          <Box role="cell">
            {/* Follow Me */}
            {DeviceFormFieldsGenerator.shouldGenerate(
              FormFields.FollowMe,
              watchFields.deviceType,
            ) && (
              <HookFormInputWrapper isCheckbox name={FormFields.FollowMe}>
                {({ ref, isDirty, ...formProps }) => (
                  <LabeledCheckbox
                    isBelow
                    isDirty={isDirty}
                    id="checkbox-inbound_calling_enabled"
                    checkboxProps={formProps}
                    label={t(
                      'phone_system:containers.devices.section.basics.inbound_calling_enabled.label',
                    )}
                    tooltip={t(
                      'phone_system:containers.devices.section.basics.inbound_calling_enabled.tooltip',
                    )}
                  />
                )}
              </HookFormInputWrapper>
            )}
            {/* Suppress Unregister Notifications */}
            {DeviceFormFieldsGenerator.shouldGenerate(
              FormFields.SuppressUnregisterNotifications,
              watchFields.deviceType,
            ) && (
              <HookFormInputWrapper isCheckbox name={FormFields.SuppressUnregisterNotifications}>
                {({ ref, isDirty, onChange, value, ...formProps }) => (
                  <LabeledCheckbox
                    isDirty={isDirty}
                    checkboxProps={{
                      ...formProps,
                      checked: !value,
                      onChange: (e, checked) => {
                        onChange(!checked);
                      },
                    }}
                    id="checkbox-basic-notify-unregistered"
                    label={t(
                      'phone_system:containers.devices.section.basics.notify_when_unregistered.label',
                    )}
                    tooltip={t(
                      'phone_system:containers.devices.section.basics.notify_when_unregistered.tooltip',
                    )}
                  />
                )}
              </HookFormInputWrapper>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default BasicsSection;
