import { usePatchPhoneNumberMutation } from 'models/PhoneNumber';
import { usePatchSeatMutation } from 'models/Seat';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import Typography from 'shared/components/Typography';
import { useToast } from 'shared/hooks/useToast';
import { ResponseBody } from '../../definition';
import { useE911CallerIdDisplayInfo } from './hooks';
import { openDialog } from './utility/openDialog';
import { updateSeatE911 } from './utility/updateSeatE911';

/**
 * @description This dialog to update E911 caller id number using phone number (if there is any) will show up on edit saved when there is no e911 caller id number and a phone number exists.
 */
export const UpdateE911NumberDialog = ({
  formData,
  id,
  isSubmitSuccessful,
}: {
  /** The form data that is submitted */
  formData: ResponseBody | undefined;
  id: string;
  isSubmitSuccessful: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [patchSeat] = usePatchSeatMutation();
  const [patchPhoneNumber] = usePatchPhoneNumberMutation();
  const { showToast } = useToast();
  const { t } = useTranslation();
  const e911CallerIdDisplayInfo = useE911CallerIdDisplayInfo(formData);
  const onClose = () => {
    setIsOpen(false);
  };

  const onDialogAction = async (closeResponse: { reason: DialogActionsCloseReasons }) => {
    switch (closeResponse.reason) {
      case 'cancelClicked':
        onClose();
        break;
      case 'saveClicked':
        await updateSeatE911({
          id,
          e911CallerIdDisplayInfo,
          onClose,
          patchSeat,
          patchPhoneNumber,
          showToast,
          toastMessage: t(
            'phone_system:containers.seats.update_e911_number_dialog.update_e911_number_success_toast',
          ),
        });
        break;
    }
  };

  // Open dialog on edit saved when conditions are met
  useEffect(() => {
    openDialog(formData, isSubmitSuccessful, setIsOpen);
  }, [formData, isSubmitSuccessful]);

  return (
    <Dialog
      open={isOpen}
      renderActions={
        <DialogActions
          cancelLabel={t('phone_system:containers.seats.update_e911_number_dialog.not_now')}
          saveButtonProps={{
            color: 'primary',
            label: t('common:update'),
            variant: 'contained',
          }}
          onAction={onDialogAction}
        />
      }
      title={t('phone_system:containers.seats.update_e911_number_dialog.title')}
      type={DialogType.Large}
      onClose={onClose}
    >
      <Typography
        sx={(theme) => ({ marginBottom: `${theme.spacing(2)} !important` })}
        variant="body1"
      >
        {t('phone_system:containers.seats.update_e911_number_dialog.description')}
      </Typography>
      <Typography
        sx={(theme) => ({ marginBottom: `${theme.spacing(1)} !important` })}
        variant="body1"
      >
        <strong>
          {t('phone_system:containers.seats.update_e911_number_dialog.e911_caller_id_label')}:{' '}
        </strong>
        {e911CallerIdDisplayInfo.number}
      </Typography>
      <Typography
        sx={(theme) => ({ marginBottom: `${theme.spacing(1)} !important` })}
        variant="body1"
      >
        <strong>
          {t('phone_system:containers.seats.update_e911_number_dialog.e911_address_label')}:{' '}
        </strong>
        {e911CallerIdDisplayInfo.address}
      </Typography>
    </Dialog>
  );
};
