import Edit from 'apps/PhoneSystem/containers/Devices/Edit';
import DeviceSelect from 'apps/PhoneSystem/shared/DeviceSelect';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'shared/components/Box';
import Dialog, { DialogLink, DialogType } from 'shared/components/Dialog';
import { useToast } from 'shared/hooks/useToast';
import { onEmbeddedEditSaveSuccess } from '../../../../utility';
import { DeviceSelectWrapperProps as Props } from './definition';

const DeviceSelectWrapper = ({ id, control }: Props) => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { showToast } = useToast();

  return (
    <>
      <Box className="split split-with-link" role="row">
        <Box role="cell">
          <DeviceSelect
            isLabelAbove
            control={control}
            label={t('phone_system:containers.callflows.callflow_action_dialog.device.add.device')}
            name="id"
          />
        </Box>
        <Box role="cell">{id && <DialogLink onClick={() => setIsEditDialogOpen(true)} />}</Box>
      </Box>
      <Dialog
        open={isEditDialogOpen}
        title={t('phone_system:containers.callflows.callflow_action_dialog.device.edit.title')}
        type={DialogType.CallflowActions}
        onClose={() => setIsEditDialogOpen(false)}
      >
        <Edit
          handleCancel={() => setIsEditDialogOpen(false)}
          handleDeleteSuccess={() => setIsEditDialogOpen(false)}
          handleSaveSuccess={() => onEmbeddedEditSaveSuccess({ showToast, setIsEditDialogOpen })}
          id={id}
        />
      </Dialog>
    </>
  );
};

export default DeviceSelectWrapper;
