import { RowArray } from 'apps/shared/definition';
import merge from 'lodash/merge';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog, {
  DialogActions,
  DialogActionsCloseReasons,
  DialogType,
} from 'shared/components/Dialog';
import Table, { SelectionPosition, SelectionType } from 'shared/components/Table';
import defaultProps from './default';
import { SiteSelectionProps as Props } from './definition';

const SiteSelectionDialog = (props: Props) => {
  const { isOpen, columns, data, selection, title, onApply, onCancel, onClose } = {
    ...defaultProps,
    ...props,
  };
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState<RowArray>([]);
  const defaultIndexRef = useRef<number>(0);

  useEffect(() => {
    if (data?.length > 0) {
      const index = data.findIndex(({ key }) => key === 'default');

      if (index !== -1) {
        defaultIndexRef.current = index;
        setSelectedRows([data[index]]);
      }
    }
  }, [data]);

  const handleDialogAction = (closeResponse: { reason: DialogActionsCloseReasons }) => {
    if (closeResponse.reason === 'cancelClicked') {
      onCancel();
    } else if (closeResponse.reason === 'saveClicked') {
      onApply(selectedRows[0]);
    }
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      renderActions={
        <DialogActions
          saveButtonProps={{
            color: 'secondary',
            label: t('common:apply'),
            role: 'button',
            variant: 'contained',
          }}
          onAction={handleDialogAction}
        />
      }
      title={title ?? t('numbers:containers.numbers_in_use.section.e911.site_selection.title')}
      type={DialogType.XLarge}
      onClose={onClose}
    >
      <Table
        hasBorderRadius
        columns={columns}
        data={data}
        hasActionRow={false}
        hasEditIcon={false}
        hasPaginationPadding={{
          side: false,
        }}
        hasSideMargin={false}
        id="site-selection-dialog"
        selection={merge(
          {},
          {
            indices: [defaultIndexRef.current],
            position: SelectionPosition.Right,
            type: SelectionType.Single,
            setRows: setSelectedRows,
          },
          selection,
        )}
      />
    </Dialog>
  );
};

export default SiteSelectionDialog;
