import { ResponseBody } from '../../../../definition';

export const openDialog = (
  formData: ResponseBody | undefined,
  isSubmitSuccessful: boolean,
  setIsOpen: (isOpen: boolean) => void,
) => {
  if (
    isSubmitSuccessful &&
    !formData?.seat?.caller_id?.emergency?.number && // E911 caller id number
    formData?.seat?.phone_number // Phone number
  ) {
    setIsOpen(true);
  }
};
