import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, PivotDialogProps as Props, PivotNodeData } from './definition';
import schema from './schema';
import translations from './translations';

const PivotDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const { method, methods, req_format, req_formats, voice_url, title } = useMemo(
    () => translations(),
    [],
  );

  const submitHandler = (formData: Data) => {
    const nodeData: PivotNodeData = { data: formData };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={title}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper control={control} name="voice_url">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-pivot-add-voice-url',
            }}
            inputWidth="xlarge"
            isDirty={isDirty}
            label={voice_url.label}
          />
        )}
      </HookFormInputWrapper>

      <HookFormSelectWrapper control={control} name="method" options={methods}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={method.label}
            selectProps={{
              ...formProps,
              id: 'select-pivot-add-method',
            }}
          />
        )}
      </HookFormSelectWrapper>

      <HookFormSelectWrapper control={control} name="req_format" options={req_formats}>
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledSelect
            isLabelAbove
            feedback={feedback}
            isDirty={isDirty}
            label={req_format.label}
            selectProps={{
              ...formProps,
              id: 'select-pivot-add-req-format',
            }}
          />
        )}
      </HookFormSelectWrapper>
    </CallflowActionsDialog>
  );
};

export default PivotDialog;
