import { joiResolver } from '@hookform/resolvers/joi';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import { PARK_CONVERSION_FACTOR } from '../../constants';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, ParkDialogProps as Props, ParkNodeData } from './definition';
import schema from './schema';

const ParkDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<Data>({
    defaultValues,
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const submitHandler = (formData: Data) => {
    const nodeData: ParkNodeData = {
      data: {
        ...formData,
        max_slot_number: String(Number(formData.max_slot_number) + PARK_CONVERSION_FACTOR),
      },
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    const updatedData = enhancedFormUtility.transformDataToFormData(
      {
        ...data,
        max_slot_number: data?.max_slot_number
          ? Number(data.max_slot_number) - PARK_CONVERSION_FACTOR
          : defaultValues.max_slot_number,
      },
      defaultValues,
    );

    reset(updatedData);
  }, [data, reset]);

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.park.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper control={control} name="max_slot_number">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              type: 'number',
              ...formProps,
              id: 'input-park-add-max-slots',
            }}
            inputWidth="small"
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.park.add.max_slots.label',
            )}
            tooltip={t(
              'phone_system:containers.callflows.callflow_action_dialog.park.add.max_slots.tooltip',
            )}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default ParkDialog;
