import { joiResolver } from '@hookform/resolvers/joi';
import { EndpointItemType, EndpointType, GroupEndpoint } from 'apps/PhoneSystem/definition';
import useSetSelectValue from 'apps/PhoneSystem/hooks/useSetSelectValue';
import TableSelector from 'apps/PhoneSystem/shared/TableSelector';
import { nameFormatter, remapEndpoints } from 'apps/PhoneSystem/shared/TableSelector/utility';
import { enhancedFormUtility } from 'apps/shared/components/EnhancedFormCore/utility';
import { HookFormInputWrapper, HookFormSelectWrapper } from 'apps/shared/components/HookForm';
import { useFetchMediasQuery } from 'models/Media';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { SelectInstance } from 'react-select';
import Box from 'shared/components/Box';
import { DialogType } from 'shared/components/Dialog';
import { LabeledInput, LabeledSelect } from 'shared/components/Labeled';
import { SelectOption } from 'shared/components/Select';
import { DragIndex, TableType } from 'shared/components/Table';
import { selectOptionMapper } from '../../utility';
import CallflowActionsDialog from '../CallflowActionDialog';
import AddMediaDialog from '../MediaDialog/AddMediaDialog';
import { CONSTANTS } from './constant';
import defaultProps, { defaultEndpointValues, defaultValues } from './default';
import { Data, RingGroupDialogProps as Props, RingGroupNodeData } from './definition';
import schema from './schema';
import translations from './translations';
import { columnsSelected, getLargestTimeout } from './utility';

const RingGroupDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };

  const [mediaOptions, setMediaOptions] = useState<Array<SelectOption>>([]);
  const [itemName, setItemName] = useState<string>();
  const selectRef = useRef<SelectInstance>(null);

  const { data: mediaData, isLoading: isLoadingMedia } = useFetchMediasQuery();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<Data>({
    defaultValues,
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const endpointsFieldArray = useFieldArray({ control, name: 'endpoints' });
  const { confirmations, name, repeats, ringback, strategy, strategies, title } = useMemo(
    () => translations(),
    [],
  );

  const ringBackOptions = [...ringback.options, ...mediaOptions];

  const onItem = {
    add: (item: EndpointItemType, endpointType: EndpointType) => {
      const endpoint = {
        endpoint_type: endpointType,
        key: item.id,
        delay: defaultEndpointValues.delay,
        timeout: defaultEndpointValues.timeout,
        name: nameFormatter(endpointType, item, true),
      };
      return endpointsFieldArray.append(endpoint);
    },
    reorder: (index: DragIndex) => {
      endpointsFieldArray.move(index.old, index.new);
    },
  };

  const submitHandler = (formData: Data) => {
    const mappedEndpoints: Array<GroupEndpoint> = remapEndpoints(formData);
    const nodeData: RingGroupNodeData = {
      data: {
        ...formData,
        timeout: getLargestTimeout(mappedEndpoints),
        endpoints: mappedEndpoints,
      },
      metadata: {
        name: formData.name,
        pvt_type: 'ring_group',
      },
    };
    onSave(nodeData, isDirty);
  };

  useEffect(() => {
    if (data) {
      reset(
        enhancedFormUtility.transformDataToFormData(
          {
            ...data,
            endpoints: data.endpoints?.map((endpoint: GroupEndpoint) => ({
              ...endpoint,
              key: endpoint.id,
            })),
          },
          defaultValues,
        ),
      );
    }
  }, [data, reset]);

  useEffect(() => {
    if (mediaData) {
      setMediaOptions(mediaData.map(selectOptionMapper));
    }
  }, [mediaData]);

  useSetSelectValue({ itemName, selectRef, options: ringBackOptions });

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={isLoadingMedia}
      title={title}
      type={DialogType.XLarge}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <Box className="split split-input-fields" role="row">
        <Box role="cell">
          <HookFormInputWrapper control={control} name="name">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isLabelAbove
                feedback={feedback}
                inputProps={{
                  ...formProps,
                  id: 'input-ring-group-name',
                }}
                isDirty={isDirty}
                label={name.label}
              />
            )}
          </HookFormInputWrapper>
        </Box>
        <Box role="cell">
          <HookFormSelectWrapper control={control} name="strategy" options={strategies}>
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledSelect
                isLabelAbove
                feedback={feedback}
                isDirty={isDirty}
                label={strategy.label}
                selectProps={{
                  ...formProps,
                  id: 'select-ring-group-strategy',
                }}
              />
            )}
          </HookFormSelectWrapper>
        </Box>
      </Box>

      <Box className="split split-input-fields" role="row">
        <Box role="cell">
          <HookFormInputWrapper control={control} name="repeats">
            {({ ref, isDirty, feedback, ...formProps }) => (
              <LabeledInput
                isLabelAbove
                feedback={feedback}
                inputProps={{
                  ...formProps,
                  type: 'number',
                  id: 'input-ring-group-repeats',
                }}
                inputWidth="small"
                isDirty={isDirty}
                label={repeats.label}
              />
            )}
          </HookFormInputWrapper>
        </Box>
        <Box role="cell">
          <AddMediaDialog
            selectComponent={
              <HookFormSelectWrapper control={control} name="ringback" options={ringBackOptions}>
                {({ ref, isDirty, feedback, ...formProps }) => (
                  <LabeledSelect
                    ref={selectRef}
                    isLabelAbove
                    feedback={feedback}
                    isDirty={isDirty}
                    label={ringback.label}
                    selectProps={{
                      ...formProps,
                      id: 'select-ring-group-ringback',
                    }}
                  />
                )}
              </HookFormSelectWrapper>
            }
            setItemName={setItemName}
          />
        </Box>
      </Box>

      <TableSelector
        disableSortBy
        hasActionRow
        hasUsersExtension
        isRightTableDraggable
        manualSortBy
        addItemHandler={onItem.add}
        columnsSelected={columnsSelected(control, endpointsFieldArray)}
        confirmations={confirmations}
        hasAdmins={false}
        hasPagination={{
          content: false,
        }}
        id="ring-group"
        reorderItemsHandler={onItem.reorder}
        selectionFieldArray={endpointsFieldArray}
        selectionTableType={TableType.SelectionDraggable}
        width={{
          left: CONSTANTS.WIDTH.TABLE.LEFT,
          right: CONSTANTS.WIDTH.TABLE.RIGHT,
        }}
      />
    </CallflowActionsDialog>
  );
};

export default RingGroupDialog;
