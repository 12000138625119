import { joiResolver } from '@hookform/resolvers/joi';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LabeledInput } from 'shared/components/Labeled';
import CallflowActionsDialog from '../CallflowActionDialog';
import defaultProps, { defaultValues } from './default';
import { Data, DistinctiveRingDialogProps as Props, DistinctiveRingNodeData } from './definition';
import schema from './schema';

const DistinctiveRingDialog = (props: Props) => {
  const { data, onClose, onSave }: Props = { ...defaultProps, ...props };
  const { t } = useTranslation();

  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<Data>({
    defaultValues: {
      ...defaultValues,
      ...data,
    },
    mode: 'onChange',
    resolver: joiResolver(schema()),
  });

  const submitHandler = (formData: Data) => {
    const nodeData: DistinctiveRingNodeData = {
      data: formData,
      metadata: {
        name: formData.alert_info,
        pvt_type: 'set_alert_info',
      },
    };
    onSave(nodeData, isDirty);
  };

  return (
    <CallflowActionsDialog
      data={data}
      isLoading={false}
      title={t('phone_system:containers.callflows.callflow_action_dialog.distinctive_ring.title')}
      handleClose={onClose.bind(null, handleSubmit, submitHandler)}
    >
      <HookFormInputWrapper control={control} name="alert_info">
        {({ ref, isDirty, feedback, ...formProps }) => (
          <LabeledInput
            isLabelAbove
            feedback={feedback}
            inputProps={{
              ...formProps,
              id: 'input-distinctive-ring-alert-info',
            }}
            inputWidth="small"
            isDirty={isDirty}
            label={t(
              'phone_system:containers.callflows.callflow_action_dialog.distinctive_ring.add.alert_info',
            )}
          />
        )}
      </HookFormInputWrapper>
    </CallflowActionsDialog>
  );
};

export default DistinctiveRingDialog;
