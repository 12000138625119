import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../definition';

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = ['max_connect_failures'];

const CallCenterSection: FunctionComponent<TabPanelProps<FormInput>> = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div role="row">
      <div role="cell">
        <HookFormInputWrapper name="max_connect_failures">
          {({ ref, isDirty, feedback, ...formProps }) => (
            <LabeledInput
              feedback={feedback}
              inputProps={{ ...formProps, type: 'number' }}
              isDirty={isDirty}
              label={t(
                'phone_system:containers.account.submodule.settings.section.call_center.missed_calls_logout',
              )}
              labelProps={{ required: true }}
            />
          )}
        </HookFormInputWrapper>
      </div>
    </div>
  );
};

export default CallCenterSection;
