import useBuildSMTPAddress from 'apps/AccountsManager/containers/Accounts/Edit/hooks/useBuildSMTPAddress';
import {
  createArrayValue,
  createTranslateChangeHandler,
} from 'apps/PhoneSystem/components/EditForm/helper';
import { FormContext } from 'apps/shared/components/FormContext';
import { HookFormInputWrapper } from 'apps/shared/components/HookForm';
import { TabPanelProps } from 'apps/shared/hooks/useTabs';
import { ADD_KEY } from 'constant';
import { FunctionComponent, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import LabeledInput from 'shared/components/Labeled/components/LabeledInput';
import { FormInput } from '../../definition';

type Props = TabPanelProps<FormInput>;

/**
 * List of fields held within this page to allow for
 * the parent component to make this section as dirty
 */
export const fields = [
  'name',
  'notifications.inbound.email.send_to',
  'notifications.outbound.email.send_to',
];

const BasicsSection: FunctionComponent<Props> = (): JSX.Element => {
  const { t } = useTranslation();
  const formName = useContext(FormContext);
  const { setValue } = useFormContext();
  const buildSMTPAddress = useBuildSMTPAddress();
  const { id = '' } = useParams();
  const isNewFaxbox = id.indexOf(ADD_KEY) > -1;

  return (
    <>
      <div role="row">
        <div role="cell">
          {/* Faxbox Name */}
          <HookFormInputWrapper name="name">
            {({ ref, isDirty, feedback, onChange, ...formProps }) => (
              <LabeledInput
                id="input-basic-faxbox-name"
                label={t('phone_system:containers.faxes.section.basics.name.label')}
                labelProps={{ required: true }}
                inputProps={{
                  ...formProps,
                  onChange: ({ target: { value } }) => {
                    onChange(value);

                    if (isNewFaxbox) {
                      setValue(`${formName}custom_smtp_email_address`, buildSMTPAddress(value), {
                        shouldDirty: true,
                      });
                    }
                  },
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>

          {/**
           * Not sure if it actually makes sense for a seat select on the faxbox?
           */}
          {/*
					<SeatSelect
            name="owner_id"
            label={t('phone_system:containers.faxes.section.basics.assign_to.label')}
            tooltip={t('phone_system:containers.faxes.section.basics.assign_to.tooltip')}
          />
					*/}
        </div>
      </div>

      <h2>{t('phone_system:containers.faxes.section.basics.notifications_emails')}</h2>
      <div role="row">
        <div role="cell">
          {/* Inbound faxes */}
          <HookFormInputWrapper name="notifications.inbound.email.send_to">
            {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.faxes.section.basics.inbound_faxes.label')}
                tooltip={t('phone_system:containers.faxes.section.basics.inbound_faxes.tooltip')}
                inputProps={{
                  value: createArrayValue(value),
                  onChange: createTranslateChangeHandler(onChange),
                  ...formProps,
                  id: 'input-basic-inbound-faxes',
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>

          {/* fax sent receipts */}
          <HookFormInputWrapper name="notifications.outbound.email.send_to">
            {({ ref, isDirty, feedback, value, onChange, ...formProps }) => (
              <LabeledInput
                label={t('phone_system:containers.faxes.section.basics.fax_sent_receipts.label')}
                tooltip={t(
                  'phone_system:containers.faxes.section.basics.fax_sent_receipts.tooltip',
                )}
                inputProps={{
                  value: createArrayValue(value),
                  onChange: createTranslateChangeHandler(onChange),
                  ...formProps,
                  id: 'input-basic-fax-sent-receipts',
                }}
                isDirty={isDirty}
                feedback={feedback}
              />
            )}
          </HookFormInputWrapper>
        </div>
      </div>
    </>
  );
};

export default BasicsSection;
